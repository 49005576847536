import { NumberOperator } from '../models/view';

export const numberOperatorMap = (operator: NumberOperator) => {
  const mapper = {
    GREATER_THAN: '>',
    GREATER_OR_EQUAL: '>=',
    LESS_THAN: '<',
    LESS_OR_EQUAL: '<=',
    EQUAL: '=',
  };

  return mapper[operator];
};
