import { z } from 'zod';

import { zDayOfWeek } from '@/models/common';

export const UploadedFileDtoSchema = z.object({
  name: z.string().nullable().optional(),
  size: z.number().nullable().optional(),
  created_at: z.string().nullable().optional(),
});

export type UploadedFileDto = z.infer<typeof UploadedFileDtoSchema>;

export const LogCommonOption = z.object({
  start_time: z.string().nullable().optional(),
  ended_time: z.string().nullable().optional(),
  entire_sync_duration: z.number().nullable().optional(),
});

const SuccessLogSchema = LogCommonOption.merge(
  z.object({
    status: z.literal('SUCCESS'),
    data_ingested: z.number().nullable().optional(),
    data_updated: z.number().nullable().optional(),
    row_added: z.number().nullable().optional(),
  }),
);
const FailedLogSchema = LogCommonOption.merge(
  z.object({
    status: z.literal('FAILED'),
    error_message: z.string().nullable().optional(),
  }),
);

export type FailedLogDto = z.infer<typeof FailedLogSchema>;
export type SuccessLogDto = z.infer<typeof SuccessLogSchema>;

export const LogDtoSchema = z.union([SuccessLogSchema, FailedLogSchema]);

export type LogDto = z.infer<typeof LogDtoSchema>;

const zWriteDisposition = z.enum(['add', 'replace']);

// export type WriteDisposition = z.infer<typeof zWriteDisposition>;

export const zPeriodTime = z.enum(['every-day', 'every-week']);
export type ConnectionPeriodTime = z.infer<typeof zPeriodTime>;

export const CreatorDto = z.object({
  id: z.string().nullable().optional(),
  email: z.string().nullable().optional(),
  username: z.string().nullable().optional(),
  avatar_url: z.string().nullable().optional(),
});

export const ConnectionDtoSchema = z.object({
  name: z.string().nullable().optional(),
  id: z.number().nullable().optional(),
  schema: z.record(z.string(), z.string()).nullable().optional(),
  created_time: z.string().nullable().optional(),
  team_id: z.string().nullable().optional(),
  write_disposition: zWriteDisposition.nullable().optional(),
  creator: CreatorDto.nullable().optional(),
});

export type ConnectionDto = z.infer<typeof ConnectionDtoSchema>;

export const ConnectionTableConfig = z.object({
  name: z.string().nullish(),
  is_active: z.boolean().nullish(),
  able_change_active_state: z.boolean().nullish(),
  group_name: z.string().nullish(),
});

export const ConnectionSchema = z.object({
  name: z.string().nullish(),
  original_name: z.string().nullish(),
  dataset_id: z.string().nullish(),
  is_active: z.boolean().nullable(),
  has_collected_data: z.boolean().nullish(),
  able_change_active_state: z.boolean().nullable(),
  table_configs: z.array(ConnectionTableConfig).nullish(),
});

export type ConnectionSchemaDto = z.infer<typeof ConnectionSchema>;

export const ConnectionColumn = z.object({
  column_name: z.string().nullish(),
  column_type: z
    .enum(['TEXT', 'NUMBER', 'BOOLEAN', 'DATE', 'DATETIME', 'ARRAY', 'JSON'])
    .nullish(),
});

export type ConnectionColumnDto = z.infer<typeof ConnectionColumn>;

export const CreateConnectionSchema = z.object({
  name: z.string(),
  write_disposition: z.enum(['add', 'replace']),
  csv_schema: z.record(z.string(), z.string()),
});

export type CreateConnectionPayload = z.infer<typeof CreateConnectionSchema>;

export const ConfigDataSourceSchema = z.object({
  periodType: z.enum(['am', 'pm']),
  periodTime: zPeriodTime,
  hour: z.number().gte(1).lte(12),
  minute: z.number().gt(0).lt(60),
});

export const ScheduleSchema = z.object({
  id: z.string(),
  connection_id: z.string(),
  interval_hours: z.number(),
  days_of_weeks: zDayOfWeek.array(),
  start_time: z.string(),
  created_at: z.string().nullish(),
  updated_at: z.string().nullish(),
});

export type ConnectionScheduleDto = z.infer<typeof ScheduleSchema>;
export type CreateSchedulePayload = Omit<
  ConnectionScheduleDto,
  'id' | 'connection_id' | 'created_at' | 'updated_at'
>;

export const GetTeamMembersPayloadSchema = z.object({
  search_keyword: z.string().optional().nullable(),
  team_id: z.string().uuid(),
  size: z.number().int().min(1).default(20),
  offset: z.number().int().min(0).default(0),
});

export type GetTeamMembersPayload = z.infer<typeof GetTeamMembersPayloadSchema>;
