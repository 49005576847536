import type { GroupingGuidelines } from './types';
import type {
  ClientRect,
  CollisionDescriptor,
  CollisionDetection,
} from '@dnd-kit/core';

import { rectIntersection } from '@dnd-kit/core';
import { flexRender } from '@tanstack/react-table';
import lodashIsNumber from 'lodash/isNumber';

type FullLine = 1;
type HalfLine = 0;
const FULL_LINE_SIGNAL: FullLine = 1;
const HALF_LINE_SIGNAL: HalfLine = 0;
/**
 * Check if provided value is falsy
 *
 * https://developer.mozilla.org/en-US/docs/Glossary/Falsy
 * @param value
 * @returns
 */
export function isFalsyValue(value: unknown): boolean {
  if (lodashIsNumber(value)) return false;
  return Boolean(value) === false;
}

/**
 * Safely display cell value, check falsy values (except numeric values)
 * @param value
 * @param fallback
 * @returns
 */
export function safeDisplayCellValue(
  value: unknown,
  fallback?: ReturnType<typeof flexRender>,
): ReturnType<typeof flexRender> {
  return isFalsyValue(value) ? fallback : <>{value}</>;
}

export const createRowGroupingGuidelines = (
  rowDepth = 0,
  isNextSiblingRowGrouped = false,
  nextRowDepth?: number,
): GroupingGuidelines => {
  if (!rowDepth) return [];
  // NOTE: Number of lines = row's depth
  const lines = Array.from({ length: rowDepth }).map((_, index) => {
    // NOTE: last visible row
    if (nextRowDepth == null) return HALF_LINE_SIGNAL;

    const targetRowDepth = index + 1;

    if (isNextSiblingRowGrouped && rowDepth > nextRowDepth) {
      return targetRowDepth <= nextRowDepth
        ? FULL_LINE_SIGNAL
        : HALF_LINE_SIGNAL;
    }

    return FULL_LINE_SIGNAL;
  });
  return lines;
};

/**
 * Sort collisions in descending order (from greatest to smallest value)
 */
function sortCollisionsDesc(
  { data: { value: a = 0 } },
  { data: { value: b = 0 } },
) {
  return b - a;
}

function sortCollisionsAsc(
  { data: { value: a = 0 } },
  { data: { value: b = 0 } },
) {
  return a - b;
}

function isRectHorizontalInstersection(
  r1: ClientRect,
  r2: ClientRect,
): boolean {
  // return !(r2.left > r1.right || r2.right < r1.left);
  return !(
    r2.left > r1.right ||
    r2.right < r1.left ||
    r2.top > r1.bottom ||
    r2.bottom < r1.top
  );
}

type DragDirection = 'left' | 'right' | 'idle';

function getHorizontalInsertecRatio(
  entry: ClientRect,
  target: ClientRect,
  dragDirection: DragDirection,
): number {
  // NOTE: if drag from left -> right
  if (dragDirection === 'right') {
    const isIntersectLeftEdge = target.right > entry.left;

    if (isIntersectLeftEdge) return target.right - entry.left;
  } else if (dragDirection === 'left') {
    // NOTE: if drag from right -> left
    const isIntersectRightEdge = target.left < entry.right;

    if (isIntersectRightEdge) return entry.right - target.left;
  }

  return 0;
}

export const closetHeaderCell: CollisionDetection = ({
  collisionRect,
  droppableRects,
  droppableContainers,
  //
  active,
  pointerCoordinates,
}) => {
  const collisions: CollisionDescriptor[] = [];
  let dragDirection: DragDirection = 'idle';

  if (active.rect.current.initial && active.rect.current.translated) {
    if (
      active.rect.current.initial.left > active.rect.current.translated.left
    ) {
      dragDirection = 'left';
    } else if (
      active.rect.current.initial.right < active.rect.current.translated.right
    ) {
      dragDirection = 'right';
    }
  }
  for (const droppableContainer of droppableContainers) {
    const { id } = droppableContainer;
    const rect = droppableRects.get(id);

    if (rect) {
      const intersectionRatio = getHorizontalInsertecRatio(
        rect,
        collisionRect,
        dragDirection,
      );
      // console.log(
      //   'intersectionRatio of ',
      //   id,
      //   intersectionRatio,
      //   data?.current?.index,
      // );
      if (intersectionRatio > 0) {
        collisions.push({
          id,
          data: { droppableContainer, value: intersectionRatio }, // NOTE: pick the smallest ratio (closet)
        });
      }
    }
  }

  // console.log(
  //   'custom',
  //   collisions.map((c) => c.id),
  // );

  if (collisions.length) {
    // NOTE: pick the smallest ratio (closet) - sort ASC
    return collisions.sort(sortCollisionsAsc);
  }

  const rectInterSectionCollisions = rectIntersection({
    collisionRect,
    droppableRects,
    droppableContainers,
    //
    active,
    pointerCoordinates,
  });

  // console.log(
  //   'rect-intersection',
  //   rectInterSectionCollisions.map((c) => c.id),
  // );

  if (rectInterSectionCollisions.length) {
    return rectInterSectionCollisions;
  }

  return collisions;
};
