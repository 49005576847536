import { z } from 'zod';

import { makeSafeSchema } from '@/utils/data';

import { zCustomColumnsConfigMap, zDateSchema } from './common';
import { ViewSchema } from './view';

const WidgetContentType = z.enum(['REPORT', 'TEXT']);

export type WidgetContentType = z.infer<typeof WidgetContentType>;

const zWidgetTextContentSchema = z.object({
  content: z.string(),
  widget_id: z.string(),
});

export const zColumnFormula = z.object({
  name: z.string(),
  formula_text: z.string(),
});
export type ColumnFormula = z.infer<typeof zColumnFormula>;

const zDataModelConfig = z.object({
  formulas: makeSafeSchema(z.array(zColumnFormula), []),
  custom_columns: zCustomColumnsConfigMap.nullish(),
});
export type DataModelConfig = z.infer<typeof zDataModelConfig>;

const zWidgetReportContentSchema = z.object({
  id: z.string(),
  data_model_id: z.string().nullable().optional(),
  dataset_id: z.string().nullable(),
  data_model_config: zDataModelConfig.nullish(),
  widget_id: z.string(),
  default_view_id: z.string().optional(),
  name: z.string().optional(),
  views: z.array(ViewSchema),
  created_at: zDateSchema,
  updated_at: zDateSchema,
  data_model_name: z.string().nullish(),
});

export const WidgetDtoSchema = z.object({
  id: z.string(),
  dashboard_id: z.string(),
  name: z.string(),
  description: z.string().optional(),
  x_axis_start: z.number().int(),
  x_axis_end: z.number().int(),
  y_axis_start: z.number().int(),
  y_axis_end: z.number().int(),
  width: z.number().int(),
  height: z.number().int(),
  content_type: WidgetContentType,
  created_at: zDateSchema, // '2023-04-17T09:53:25.489273+00:00'
  updated_at: zDateSchema, // '2023-04-17T09:53:25.489273+00:00'
  text: zWidgetTextContentSchema.nullable(),
  report: zWidgetReportContentSchema.nullable(),
});

export type WidgetDto = z.infer<typeof WidgetDtoSchema>;

export type WidgetReportDto = z.infer<typeof zWidgetReportContentSchema>;
