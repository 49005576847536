import type { SxProps, Theme } from '@mui/material/styles';

import React from 'react';

import { styled } from '@mui/material/styles';
import Lottie, { type LottieProps } from 'react-lottie';

type Props = LottieProps & {
  sx?: SxProps<Theme>;
};

export const AppLottie = ({ sx, ...lottieProps }: Props) => {
  return <StyledLottie sx={sx} {...lottieProps} />;
};

const StyledLottie = styled(Lottie)``;
