import {
  generateMonthlyRangedDates,
  generateQuarterlyRangedDates,
  generateRangedDates,
  generateWeeklyRangedDates,
  generateYearlyRangedDates,
} from './misc';

export * from './misc';

export const RANGED_DATES_FN_MAPPER = {
  DAILY: generateRangedDates,
  WEEKLY: generateWeeklyRangedDates,
  MONTHLY: generateMonthlyRangedDates,
  QUARTERLY: generateQuarterlyRangedDates,
  YEARLY: generateYearlyRangedDates,
};

export const PERIOD_TO_DAYJS_UNIT_MAPPER = {
  DAILY: 'day',
  WEEKLY: 'week',
  MONTHLY: 'month',
  QUARTERLY: 'quarter',
  YEARLY: 'year',
} as const;
