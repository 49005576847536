const appConfig = {
  API: process.env.API || '',
  SAFE_REFRESH_TOKEN_MS: 5000, // NOTE: 5 seconds
  ENABLE_SCRIPT: process.env.ENABLE_SCRIPT === 'true',
  DEPLOY_TYPE: `${
    process.env.DEPLOY_TYPE ? `[${process.env.DEPLOY_TYPE}] ` : ''
  }Endash`,
  SMARTLOOK_KEY: process.env.SMARTLOOK_KEY || '',
  CSVBOX_LICENSE_KEY: process.env.CSVBOX_LICENSE_KEY || '',
  SURVICATE_KEY: process.env.SURVICATE_KEY || '',
  ZENDESK_KEY: process.env.ZENDESK_KEY || '',
  BILLING_URL: process.env.BILLING_URL || '',
  BIGQUERY_POLICY_URL: process.env.BIGQUERY_POLICY_URL || '',
  FIVETRAN_POLICY_URL: process.env.FIVETRAN_POLICY_URL || '',
  DOCS_URL: process.env.DOCS_URL || '',
  AI_FEEDBACK_SLACK_WEBHOOK: process.env.AI_FEEDBACK_SLACK_WEBHOOK || '',
  // NOTE: data dog
  DATADOG_APPLICATION_ID: process.env.DATADOG_APPLICATION_ID || '',
  DATADOG_CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN || '',
  DATADOG_SITE: process.env.DATADOG_SITE || '',
  USER_PREFERRED_LANGUAGE_COOKIE_NAME: 'NEXT_LOCALE',
  IS_PROD: process.env.DEPLOY_TYPE === '',
  HOST: process.env.NEXT_PUBLIC_HOST,
  // NOTE: CIGRO data provider URL
  CIGRO_DATA_PROVIDER_URL: process.env.CIGRO_DATA_PROVIDER_URL || '',
  GOOGLE_ANALYTIC_ID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTIC_ID || '',
} as const;

export const CSS_VAR_SIDE_BAR_WIDTH = '--cg-sidebar-width';

export default appConfig;
