import type {
  GetPermissionsResponse,
  SignInPayload,
  SignInResponse,
  SignUpPayload,
} from '@/feature-auth/api';

import {
  BaseApiAdapterClass,
  type RequestManager,
} from '@/adapters/api/__base';

import { clientHttp, serverHttp } from './axios';

const BACKEND_REFRESH_TOKEN_COOKIE_KEY = 'refresh_token';

class AuthApiAdapterClass extends BaseApiAdapterClass {
  constructor(rm: RequestManager) {
    super(rm);
  }

  // async signUp(payload: SignUpPayload) {
  //   await this.request('/v3/auth/users', 'post', payload);
  //   return;
  // }

  async signIn(
    { email, otp_code }: SignInPayload,
    $refreshToken?: string,
  ): Promise<SignInResponse | null> {
    let reqHeaders: Record<string, string> | undefined = undefined;
    if ($refreshToken) {
      reqHeaders = {
        Cookie: BACKEND_REFRESH_TOKEN_COOKIE_KEY + '=' + $refreshToken,
      };
    }
    const url = '/v3/auth/users/tokens';
    return await this.request<SignInResponse, SignInPayload>(
      url,
      'post',
      {
        email,
        otp_code,
      },
      {},
      reqHeaders,
    );
  }

  async refreshToken($refreshToken?: string): Promise<SignInResponse | null> {
    let reqHeaders: Record<string, string> | undefined = undefined;
    if ($refreshToken) {
      reqHeaders = {
        Cookie: BACKEND_REFRESH_TOKEN_COOKIE_KEY + '=' + $refreshToken,
      };
    }
    return await this.request<SignInResponse, {}>(
      '/v3/auth/users/tokens',
      'put',
      {},
      {},
      reqHeaders,
    );
  }

  async signOut($refreshToken?: string) {
    let reqHeaders: Record<string, string> | undefined = undefined;
    if ($refreshToken) {
      reqHeaders = {
        Cookie: BACKEND_REFRESH_TOKEN_COOKIE_KEY + '=' + $refreshToken,
      };
    }
    return await this.request<SignInResponse, {}>(
      '/v3/auth/users/tokens',
      'delete',
      {},
      {},
      reqHeaders,
    );
  }

  async getPermissions(): Promise<GetPermissionsResponse | null> {
    return await this.request<GetPermissionsResponse, {}>(
      '/v3/auth/users/me/permissions',
      'get',
    );
  }

  async checkEmailIsRegistered(email: string): Promise<boolean> {
    return await this.request('/v3/auth/users/check-exists', 'post', { email })
      .then((response) => !!response)
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  async googleToken(googleToken: string) {
    return await this.request<SignInResponse, { id_token: string }>(
      '/v3/auth/users/tokens/google',
      'post',
      {
        id_token: googleToken,
      },
    );
  }
}

/**
 * @deprecated
 */
export const AuthClientApiAdapter = Object.freeze(
  new AuthApiAdapterClass(clientHttp),
);
/**
 * @deprecated
 */
export const AuthServerApiAdapter = Object.freeze(
  new AuthApiAdapterClass(serverHttp),
);

export const AuthApi = {
  onBrowser: () => new AuthApiAdapterClass(clientHttp),
  onServer: () => new AuthApiAdapterClass(serverHttp),
};
