import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { useTranslation } from 'next-i18next';

import { AppButtonV2 } from '@/components/Button';
import { AppRoutes } from '@/routes';

export const BackToDashboardButton: React.FC<{
  resetErrorBoundary: () => void;
  callbackUrl?: string;
}> = ({ resetErrorBoundary }) => {
  const [loading, setLoading] = useState(false);
  const { push, prefetch } = useRouter();

  const { t } = useTranslation('common', {
    keyPrefix: 'error-page',
  });

  useEffect(() => {
    prefetch(AppRoutes.INDEX);
  }, [prefetch]);

  return (
    <AppButtonV2
      variant="outline"
      disabled={loading}
      color="tertiary"
      sx={{
        textDecoration: 'none',
        borderColor: 'neutralV2.4',
        width: '100%',
        justifyContent: 'center',
      }}
      onClick={async () => {
        try {
          setLoading(true);
          await push(AppRoutes.INDEX);
        } finally {
          resetErrorBoundary();
          setLoading(false);
        }
      }}
      //
    >
      {t('back-to-your-dashboard')}
    </AppButtonV2>
  );
};
