import { z } from 'zod';

import { zDateSchema } from '../common';
import { zBarChartFullConfig } from './bar-chart';
import { zBoardFullConfig } from './board';
import { zComboChartFullConfig } from './combo-chart';
import { zGalleryFullConfig } from './gallery';
import { zGoalFullConfig } from './goal';
import { zLineChartFullConfig } from './line-chart';
import { zPieChartFullConfig } from './pie-chart';
import { zPivotFullConfig } from './pivot';
import { zTableFullConfig } from './table';

export const zViewConfig = z.union([
  zTableFullConfig,
  zBarChartFullConfig,
  zPieChartFullConfig,
  zLineChartFullConfig,
  zBoardFullConfig,
  zPivotFullConfig,
  zGalleryFullConfig,
  zGoalFullConfig,
  zComboChartFullConfig,
]);

// NOTE: Remove all duplicated column in display and undisplay
const zSafeViewConfig = zViewConfig.transform((config) => {
  const { display, undisplay } = config;

  const newDisplay = display.filter((col, index) => {
    if (index === 0) return col;
    // Find in all previous time to see if duplicated
    const isDuplicated = display
      .slice(0, index - 1)
      .find((item) => item.column_name === col.column_name);
    return !isDuplicated;
  });

  const newUnDisplay = undisplay
    .filter((col, index) => {
      if (index === 0) return col;
      // Find in all previous time to see if duplicated
      const isDuplicated = undisplay
        .slice(0, index - 1)
        .find((item) => item.column_name === col.column_name);
      return !isDuplicated;
    })
    .filter((col) => {
      const isDuplicated = Boolean(
        newDisplay.find((item) => item.column_name === col.column_name),
      );
      return !isDuplicated;
    });

  return { ...config, display: newDisplay, undisplay: newUnDisplay };
});

export type ViewConfig = z.infer<typeof zViewConfig>;

export const ViewSchema = z.object({
  id: z.string(),
  report_id: z.string(),
  name: z.string(),
  config: zSafeViewConfig,
  teamId: z.string().optional(),
  default: z.boolean().default(false).optional(),
  order: z.number().int().nullable().default(null),
  created_at: zDateSchema,
  updated_at: zDateSchema,
  description: z.string().nullish(),
});

export type ViewDto = z.infer<typeof ViewSchema>;
