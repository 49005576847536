import type { AppButtonProps } from './types';

import { forwardRef } from 'react';

import { Button as UnstyledButton } from '@mui/base/Button';
import { styled, useTheme } from '@mui/material/styles';
import clsx from 'clsx';

import { CIGRO_BUTTON_CLASSNAME } from '@components/Button/constants';

import { getButtonStyles } from './utils';

const Button = styled(UnstyledButton, {
  shouldForwardProp: (propName) => {
    if (propName === 'variant') return false;
    if (propName === 'color') return false;
    if (propName === 'fullWidth') return false;
    if (propName === 'semiBold') return false;
    if (propName === 'startIcon') return false;
    if (propName === 'endIcon') return false;
    if (propName === 'sx') return false;
    return true;
  },
})<AppButtonProps>({});

const AppButton = forwardRef<HTMLButtonElement, AppButtonProps>(
  (
    {
      variant = 'contained',
      color = 'primary',
      fullWidth = false,
      semiBold = false,
      sx,
      startIcon,
      endIcon,
      children,
      // onClick,
      clickRateLimitMs,
      className,
      ...nativeProps
    },
    ref,
  ) => {
    const theme = useTheme();
    const tabIndex = nativeProps.disabled ? -1 : 0;
    const buttonSx = getButtonStyles({
      theme,
      sx,
      variant,
      color,
      fullWidth,
      semiBold,
    });

    return (
      <Button
        ref={ref}
        sx={[buttonSx, ...(Array.isArray(sx) ? sx : [sx])]}
        tabIndex={tabIndex}
        variant={variant}
        color={color}
        fullWidth={fullWidth}
        semiBold={semiBold}
        className={clsx(CIGRO_BUTTON_CLASSNAME, className)}
        {...nativeProps}
        //
      >
        {startIcon}
        {children}
        {endIcon}
      </Button>
    );
  },
);

// @ts-expect-error displayName
AppButton.displayName = 'AppButton';

export default AppButton;
