import type {
  GetTeamInvitationStatusResponse,
  GetTeamMembersPayload,
  UpdateTeamPayload,
} from '../api';
import type {
  EditTeamPermissionsPayload,
  EditTeamPermissionsResponse,
  InviteMembersPayload,
  RemoveInviteMemberPayload,
  RemoveTeamMembersPayload,
} from '@/feature-team/api';
import type { TeamDto } from '@/feature-team/model';
import type { MemberDto, UserDto } from '@/models/user';
import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';

import { useCallback } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { TeamApi } from '@/adapters/api';
import { ApiError } from '@/api-error';
import { UseTeamMembersOptionsType } from '@/layouts/navbar/use-team-members-options.type';
import { teamQK } from '@/query-key';

export type UseTeamsOptions<T> = UseQueryOptions<TeamDto[], unknown, T>;

export function useTeamsQuery<T = TeamDto[]>(options?: UseTeamsOptions<T>) {
  return useQuery({
    queryKey: [teamQK._main],
    queryFn: () => TeamApi.onBrowser().getAll(),
    ...options,
  });
}

export function useTeamMembers<T = MemberDto[]>(
  params: GetTeamMembersPayload,
  options?: UseTeamMembersOptionsType<T>,
) {
  const queryClient = useQueryClient();

  const { data, isInitialLoading } = useQuery<MemberDto[], unknown, T>(
    teamQK.members(params),
    () => TeamApi.onBrowser().getMembers(params),
    {
      ...options,
      enabled:
        Boolean(params.team_id) &&
        (options?.enabled != null ? options.enabled : true),
    },
  );

  const invalidateTeamMembersQuery = useCallback(() => {
    queryClient.invalidateQueries(teamQK.members(params));
  }, [queryClient, params]);

  return { data, isLoading: isInitialLoading, invalidateTeamMembersQuery };
}

export function useTeamMembersMutations(params: GetTeamMembersPayload) {
  const queryClient = useQueryClient();

  const removeMembersMutation = useMutation({
    mutationFn: (payload: RemoveTeamMembersPayload) =>
      TeamApi.onBrowser().removeMembers(params.team_id, payload),
    onSuccess(_, { revoked_member_ids }) {
      queryClient.setQueryData<UserDto[]>(
        teamQK.members(params),
        (members = []) =>
          members.filter((m) => !revoked_member_ids.includes(m.id)),
      );
    },
  });

  return { removeMembersMutation };
}

export const useTeamMembersPermissionsMutation = (
  params: GetTeamMembersPayload,
  options?: Omit<
    UseMutationOptions<
      EditTeamPermissionsResponse | null,
      ApiError,
      EditTeamPermissionsPayload,
      unknown
    >,
    'mutationFn'
  >,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload) =>
      TeamApi.onBrowser().editPermissions(params.team_id, payload),
    onMutate(newPermissions) {
      if (!newPermissions) return;

      queryClient.setQueryData<Array<UserDto>>(teamQK.members(params), (old) =>
        old?.map((mem) => {
          const hasPermissionChanged = newPermissions.find(
            (one) => one.member_id === mem.id,
          );

          return hasPermissionChanged
            ? { ...mem, permission: hasPermissionChanged.new_permission }
            : mem;
        }),
      );
    },
    ...options,
  });
};

export const useInviteNewMemberMutation = (
  teamId: string,
  options?: Omit<
    UseMutationOptions<void, ApiError, InviteMembersPayload, string[]>,
    'mutationFn'
  >,
) => {
  return useMutation({
    mutationFn: (payload) =>
      TeamApi.onBrowser().inviteNewMembers(teamId, payload),
    ...options,
  });
};

export const useRemoveInviteMemberMutation = (
  teamId: string,
  options?: Omit<
    UseMutationOptions<void, ApiError, RemoveInviteMemberPayload, string[]>,
    'mutationFn'
  >,
) => {
  return useMutation({
    mutationFn: (payload) =>
      TeamApi.onBrowser().removeInviteMember(teamId, payload),
    ...options,
  });
};

export const usePendingMembersQuery = <TData = GetTeamInvitationStatusResponse>(
  teamId: string,
  options?: Omit<
    UseQueryOptions<
      GetTeamInvitationStatusResponse[],
      unknown,
      TData[],
      string[]
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery({
    queryKey: teamQK.status(teamId),
    queryFn: () => TeamApi.onBrowser().getTeamInvitationStatus(teamId),
    ...options,
  });
};

export const useTeamDetailQuery = <TData = TeamDto>(
  teamId: string,
  options?: Omit<
    UseQueryOptions<TeamDto | null, ApiError, TData, string[]>,
    'queryKey' | 'queryFn'
  >,
) => {
  const queryClient = useQueryClient();

  const initialData = queryClient
    .getQueryData<Array<TeamDto>>([teamQK._main])
    ?.find((team) => team.id === teamId);

  return useQuery({
    queryKey: teamQK.detail(teamId),
    queryFn: () => TeamApi.onBrowser().getDetailById(teamId),
    initialData,
    ...options,
  });
};

export const useTeamSettingsMutation = (
  teamId: string,
  options?: Omit<
    UseMutationOptions<TeamDto | null, ApiError, UpdateTeamPayload, unknown>,
    'mutationFn'
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) => TeamApi.onBrowser().updateTeam(teamId, payload),
    onSuccess(newSettings) {
      if (!newSettings) return;

      queryClient.setQueryData<TeamDto>(teamQK.detail(teamId), newSettings);

      queryClient.setQueryData<Array<TeamDto>>([teamQK._main], (old) =>
        old?.map((team) => (team.id === teamId ? newSettings : team)),
      );
    },
    ...options,
  });
};
