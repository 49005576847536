import axios, { AxiosResponse } from 'axios';
import invariant from 'tiny-invariant';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import { clientHttp as axiosHttpClient } from '@/adapters/api';
import { zDateSchema } from '@/models/common';
import { serializeData } from '@/utils/data';

const GetPresignedUrlPayloadSchema = z.object({
  object_name: z.string(),
});

type GetPresignedUrlPayload = z.infer<typeof GetPresignedUrlPayloadSchema>;

const GetPresignedUrlResponseSchema = z.object({
  url: z.string(),
  fields: z.object({
    key: z.string(),
    policy: z.string(),
    ['x-amz-date']: z.string(),
    ['x-amz-signature']: z.string(),
    ['x-amz-algorithm']: z.string(),
    ['x-amz-credential']: z.string(),
  }),
  expires_at: zDateSchema,
});

type GetPresignedUrlResponse = z.infer<typeof GetPresignedUrlResponseSchema>;

// ----------------------------CORE
async function getPresignedUrlService(objectId: string) {
  const res = await axiosHttpClient.post<
    unknown,
    AxiosResponse<GetPresignedUrlResponse>,
    GetPresignedUrlPayload
  >('/v3/auth/users/presigned-urls/post', {
    object_name: objectId,
  });
  return serializeData(res?.data, GetPresignedUrlResponseSchema);
}

async function uploadFileService(presignedUrl: string, formData: FormData) {
  return await axios.postForm(presignedUrl, formData);
}

export function getFullUrlService(objectId: string) {
  if (objectId.startsWith('https://')) return objectId;

  const providerUrl = process.env.STORAGE_PROVIDER_URL;

  invariant(providerUrl, 'no file storage provider url env provided');
  if (objectId.startsWith(providerUrl)) return objectId;

  return providerUrl + '/' + objectId;
}

// ----------------------------EXTENSIONS

export async function uploadFileWithObjectIdService(
  objectId: string,
  file: File,
) {
  const urlRes = await getPresignedUrlService(objectId);
  if (!urlRes) throw new Error('no response when getting presigned url');
  const { url, fields } = urlRes;
  const formData = new FormData();
  Object.entries(fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append('file', file);
  await uploadFileService(url, formData);
}

export function getTeamAvatarFileObjectIdService(teamIdOrUuid: string) {
  return `team-avatar/${teamIdOrUuid}/${uuidv4()}`;
}

export function getUserAvatarFileObjectIdService(userId: string) {
  return `user-avatar/${userId}/${uuidv4()}`;
}
