'use client';

import React from 'react';

import { styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { CustomContentProps, SnackbarContent } from 'notistack';

export const SnackbarWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  padding: '14px 16px',
  borderRadius: '8px',
  margin: '0 auto',
  backgroundColor: theme.palette.neutralV2[8],
  boxShadow:
    '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
}));

interface AppSnackBarProps extends CustomContentProps {
  icon?: React.ReactNode;
}

export const AppSnackBar = React.forwardRef(function AppSnackBar(
  { id, message, icon, action }: AppSnackBarProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <SnackbarContent ref={ref}>
      <SnackbarWrapper>
        {icon}
        <Typography variant="body1">{message}</Typography>
        {typeof action === 'function' ? action(id) : action}
      </SnackbarWrapper>
    </SnackbarContent>
  );
});
