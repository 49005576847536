import type { SxProps, Theme } from '@mui/material/styles';

export const toCssString = (style: React.CSSProperties) =>
  Object.entries(style)
    .map(([k, v]) => {
      const cssKey = k.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`);
      return `${cssKey}:${v}`;
    })
    .join(';') + ';';

export const getScrollBarCss = (theme: Theme) => ({
  '::-webkit-scrollbar': {
    padding: '4px',
    width: '4px',
    height: '4px',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '10px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    background: theme.palette.neutral[4],
  },
});

export const spreadMuiSxes = (props?: SxProps<Theme> | null) =>
  props == null ? [] : Array.isArray(props) ? props : [props];
