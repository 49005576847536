import { Box, Stack, Typography } from '@mui/material';
import { CanceledError } from 'axios';
import { Trans } from 'next-i18next';
import {
  closeSnackbar,
  enqueueSnackbar,
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
} from 'notistack';

import { ApiError } from '@/api-error';
import { CloseButton } from '@/components/Dialog';
import AppIcon from '@/components/Icon';
import { SnackbarWrapper } from '@/components/SnackBar';
import { VIEW_TYPE_ICON_MAP } from '@/constants';

import AppButton from '../components/Button/Button.component';
import { ViewType } from '../models/view';

const ICON_SIZE_PX = 20;
const AUTO_HIDE_DURATION_MS = 2000;

const ERROR_OPTION: OptionsObject<'custom'> & { icon: JSX.Element } = {
  variant: 'custom',
  autoHideDuration: AUTO_HIDE_DURATION_MS,
  icon: (
    <AppIcon
      name="toast-warning"
      size={ICON_SIZE_PX}
      color={(theme) => theme.palette.semantic.negative100}
    />
  ),
};

const SUCCESS_OPTION: OptionsObject<'custom'> & { icon: JSX.Element } = {
  variant: 'custom',
  autoHideDuration: AUTO_HIDE_DURATION_MS,
  icon: (
    <AppIcon
      name="toast-success"
      size={ICON_SIZE_PX}
      color={(theme) => theme.palette.semantic.positive}
    />
  ),
};

export function toastSuccess(
  message: SnackbarMessage,
  opt?: OptionsObject<'custom'>,
) {
  if (typeof window === 'undefined') return;
  return enqueueSnackbar(message, { ...SUCCESS_OPTION, ...opt });
}

export function toastError(
  message: SnackbarMessage,
  opt?: OptionsObject<'custom'>,
) {
  if (
    (message as unknown) instanceof ApiError ||
    (message as unknown) instanceof CanceledError
  ) {
    console.error(message);
    return;
  }
  if (typeof window === 'undefined') return;

  return enqueueSnackbar(message, { ...ERROR_OPTION, ...opt });
}
export const VIEW_TYPE_TO_ICON_NAME_MAP = {
  BOARD: 'ic_chart_28_board',
  BAR_CHART: 'ic_chart_28_bar1',
  PIE_CHART: 'ic_chart_28_pie1',
  LINE_CHART: 'ic_chart_28_line1',
  TABLE: 'ic_chart_28_table1',
  PIVOT_TABLE: 'ic_chart_28_pivot',
  GALLERY: 'image-line',
  GOAL: 'focus-2-line',
};
export function toastPrompt({
  header,
  buttonLabel,
  message,
  key,
  subtext,
  viewType,
  onClickView,
  isSucceed = true,
  isGlobal = true,
  opt,
}: {
  header: string;
  buttonLabel: string;
  message: SnackbarMessage;
  key: string;
  subtext?: string;
  viewType?: ViewType;
  onClickView?: () => void;
  isSucceed?: boolean;
  isGlobal?: boolean;
  opt?: OptionsObject<'custom'>;
}) {
  if (typeof window === 'undefined') return;
  if (isSucceed && !viewType) return;
  enqueueSnackbar('', {
    key,
    variant: 'custom',
    autoHideDuration: AUTO_HIDE_DURATION_MS,
    persist: isGlobal ? true : false,
    preventDuplicate: true,
    icon: <></>,
    content: () => {
      return (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="start"
          minWidth={0}
          padding="12px"
          pr="8px"
          borderRadius="8px"
          margin="0 auto"
          sx={{
            bgcolor: 'neutral.7',
            boxShadow:
              '2px 6px 12px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(5, 43, 97, 0.12)',
          }}>
          <Stack direction="row" gap="8px" alignItems="center">
            <AppIcon
              name={
                isSucceed
                  ? VIEW_TYPE_ICON_MAP[viewType ?? 'TABLE']
                  : 'ic_chart_28_error'
              }
              size={28}
              color={(theme) => theme.palette.neutralV2[2]}
            />
            <AppIcon
              name="ic_magic_16"
              size={16}
              color={(theme) =>
                isSucceed
                  ? theme.palette.semantic.positive
                  : theme.palette.semantic.negative100
              }
              sx={{ marginTop: '-22px', marginLeft: '-15px' }}
            />
            <Stack
              minWidth={0}
              maxWidth="256px"
              sx={{
                gap: '4px',
              }}>
              <Stack alignItems="center" direction="row" gap="4px">
                <AppIcon
                  name={isSucceed ? 'toast-success' : 'toast-warning'}
                  size={16}
                  color={(theme) =>
                    isSucceed
                      ? theme.palette.semantic.positive
                      : theme.palette.semantic.negative100
                  }
                />
                <Typography variant="caption" color="text.1">
                  {header}
                </Typography>
              </Stack>
              <Typography
                variant="small1"
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}>
                {message}
              </Typography>
              <Stack>
                {isGlobal && (
                  <Typography color="text.2" variant="caption2">
                    {subtext}
                  </Typography>
                )}
              </Stack>
            </Stack>

            {isGlobal && (
              <AppButton
                variant="text-outlined"
                onClick={() => {
                  if (onClickView) onClickView();
                  setTimeout(() => closeSnackbar(key), 1000);
                }}
                sx={{
                  padding: '7px 15px',
                  border: '1px solid',
                  borderColor: isSucceed
                    ? 'semantic.positive'
                    : 'semantic.negative100',
                  ':active': {
                    borderColor: isSucceed
                      ? 'semantic.positive'
                      : 'semantic.negative100',
                    outline: 'none',
                  },
                  color: 'text.0',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '16px',
                }}>
                {buttonLabel}
              </AppButton>
            )}

            {isGlobal && (
              <AppButton
                onClick={() => closeSnackbar(key)}
                variant="icon-only"
                sx={{
                  padding: '10px',
                  ml: '-4px',
                }}>
                <AppIcon
                  name="ic_close_12"
                  size={12}
                  color={(theme) => theme.palette.neutralV2[3]}
                />
              </AppButton>
            )}
          </Stack>
        </Stack>
      );
    },
    ...opt,
  });
}

export const toastSelectedItemsAction = (
  message: SnackbarMessage,
  onConfirm?: () => void,
  onDismiss?: () => void,
  opt?: OptionsObject<'custom'>,
) => {
  if (typeof window === undefined) return;

  enqueueSnackbar(message, {
    persist: true,
    preventDuplicate: true,
    hideIconVariant: true,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    onEntered() {
      closeSnackbar();
    },
    content(key, message) {
      return (
        <SnackbarWrapper
          sx={{
            gap: 2,
            padding: '12px 12px 12px 24px',
          }}>
          <Typography variant="small1">{message}</Typography>
          <Box display="inline-flex" gap="4px">
            <AppButton
              sx={{ height: 32, p: '12px 10px' }}
              onClick={onConfirm}
              variant="text-outlined"
              startIcon={<AppIcon name="ic_Delete_12" size={14} />}>
              <Typography variant="small1" color="text.0">
                <Trans i18nKey="page-transformation.delete">delete</Trans>
              </Typography>
            </AppButton>

            <AppButton
              sx={{ height: 32, p: '12px 10px' }}
              variant="text"
              onClick={() => {
                if (onDismiss) onDismiss();
                closeSnackbar(key);
              }}>
              <Typography variant="small1" color="text.1">
                <Trans i18nKey="page-transformation.deselect">deselect</Trans>
              </Typography>
            </AppButton>
          </Box>
        </SnackbarWrapper>
      );
    },
    ...opt,
  });
};

const CloseNotificationButton: React.FC<{ notificationKey: SnackbarKey }> = ({
  notificationKey,
}) => {
  return (
    <CloseButton
      sx={{ position: 'static' }}
      onClose={() => closeSnackbar(notificationKey)}
    />
  );
};

export const WITH_CLOSE_ACTION_OPTION: OptionsObject<'custom'> = {
  persist: true,
  action: (key) => <CloseNotificationButton notificationKey={key} />,
};
