import type { UseQueryOptions } from '@tanstack/react-query';

import {
  AuthApi,
  DashboardApi,
  DataModelsApi,
  WidgetApi,
} from './adapters/api';
import { DatasetType } from './models/data-model';
import { dashboardQK, dataModelQK, userQK, widgetQK } from './query-key';

type QueryKeyFactory = (...args: any) => {
  queryKey: Readonly<unknown[]>;
  queryFn: () => Promise<unknown>;
};

export type QueryKeyDataInfer<T extends QueryKeyFactory> = Awaited<
  ReturnType<ReturnType<T>['queryFn']>
>;

export type QueryKeyOptionsInfer<
  T extends QueryKeyFactory,
  TData,
> = UseQueryOptions<
  Awaited<ReturnType<ReturnType<T>['queryFn']>>,
  unknown,
  TData,
  ReturnType<T>['queryKey']
>;

export const userQueryKeys = {
  permissions: (userId: string) => ({
    queryKey: userQK.permissions(userId),
    queryFn: () => AuthApi.onBrowser().getPermissions(),
  }),
};

// export type UserQueryKeyOptions =

export const dataModelQueryKeys = {
  detail: (teamId: string, datasetType: DatasetType | 'ALL') =>
    ({
      queryKey: dataModelQK.detail(teamId, datasetType),
      queryFn: () =>
        DataModelsApi.onBrowser().getDataModelsAPI(teamId, datasetType),
    } as const),
  config: ({
    teamId,
    dataModelId,
    datasetId,
  }: {
    teamId: string;
    dataModelId: string;
    datasetId: string;
  }) =>
    ({
      queryKey: dataModelQK.getDataModelConfig(teamId, datasetId, dataModelId),
      queryFn: () =>
        DataModelsApi.onBrowser().getConfig({
          teamId,
          dataModelId,
          datasetId,
        }),
    } as const),
  getDataModelsDetail: ({
    teamId,
    dataModelId,
    datasetId,
  }: {
    teamId: string;
    dataModelId: string;
    datasetId?: string | undefined;
  }) =>
    ({
      queryKey: dataModelQK.getDataModelsDetail(
        teamId,
        datasetId || teamId,
        dataModelId,
      ),
      queryFn: () =>
        DataModelsApi.onBrowser().getById(
          teamId,
          datasetId || teamId,
          dataModelId,
        ),
    } as const),
};

export const widgetQueryKeys = {
  all: (teamId: string, dashboardId: string) => ({
    queryKey: widgetQK.all(teamId, dashboardId),
    queryFn: () => WidgetApi.onBrowser().getAll(teamId, dashboardId),
  }),
  detail: (teamId: string, dashboardId: string, widgetId: string) =>
    ({
      queryKey: widgetQK.detail(teamId, dashboardId, widgetId),
      queryFn: () =>
        WidgetApi.onBrowser().getDetail(teamId, dashboardId, widgetId),
    } as const),
};

export const dashboardQueryKeys = {
  all: (teamId: string) => ({
    queryKey: dashboardQK.all(teamId),
    queryFn: () => DashboardApi.onBrowser().getAll(teamId),
  }),
  detail: (teamId: string, dashboardId: string) => ({
    queryKey: dashboardQK.detail(teamId, dashboardId),
    queryFn: () => DashboardApi.onBrowser().getDetail(teamId, dashboardId),
  }),
  templates: (teamId: string) => ({
    queryKey: dashboardQK.templates(teamId),
    queryFn: () => DashboardApi.onBrowser().getTemplates(teamId),
  }),
};
