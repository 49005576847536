import type { Components, Theme } from '@mui/material/styles';

const InputTextFieldTheme: Components<Omit<Theme, 'components'>> = {
  MuiInputBase: {
    styleOverrides: {},
  },
  MuiInput: {
    styleOverrides: {
      formControl: {
        border: 'none',
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'filled',
    },
  },
  MuiFilledInput: {
    defaultProps: {
      disableUnderline: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: 1 * theme.shape.borderRadius,
      }),
      input: ({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(2),
        borderRadius: 1 * theme.shape.borderRadius,
        backgroundColor: theme.palette.neutral[6],
        color: theme.palette.text[0],
        caretColor: theme.palette.text[0],
        '&::placeholder': {
          color: theme.palette.text[2],
        },
        ':hover': {
          boxShadow: `0px 0px 0px 1px ${theme.palette.neutralV2[25]}`,
        },
        ':focus': {
          boxShadow: `0px 0px 0px 1px ${theme.palette.neutralV2[2]}`,
          background: theme.palette.neutral[7],
        },
        ':disabled': {
          boxShadow: `0px!important`,
        },
        '&[type=number]': {
          MozAppearance: 'textfield',
        },
        '&::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: '0px',
        marginTop: '4px',
      },
    },
  },
  MuiOutlinedInput: {},
};

export default InputTextFieldTheme;
