import type { ReportTableData } from './feature-report/types';
import type { MaybeErrorLocale } from '@/common.type';

import { AxiosError } from 'axios';
import lodashIsEmpty from 'lodash/isEmpty';

import { ApiServerError } from '@/models/common';

export class ApiError extends AxiosError {
  private __apiError: ApiServerError;
  private __statusCode: number;
  private __originError: any;

  constructor(
    originError: any,
    e: ApiServerError,
    statusCode = 500,
    msg = '[api-error]',
  ) {
    super(e.error_message || msg);
    this.__apiError = e;
    this.__statusCode = statusCode;
    this.__originError = originError;
  }

  public get originError() {
    return this.__originError;
  }

  public get statusCode() {
    return this.__statusCode;
  }

  public get apiError() {
    return this.__apiError;
  }

  public get errorCode() {
    return (
      (this.__apiError.error_code?.toString() as MaybeErrorLocale) || 'unknown'
    );
  }

  public get errorMessage() {
    return this.__apiError.error_message;
  }

  isUserError() {
    return this.__statusCode >= 400 && this.__statusCode < 500;
  }
}

function isApiError(error: unknown): error is ApiError {
  return error instanceof ApiError;
}

type ReportError = NonNullable<ReportTableData['errors']>;

type ReportResponse = ReportTableData;

interface Issue<Data, RetryParams> {
  data: Data;
  retry: (
    params: RetryParams,
  ) => Promise<ReportResponse | null> | ReportResponse | null;
  checkIfFixed: (response: ReportResponse) => Promise<boolean> | boolean;
}

type FxIssueRetryParam = {
  columnId: string;
  fixed: { formula: string };
};

type FxIssueData = {
  columnId: string;
  message: string;
};

export interface FxIssue extends Issue<FxIssueData, FxIssueRetryParam> {}

const BAD_REQUEST = 400;
const GENERIC_SERVER_ERR_CODE = 40000;
const RESOLVABLE_ERROR_DETAIL = 'RESOLVABLE_ERROR';
const FIXED = true;
const NOT_FIXED = false;

function checkIfFxColumnHasBeenFixed(
  columnId: string,
  apiRes: ReportTableData | null,
): boolean {
  if (!apiRes) return NOT_FIXED;
  // has fixed all issues!
  if (lodashIsEmpty(apiRes.errors?.custom_formulas)) return FIXED;
  // has fixed only columnId's issue
  return apiRes.errors.custom_formulas[columnId] == null ? FIXED : NOT_FIXED;
}

function toFxIssues(
  customFormulaErrors: ReportError['custom_formulas'] | null | undefined,
  retryFn: FxIssue['retry'],
): FxIssue[] {
  if (lodashIsEmpty(customFormulaErrors)) return [];

  return Object.entries(customFormulaErrors).reduce<FxIssue[]>(
    (_issues, [columnId, errorMessage]) => {
      _issues.push({
        data: {
          columnId,
          message: errorMessage,
        },
        retry: retryFn,
        checkIfFixed: (fixedRes) =>
          checkIfFxColumnHasBeenFixed(columnId, fixedRes),
      });
      return _issues;
    },
    [],
  );
}

/**
 * A generic class for resolvable API issue
 */
class ApiIssue<Data, RetryParams> extends ApiError {
  private __issues: Issue<Data, RetryParams>[] = [];

  constructor(issues: Issue<Data, RetryParams>[]) {
    super(
      issues,
      {
        detail: RESOLVABLE_ERROR_DETAIL,
        error_code: GENERIC_SERVER_ERR_CODE,
      },
      BAD_REQUEST,
    );
    this.__issues = issues;
  }

  protected get issues(): Issue<Data, RetryParams>[] {
    return this.__issues;
  }
}

/**
 * A resolvable formula-related API issue
 */
class FxApiIssue extends ApiIssue<FxIssueData, FxIssueRetryParam> {
  constructor(fxIssues: FxIssue[]) {
    super(fxIssues);
  }

  get fxIssues(): FxIssue[] {
    return this.issues;
  }
}

function composeFxApiIssues(apiFxIssues: Array<FxApiIssue>): FxApiIssue | null {
  const issues: FxIssue[] = [];

  for (let index = 0; index < apiFxIssues.length; index++) {
    const apiIssue = apiFxIssues[index];
    if (apiIssue == null) continue;
    issues.push(...apiIssue.fxIssues);
  }

  if (!issues.length) return null;

  return new FxApiIssue(issues);
}

function createFxApiIssue(param: {
  customFormulaErrors: ReportError['custom_formulas'] | null | undefined;
  retryFn: FxIssue['retry'];
}): FxApiIssue | null {
  if (lodashIsEmpty(param.customFormulaErrors)) return null;
  const { customFormulaErrors, retryFn } = param;
  const fxIssues = toFxIssues(customFormulaErrors, retryFn);
  if (!fxIssues.length) return null;
  return new FxApiIssue(fxIssues);
}

/**
 * Check if report API's response has formula issues.
 *
 * If pass multiple params, it will compose into a single `FxApiIssue`
 *
 * @param responsesWithRetry
 * @returns
 */
function checkFxApiIssue(
  ...responsesWithRetry: Array<{
    customFormulaErrors: ReportError['custom_formulas'] | null | undefined;
    retryFn: FxIssue['retry'];
  }>
): FxApiIssue | null {
  const fxIssues: FxApiIssue[] = [];

  for (let index = 0; index < responsesWithRetry.length; index++) {
    const { customFormulaErrors, retryFn } = responsesWithRetry[index];
    const issue = createFxApiIssue({ customFormulaErrors, retryFn });
    if (issue == null) continue;
    fxIssues.push(issue);
  }

  return composeFxApiIssues(fxIssues);
}

/**
 * Check if an error is `FxApiIssue`
 */
function isFxApiIssue(error: unknown): error is FxApiIssue {
  return error instanceof FxApiIssue;
}

export const ApiErrorUtils = {
  isApiError,
  isFxApiIssue,
  checkFxApiIssue,
  composeFxApiIssues,
};
