import { z } from 'zod';

import {
  zAggregateItem,
  zCommonChartConfig,
  zGrouping,
  zGroupItem,
} from './common';
import { zCompatibleCommonConfig } from './compatibility';

export const zBarChartConfig = zCommonChartConfig.extend({
  view_type: z.literal('BAR_CHART'),
});

export const zBarChartFullConfig =
  zCompatibleCommonConfig.merge(zBarChartConfig);

export type BarChartFullConfig = z.infer<typeof zBarChartFullConfig>;

export const zBarChartRenderingConfig = zBarChartFullConfig.merge(
  z.object({
    grouping: zGrouping.merge(
      z.object({
        columns: z
          .array(zGroupItem)
          .min(1, {
            message: 'bar-chart-config-grouping-columns-length-min-1',
          })
          .max(2, {
            message: 'bar-chart-config-grouping-columns-length-max-2',
          }),
        sub_aggregates: z.array(zAggregateItem).length(1, {
          message: 'bar-chart-config-grouping-sub_aggregates-length-eq-1',
        }),
      }),
    ),
  }),
);

export type BarChartConfig = z.infer<typeof zBarChartConfig>;
