import type { ReportTableDataResult } from '@/hooks/queries/use-table';
import type { Theme } from '@mui/material/styles';
import type { CellContext } from '@tanstack/react-table';

import Box from '@mui/material/Box';
import dayjs from 'dayjs';

import Highlighter from '@/components/Highlighter';

import { isFalsyValue } from '../feature-report/table-view/v2/cell-utils';
import { ConditionVariant } from '../models/view';
import { fromTimeRangeToClientDateStrings } from './date';
import { addCommasAndSign, convertString, roundUp } from './number';

const INFINITY_FORMULA_RESULT = '∞';

export const getCellValue = (
  context: CellContext<ReportTableDataResult, unknown>,
  searchKeyword: string | null,
  decimalPlace: number = -1,
) => {
  const columnMeta = context.column.columnDef.meta;
  const value = context.getValue();

  const isBooleanColumn =
    columnMeta?.displayColumnType === 'BOOLEAN' || typeof value === 'boolean';
  if (isBooleanColumn) {
    return value ? 'true' : 'false';
  }

  const parsedValue = `${value}`;
  const keywordIndex =
    searchKeyword !== null ? parsedValue.indexOf(searchKeyword) : -1;

  const isNumericColumn = columnMeta?.displayColumnType === 'NUMBER';

  const formatNumericValue = (v: any, dp?: number): any => {
    if (!isNumericColumn || typeof v !== 'number') return v;
    if (!v) return 0;

    return addCommasAndSign(roundUp(convertString(v), dp));
  };

  if (searchKeyword !== null && keywordIndex !== -1) {
    const prefix = parsedValue.slice(0, keywordIndex);
    const suffix = parsedValue.slice(keywordIndex + searchKeyword.length);
    const highlightedValue = formatNumericValue(searchKeyword);
    return (
      <span>
        {prefix}
        <span style={{ backgroundColor: 'yellow' }}>{highlightedValue}</span>
        {suffix}
      </span>
    );
  }

  const displayValue = formatNumericValue(value, decimalPlace);

  return displayValue;
};

const FALLBACK_VALUE = '';

export const getHumanFriendlyValue = (
  rawValue: any,
  decimalPlace?: number,
): string | number | bigint => {
  switch (typeof rawValue) {
    case 'undefined':
    case 'symbol':
    case 'object':
    case 'function': {
      // TODO: don't know why!
      if (Array.isArray(rawValue)) {
        return rawValue.length
          ? getHumanFriendlyValue(rawValue[0], decimalPlace)
          : FALLBACK_VALUE;
      }

      // NOTE: show nothing
      return FALLBACK_VALUE;
    }
    // NOTE: parse to string
    case 'boolean': {
      return rawValue.toString();
    }
    case 'number': {
      if (rawValue === Infinity) return INFINITY_FORMULA_RESULT;

      if (Number.isNaN(rawValue)) {
        return FALLBACK_VALUE;
      }

      return rawValue;
    }
    case 'string': // NOTE: string but maybe numeric
    case 'bigint': {
      return rawValue;
    }
  }
};

export const getCellDisplayValueV3 = (
  context: CellContext<ReportTableDataResult, unknown>,
  searchKeyword: string | null,
  decimalPlace: number = -1,
) => {
  // const columnMeta = context.column.columnDef.meta;
  let value = context.getValue();

  if (searchKeyword) {
    const stringifiedValue = `${isFalsyValue(value) ? '' : value}`;
    return (
      <Highlighter
        component="span"
        text={stringifiedValue}
        highlight={searchKeyword}
      />
    );
  }

  return getHumanFriendlyValue(value, decimalPlace);
};

export const drawBorder = (theme: Theme) => {
  return `2px solid ${theme.palette.primary[10]}`;
};

export const renderPath = (length: number) => {
  return Array.from({ length }, (_, i) => i).map((i) => (
    <Box key={i} component="span" className={`path${i + 1}`} />
  ));
};

export const wrapCellContentWithHighlight = ({
  keyword,
  textToHighlight,
}: {
  textToHighlight: string;
  keyword: string;
}) => {
  return <Highlighter text={textToHighlight} highlight={keyword} />;
};

// 1 is black, 0 is white
// Hex code format: #000000
export const getTextColorByBackgroundColor = (bgHexCode: string) => {
  var r = parseInt(bgHexCode.substring(1, 3), 16);
  var g = parseInt(bgHexCode.substring(3, 5), 16);
  var b = parseInt(bgHexCode.substring(5, 7), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 1 : 0;
};

export const getAppliedColorByVariants = (
  value: string | number | boolean | undefined | null,
  colorVariants?: ConditionVariant[],
) => {
  if (!colorVariants || colorVariants.length === 0) return;

  if (value === null || value === undefined) return;

  const firstMatchVariants = colorVariants.find((item) => {
    const { condition } = item;

    if (condition.column_type === 'TEXT' && condition.value) {
      if (condition.operator === 'CONTAIN')
        return value.toString().includes(condition.value);
      if (condition.operator === 'NOT_CONTAIN')
        return !value.toString().includes(condition.value);
      if (condition.operator === 'IS' && Array.isArray(condition.value))
        return condition.value.some((option) => option === value);
      if (condition.operator === 'IS_NOT' && Array.isArray(condition.value))
        return condition.value.every((option) => option !== value);

      return false;
    }

    if (
      condition.column_type === 'NUMBER' &&
      condition.value !== null &&
      typeof value === 'number'
    ) {
      if (condition.operator === 'EQUAL') return value === condition.value;
      if (condition.operator === 'GREATER_OR_EQUAL')
        return value >= condition.value;
      if (condition.operator === 'GREATER_THAN') return value > condition.value;
      if (condition.operator === 'LESS_OR_EQUAL')
        return value <= condition.value;
      if (condition.operator === 'LESS_THAN') return value < condition.value;
      return false;
    }
    if (condition.column_type === 'BOOLEAN') {
      const parsedValue = Boolean(value) && value !== 'false';
      return parsedValue === Boolean(condition.value);
    }

    if (condition.column_type === 'DATETIME' && typeof value === 'string') {
      const valueDate = dayjs(value);

      if (condition.time_range === 'CUSTOM') {
        if (!condition.from_time || !condition.to_time) return false;
        const fromDate = dayjs(condition.from_time);
        const toDate = dayjs(condition.to_time);
        return valueDate >= fromDate && valueDate <= toDate;
      }

      const maybeDateStrings = fromTimeRangeToClientDateStrings(
        condition.time_range,
        condition.first_day_of_week,
      );

      if (!maybeDateStrings) return false;

      const fromDate = dayjs(maybeDateStrings[0]);
      const toDate = dayjs(maybeDateStrings[1]);

      return valueDate >= fromDate && valueDate <= toDate;
    }
  });

  if (!firstMatchVariants) return;

  return firstMatchVariants.color;
};
