const LogoCigro2 = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      //
    >
      <rect
        x="0.5"
        width="32"
        height="32"
        rx="16"
        fill="url(#paint0_linear_3213_16488)"
      />
      <mask
        id="mask0_3213_16488"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="4"
        width="23"
        height="24">
        <path
          d="M27.7 15.9998C27.7 19.4646 26.1268 22.5619 23.6556 24.6163C21.715 26.2297 19.2209 27.1998 16.5 27.1998C13.7792 27.1998 11.285 26.2297 9.34449 24.6163C6.87334 22.5619 5.30005 19.4646 5.30005 15.9998C5.30005 13.3511 6.21945 10.9172 7.75653 8.9998C9.80911 6.4393 12.9631 4.7998 16.5 4.7998C20.037 4.7998 23.1909 6.4393 25.2436 8.9998C26.7806 10.9172 27.7 13.3511 27.7 15.9998Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3213_16488)">
        <path
          d="M16.5 27.1997C19.2208 27.1997 21.715 26.2296 23.6556 24.6162H9.34448C11.285 26.2296 13.7792 27.1997 16.5 27.1997Z"
          fill="white"
        />
        <path
          d="M16.4863 4.81344C13.7655 4.81344 11.2714 5.78356 9.33071 7.39697L23.6418 7.39697C21.7013 5.78356 19.2071 4.81344 16.4863 4.81344Z"
          fill="white"
        />
        <path
          d="M27.6884 19.7334H5.30005V21.9112H27.6884V19.7334Z"
          fill="white"
        />
        <path
          d="M16.3401 14.6878C16.3401 14.5773 16.4296 14.4878 16.5401 14.4878H27.5355V16.7603H16.5401C16.4296 16.7603 16.3401 16.6707 16.3401 16.5603V14.6878Z"
          fill="white"
        />
        <path
          d="M27.6884 10.0889H5.30005V12.2666H27.6884V10.0889Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3213_16488"
          x1="16.5"
          y1="0"
          x2="16.5"
          y2="32"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#333547" />
          <stop offset="1" stop-color="#17181E" />
        </linearGradient>
      </defs>
    </svg>
  );
  // return (
  //   <svg
  //     width="33"
  //     height="32"
  //     viewBox="0 0 33 32"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg">
  //     <path
  //       d="M29.0256 20.6911C28.4113 22.2204 27.5158 23.5832 26.3391 24.7588C22.5059 28.5886 16.7119 29.3143 12.1368 26.9278C11.5065 26.599 10.7236 26.6694 10.2207 27.1719C9.5661 27.8259 9.65433 28.9166 10.4645 29.3637C16.1979 32.5272 23.5551 31.6912 28.4113 26.8395C29.869 25.383 30.9936 23.6768 31.7538 21.7834C32.0557 21.024 31.6913 20.1709 30.9311 19.8692C30.171 19.5675 29.3068 19.9421 29.0152 20.6911H29.0256Z"
  //       fill="#202020"
  //     />
  //     <path
  //       d="M6.77471 5.34722C1.45381 10.6633 0.974823 18.986 5.29611 24.8847C5.369 25.0199 5.4523 25.1552 5.56684 25.2696H5.57725C6.06829 25.9125 7.00797 25.9208 7.58023 25.3491L8.61777 24.3125L15.688 17.2486L18.1662 20.5465C18.4266 20.8898 18.8118 21.0979 19.2388 21.1291C19.6657 21.1603 20.0822 21.0042 20.3842 20.7025L25.0491 16.0418V17.3631C25.0491 18.0913 25.6426 18.6947 26.3819 18.6843C26.7463 18.6843 27.0795 18.5386 27.319 18.2994C27.5585 18.0601 27.7043 17.7272 27.7043 17.3631V12.7024C27.7043 11.9741 27.1108 11.3708 26.3715 11.3708H21.7066C20.9777 11.3708 20.3737 11.9637 20.3737 12.7024C20.3737 13.441 20.9673 14.034 21.7066 14.034H22.8936L19.5095 17.4151L17.0313 14.1172C16.7709 13.7739 16.3857 13.5659 15.9587 13.5346C15.5318 13.5034 15.1153 13.6595 14.8133 13.9612L6.86843 21.8989C4.3069 17.2902 4.95249 11.3603 8.86767 7.44869C13.5951 2.72558 21.2901 2.72558 26.0279 7.44869C26.6422 8.06249 27.1941 8.73871 27.6522 9.46694C28.0896 10.1536 29.0059 10.3512 29.6931 9.90388C30.3804 9.46694 30.5782 8.55145 30.1305 7.86483C29.5474 6.97014 28.8705 6.12747 28.1104 5.36803C22.2272 -0.530662 12.6579 -0.530662 6.77471 5.34722Z"
  //       fill="#202020"
  //     />
  //   </svg>
  // );
};

export default LogoCigro2;
