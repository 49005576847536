import type { AppAbility } from '@/adapters/rbac/types';
import type { GetPermissionsResponse } from '@/feature-auth/api';
import type { DashboardPermissions, TeamPermissions } from '@/models/user';

import { AbilityBuilder, createMongoAbility } from '@casl/ability';

import { DASHBOARD_SUBJECT, TEAM_SUBJECT } from '@/adapters/rbac/constants';

const updateTeamAbilities = (
  ability: AppAbility,
  teams: { id: string; permission: TeamPermissions }[],
) => {
  const { can, rules } = new AbilityBuilder<AppAbility>(createMongoAbility);
  for (const team of teams.filter((t) => !!t.permission)) {
    const condition = {
      id: {
        $eq: team.id,
      },
    };

    switch (team.permission) {
      case 'OWNER': {
        can('own', TEAM_SUBJECT, undefined, condition);
        can('edit', TEAM_SUBJECT, undefined, condition);
        can('view', TEAM_SUBJECT, undefined, condition);
        break;
      }
      case 'MEMBER':
      default: {
        can('view', TEAM_SUBJECT, undefined, condition);
      }
    }
  }

  // NOTE: append new rules
  const newRules = ability.rules.concat(rules);
  ability.update(newRules);
};

const updateDashboardAbilities = (
  ability: AppAbility,
  dashboards: { id: string; permission: DashboardPermissions }[],
) => {
  const { can, rules } = new AbilityBuilder<AppAbility>(createMongoAbility);
  const dashboardsWithPermission = dashboards.filter((t) =>
    Boolean(t.permission),
  );
  for (const dashboard of dashboardsWithPermission) {
    const condition = {
      id: {
        $eq: dashboard.id,
      },
    };

    switch (dashboard.permission) {
      case 'OWNER':
        can('own', DASHBOARD_SUBJECT, undefined, condition);
        can('edit', DASHBOARD_SUBJECT, undefined, condition);
        can('view', DASHBOARD_SUBJECT, undefined, condition);
      case 'EDITOR': {
        can('edit', DASHBOARD_SUBJECT, undefined, condition);
        can('view', DASHBOARD_SUBJECT, undefined, condition);
        break;
      }
      case 'VIEWER':
      default: {
        can('view', DASHBOARD_SUBJECT, undefined, condition);
        break;
      }
    }
  }

  // NOTE: append new rules
  const newRules = ability.rules.concat(rules);
  ability.update(newRules);
};

export function setupUserPermissions(
  ability: AppAbility,
  permissionResult: GetPermissionsResponse | null,
) {
  if (permissionResult == null) return;

  const { team_permissions, dashboard_permissions } = permissionResult;

  if (team_permissions == null) return;

  const teamPermissions = team_permissions.map((t) => ({
    id: t?.team_id ?? '',
    permission: t?.permission ?? 'MEMBER',
  }));

  if (!teamPermissions.length) return;

  // NOTE: team permissions
  updateTeamAbilities(ability, teamPermissions);

  if (dashboard_permissions == null) return;

  const dashboardPermissions = dashboard_permissions.map((t) => ({
    id: t?.dashboard_id ?? '',
    permission: t?.permission ?? 'VIEWER',
  }));

  if (!dashboardPermissions.length) return;

  // NOTE: dashboard permissions
  updateDashboardAbilities(ability, dashboardPermissions);
}
