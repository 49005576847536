import { z } from 'zod';

import { makeSafeSchema } from '../../utils/data';
import {
  zAggregateType,
  zColumnType,
  zGroupItem,
  zPeriodicity,
  zSortItem,
  zSortOption,
} from './common';
import { zCompatibleCommonConfig } from './compatibility';

export const zColumnCategory = z.enum([
  'UNGROUPED',
  'GROUPED',
  'AGGREGATION',
  'DATE',
]);
export type ColumnCategory = z.infer<typeof zColumnCategory>;

export const zSearchTableItem = z.object({
  property: z.string(),
});
export type SearchTableItem = z.infer<typeof zSearchTableItem>;

export const zAggregateColumn = z.object({
  name: z.string(),
  type: zColumnType,
  category: zColumnCategory,
});

export type AggregateColumn = z.infer<typeof zAggregateColumn>;

export const zTimeseries = z.object({
  target: z.string(),
  target_datetime_column: z.string(),
  periodicity: zPeriodicity,
});
export type Timeseries = z.infer<typeof zTimeseries>;

export const zColumnAggregateItem = z.object({
  column: zAggregateColumn,
  function: zAggregateType,
  grouped_columns: z.array(z.string()),
  timeseries: zTimeseries.nullable(),
});
export type ColumnAggregateItem = z.infer<typeof zColumnAggregateItem>;

// NOTE: zColumnAggregate is like type: {[columnName: string]: ColumnAggregateItem};
// and shape of key is below
// `${viewId}::${sourceName}::${columnName}`
export const zColumnAggregate = z.record(z.string(), zColumnAggregateItem);
export type ColumnAggregate = z.infer<typeof zColumnAggregate>;

const zRoundUpItem = z.object({
  value: z.number(),
});

// Key Convention
// 1) {"normal::all": {"value": 0}, "normal::ad_cost": {"value": 3}}
//   : Let all NUMBER type columns roundUp to decimal place 0 but setting only `ad_cost` to 3
// 2) {"time_series::all", {"value": 0}, "time_series::aggregation": {"value": 1}, "time_series::2023-01-01": {"value": 2}}
//   : Let all NUMBER type of timeSeries date columns roundUp to decimal place 0
//     but setting `2023-01-01` to 2 and aggregation column (ex. AVG ad_cost, SUM ad_cost, etc.) to 1
const zRoundUp = z.record(z.string(), zRoundUpItem);
export type RoundUp = z.infer<typeof zRoundUp>;

// NOTE: column sizes
export const zColumnSize = z.record(z.string(), z.number().positive());

export type ColumnSize = z.infer<typeof zColumnSize>;

export const zTableConfig = z.object({
  view_type: z.literal('TABLE'),
  search_value: z.string().nullish(),
  sort: makeSafeSchema(z.array(zSortItem), []),
  column_grouping: makeSafeSchema(z.array(zGroupItem), []),
  // NOTE: table does not use these properties
  time_series: z
    .object({
      periodicity: zPeriodicity,
      sort_option: zSortOption.nullish().default('ASC'),
    })
    .nullish(),
  column_aggregation: zColumnAggregate.nullish(),
  fixed_columns: z.array(zGroupItem).nullish().default([]),
  column_size: zColumnSize.nullish(),
  round_up: zRoundUp.nullish(),
  pagination: z
    .object({
      page: z.number(),
      perPage: z.number(),
    })
    .nullish(),
});

export type TableConfig = z.infer<typeof zTableConfig>;

export const zTableFullConfig = zCompatibleCommonConfig.merge(zTableConfig);

export type TableFullConfig = z.infer<typeof zTableFullConfig>;
