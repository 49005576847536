import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import { useSession } from 'next-auth/react';

function expiresInSeconds(expiresAtUtcDateString: string): number {
  const nowUtc = dayjs(new Date().toUTCString());
  const expirationUtc = dayjs(expiresAtUtcDateString);
  return expirationUtc.diff(nowUtc, 'seconds');
}

// const SOON_SECS = 15;
// const MINIMUM_SECS_TO_REFETCH = 3;
const SOON_SECS = 5;

const RefreshTokenHandler: React.FC<{
  setRefetchInterval: (interval: number) => void;
}> = ({ setRefetchInterval }) => {
  const { data: session, status: sessionStatus } = useSession();

  const sessionExpiresInSeconds = !session?.access_token_expires_at
    ? null
    : expiresInSeconds(session.access_token_expires_at) - SOON_SECS;

  useEffect(() => {
    if (session?.error || sessionStatus === 'loading') {
      return;
    }

    // NOTE: polling when session has been expired, til it's refreshed
    if (!sessionExpiresInSeconds || sessionExpiresInSeconds < 0) {
      setRefetchInterval(1);
      return;
    }

    setRefetchInterval(sessionExpiresInSeconds);
  }, [
    session?.error,
    sessionExpiresInSeconds,
    sessionStatus,
    setRefetchInterval,
  ]);

  return null;
};

export default RefreshTokenHandler;
