import invariant from 'tiny-invariant';
import z from 'zod';

// const TOKEN_STORAGE_KEY = 'atc';

const zTokenObj = z.object({
  jwt: z.string().nullable(),
  access_token_expires_at: z.string().nullable(),
});

export type TokenStorageObject = z.infer<typeof zTokenObj>;

///

type HasTokenEventListener = (payload: TokenStorageObject) => void;

class HasTokenEvent {
  private listeners: HasTokenEventListener[] = [];
  constructor() {}
  subscribe(fn: HasTokenEventListener) {
    this.listeners.push(fn);
  }
  unsubscribe(fn: HasTokenEventListener) {
    this.listeners = this.listeners.filter(function (item) {
      if (item !== fn) {
        return item;
      }
    });
  }

  fire(o: TokenStorageObject) {
    this.listeners.forEach(function (item) {
      item(o);
    });
  }
}

const hasTokenEvent = new HasTokenEvent();

let jwt: string | null = null;

let access_token_expires_at: string | null = null;

// TODO: should remove all tokens when sign out

export function saveToken(accessToken: string, accessTokenExpiresAt: string) {
  if (typeof window === 'undefined') {
    invariant(false, 'client-side only.');
  }

  jwt = accessToken;
  access_token_expires_at = accessTokenExpiresAt;

  // NOTE: notify listeners
  hasTokenEvent.fire({
    jwt: accessToken,
    access_token_expires_at: accessTokenExpiresAt,
  });
}

export function getToken(): TokenStorageObject | null {
  if (typeof window === 'undefined') {
    invariant(false, 'client-side only.');
  }

  return {
    jwt,
    access_token_expires_at,
  };
}

export function waitForToken(): Promise<TokenStorageObject> {
  return new Promise((resolve) => {
    if (jwt && access_token_expires_at) {
      return resolve({ jwt, access_token_expires_at });
    }
    const hasTokenHandler: HasTokenEventListener = (o) => {
      resolve(o);
      hasTokenEvent.unsubscribe(hasTokenHandler);
    };
    hasTokenEvent.subscribe(hasTokenHandler);
  });
}
