const API_VERSION = '/v3';

export const teamsURL = {
  teamsId: (id: string) => `${API_VERSION}/teams/${id}`,
  permissions: (teamId: string) => teamsURL.teamsId(teamId) + '/permissions',
};

export const connectionURL = {
  getAll: (teamId: string) => `${teamsURL.teamsId(teamId)}/connections`,
  getById: (teamId: string, connectionId: string) =>
    `${connectionURL.getAll(teamId)}/${connectionId}`,
  syncById: (teamId: string, connectionId: string) =>
    `${connectionURL.getById(teamId, connectionId)}/sync`,
  downloadSyncHistories: (
    teamId: string,
    connectionId: string,
    historyId: string,
  ) =>
    `${connectionURL.getById(
      teamId,
      connectionId,
    )}/sync-histories/${historyId}/download`,
  statistics: (teamId: string) => `${connectionURL.getAll(teamId)}/statistics`,
  position: (teamId: string) => `${connectionURL.getAll(teamId)}/positions`,
  schedules: (teamId: string, connectionId: string) =>
    `${connectionURL.getById(teamId, connectionId)}/schedules`,
  schedulesId: (teamId: string, connectionId: string, scheduleId: string) =>
    `${connectionURL.schedules(teamId, connectionId)}/${scheduleId}`,
  schemas: (teamId: string, connectionId: string) =>
    `${connectionURL.getById(teamId, connectionId)}/schemas`,
  columns: (teamId: string, connectionId: string) =>
    `${connectionURL.getById(teamId, connectionId)}/columns`,
  syncHistories: (teamId: string, connectionId: string) =>
    `${connectionURL.getById(teamId, connectionId)}/sync-histories`,
  downloadByUrl: (teamId: string, connectionId: string, historyId: string) =>
    `${connectionURL.getById(
      teamId,
      connectionId,
    )}/sync-histories/${historyId}/download-url`,
};

export const dataSourcesURL = {
  getAll: () => `${API_VERSION}/data-sources`,
  getById: (dsId: string) => `${dataSourcesURL.getAll()}/${dsId}`,
};

export const dataModelsURL = {
  getAll: () => `${API_VERSION}/data-models`,
  getById: (datasetId: string) =>
    `${dataModelsURL.getAll()}/datasets/${datasetId}`,
  getByDataModelId: (dataModelId: string) =>
    `${dataModelsURL.getAll()}/${dataModelId}`,
  editDataModel: (teamId: string, datasetId: string, dataModelId: string) => {
    const queryStr = new URLSearchParams({
      team_id: teamId,
      dataset_id: datasetId,
    });

    return (
      dataModelsURL.getByDataModelId(dataModelId) + '?' + queryStr.toString()
    );
  },
  getDataModelsDetail: (
    teamId: string,
    datasetId: string,
    dataModelId: string,
  ) => {
    const queryStr = new URLSearchParams({
      team_id: teamId,
      dataset_id: datasetId,
    });
    return (
      dataModelsURL.getByDataModelId(dataModelId) + '?' + queryStr.toString()
    );
  },
  editDataModelQueryString: (
    teamId: string,
    datasetId: string,
    dataModelId: string,
  ) => {
    const queryStr = new URLSearchParams({
      team_id: teamId,
      dataset_id: datasetId,
    });

    return (
      dataModelsURL.getByDataModelId(dataModelId) +
      '/query' +
      '?' +
      queryStr.toString()
    );
  },
  getDownstreams: (dataModelId: string) =>
    `${dataModelsURL.getAll()}/${dataModelId}/downstreams`,
  duplicateDataModels: (
    teamId: string,
    datasetId: string,
    dataModelId: string,
  ) => {
    const queryStr = new URLSearchParams({
      team_id: teamId,
      dataset_id: datasetId,
    });
    return (
      dataModelsURL.getByDataModelId(dataModelId) +
      '/duplicate?' +
      queryStr.toString()
    );
  },
  getConfig: (teamId: string, datasetId: string, dataModelId: string) => {
    const queryStr = new URLSearchParams({
      team_id: teamId,
      dataset_id: datasetId,
    });
    return (
      dataModelsURL.getByDataModelId(dataModelId) +
      '/config' +
      '?' +
      queryStr.toString()
    );
  },
};

export const dataModelURL = {
  getAll: () => `${API_VERSION}/data-model`,
  getById: (dataModelId: string, teamId: string) =>
    `${dataModelsURL.getAll()}/${dataModelId}?team_id=${teamId}`,
  filterByDatasetId: (teamId: string) =>
    `${dataModelURL.getAll()}/filter?team_id=${teamId}`,
};

export const dataTransformationUrlMap = {
  _base(teamId: string) {
    return `${API_VERSION}/teams/${teamId}`;
  },
  templates: () => `${API_VERSION}/templates` as const,
  collections(teamId: string) {
    return `${this._base(teamId)}/collections`;
  },
  batchDeleteCollection(teamId: string) {
    return `${this._base(teamId)}/collections/bulk-delete`;
  },
  templateConnections(teamId: string, templateId: string) {
    return `${this._base(
      teamId,
    )}/transformations/templates/${templateId}/available-connections`;
  },
  templatePreviewQuery(teamId: string, templateId: string) {
    return `${this._base(
      teamId,
    )}/transformations/templates/${templateId}/preview-query`;
  },
  executeQuery(teamId: string) {
    return `${this._base(teamId)}/transformations/queries`;
  },
  createDataModelFromQuery(teamId: string) {
    return `${this._base(teamId)}/transformations/data-models`;
  },
  getDataModelQuery: (dataModelId: string) =>
    `${API_VERSION}/data-models/${dataModelId}/query` as const,
  getCollectionById: (teamId: string, collectionId: string) =>
    [API_VERSION, 'teams', teamId, 'collections', collectionId].join('/'),
  getDagNodes(teamId: string) {
    return `${this._base(teamId)}/transformations/dag`;
  },
  editDagNodesConfig(teamId: string) {
    return `${this.getDagNodes(teamId)}/item-configs`;
  },
};
