'use client';

import type { Dispatch, SetStateAction } from 'react';

import React, {
  createContext,
  useContext,
  useDeferredValue,
  useMemo,
  useState,
} from 'react';

import { useLocalStorage } from '@mantine/hooks';

interface EnvironmentContext {
  isOpenSidebar: boolean;
  setOpenSidebar: Dispatch<SetStateAction<boolean>>;
  openDashboardShareSetting: boolean;
  setOpenDashboardShareSetting: Dispatch<SetStateAction<boolean>>;
  isSidebarResizing: boolean;
  setSidebarResizing: Dispatch<SetStateAction<boolean>>;
  isPrintingMode: boolean;
  setPrintingMode: Dispatch<SetStateAction<boolean>>;
}

const NOOP = () => {};
const USER_SETTINGS_STORAGE_KEYS = {
  sidebarState: ['user-settings', 'sidebar-state'].join('.'),
} as const;

const EnvironmentContext = createContext<EnvironmentContext>({
  isOpenSidebar: false,
  setOpenSidebar: NOOP,
  openDashboardShareSetting: false,
  setOpenDashboardShareSetting: NOOP,
  isSidebarResizing: false,
  setSidebarResizing: NOOP,
  isPrintingMode: false,
  setPrintingMode: NOOP,
});

export function EnvironmentContextProvider({
  children,
}: React.PropsWithChildren) {
  const [openDashboardShareSetting, setOpenDashboardShareSetting] =
    useState(false);

  const [isOpenSidebar, setOpenSidebar] = useLocalStorage({
    key: USER_SETTINGS_STORAGE_KEYS.sidebarState,
    defaultValue: false,
  });

  const [isSidebarResizing, setSidebarResizing] = useState(false);

  const [isPrintingMode, setPrintingMode] = useState(false);

  const memoizedContextValues = useMemo(
    () => ({
      isOpenSidebar,
      setOpenSidebar,
      isSidebarResizing,
      setSidebarResizing,
      openDashboardShareSetting,
      setOpenDashboardShareSetting,
      isPrintingMode,
      setPrintingMode,
    }),
    [
      isOpenSidebar,
      setOpenSidebar,
      isSidebarResizing,
      openDashboardShareSetting,
      setOpenDashboardShareSetting,
      isPrintingMode,
      setPrintingMode,
    ],
  );

  const contextValues = useDeferredValue(memoizedContextValues);

  return (
    <EnvironmentContext.Provider
      value={contextValues}
      //
    >
      {children}
    </EnvironmentContext.Provider>
  );
}

export const useEnvironmentContext = () => useContext(EnvironmentContext);
