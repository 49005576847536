// NOTE: Primary
export const primaryColors = {
  100: '#6713EF',
  90: '#762bf1',
  80: '#8542f2',
  70: '#955af4',
  60: '#a471f5',
  50: '#b389f7',
  40: '#c2a1f9',
  30: '#d1b8fa',
  20: '#e1d0fc',
  10: '#f0e7fd',
};
// NOTE: Neutral
export const neutralColors = {
  0: '#202020',
  1: '#353C49',
  2: '#505866',
  3: '#B1B8C0',
  4: '#D9E0E8',
  5: '#F2F4F6',
  6: '#F8F8F9',
  7: '#FFFFFF',
};
// NOTE: Text
export const textColors = {
  0: '#353C49',
  1: '#676E7B',
  2: '#9198A0',
  3: '#B1B8C0',
  4: '#D1D6DA',
  5: '#FFFFFF',
  darkGreen: '#12C457',
  darkPink: '#E84C85',
  primary: '#6713EF',
};
// NOTE: Point
export const pointColors = {
  warmPink10: '#FFEDF4',
  warmPink100: '#FF508F',
  mediumTurquoise10: '#ECFAFC',
  mediumTurquoise100: '#43CDE0',
  aquaMarine: '#3FE4A8',
  blueLotus: '#7755FF',
};
// NOTE: Chart
export const chartColors = {
  blueLotus: '#7755FF',
  butterflyBlue: '#4BA4F5',
  blueDiamond: '#3CE3D9',
  lightMustard: '#FFCC6B',
  coralPink: '#FF8473',
  brinkPink: '#FA6681',
  deepFuchsia: '#CC45BF',
  purpleFlower: '#B145CC',
};
// NOTE: Semantic
export const semanticColors = {
  negative100: '#EB5757', //#96354C
  negative90: '#EF7777',
  negative80: '#F18686',
  negative50: '#FBD9D9',
  negative10: '#FDF0F0',
  negative5: '#FDF6F6',
  neutral: '#F2C94C', //#C77B1A
  neutral100: '#F2C94C', //#C77B1A
  neutral10: '#FEFAED',
  neutral50: '#F9E4A5',
  neutral80: '#D7B335',
  neutral5: '#FEFCF6',
  positive100: '#27AE60', //#175C6B
  positive: '#27AE60', //#175C6B
  positive5: '#F4FBF7',
  positive10: '#E9F7EF',
  positive50: '#93D7AF',
  information5: '#EFF6FF',
  information10: '#DBEAFE',
  information20: '#BFDBFE',
  information30: '#93C5FD',
  information50: '#3B82F6',
  information60: '#2563EB',
  information70: '#1D4ED8',
};
// NOTE: Background
export const backgroundColors = {
  0: '#F9F9FC',
  1: '#FFFFFF',
};
// NOTE: NeutralV2
export const neutralV2Colors = {
  0: '#202020',
  1: '#353C49',
  2: '#505866',
  3: '#B1B8C0',
  4: '#D9E0E8',
  5: '#E9EDF1',
  6: '#F2F4F6',
  7: '#F8F8F9',
  8: '#FFFFFF',
  25: '#7F8794',
};
