import { z } from 'zod';

import { zAggregateItem, zGrouping, zGroupItem, zSortOption } from './common';
import { zCompatibleCommonConfig } from './compatibility';

export const zPieChartConfig = z.object({
  view_type: z.literal('PIE_CHART'),
  type: z.enum(['PIE', 'DONUT']).default('PIE'),
  show_values: z.boolean().default(false).nullish(),
  sort_option: zSortOption.optional().nullable(),
});

export const zPieChartFullConfig =
  zCompatibleCommonConfig.merge(zPieChartConfig);

export type PieChartFullConfig = z.infer<typeof zPieChartFullConfig>;

export const zPieChartRenderingConfig = zPieChartFullConfig.merge(
  z.object({
    grouping: zGrouping.merge(
      z.object({
        columns: z
          .array(zGroupItem)
          .length(1, {
            message: 'pie-chart-config-grouping-columns-length-must-be-1',
          })
          .refine((cols) => cols[0] && cols[0].column_type !== 'DATETIME', {
            message: 'pie-chart-fisrt-grouping-column-is-datetime',
          }),
        sub_aggregates: z.array(zAggregateItem).length(1, {
          message: 'pie-chart-config-grouping-sub_aggregates-length-must-be-1',
        }),
      }),
    ),
  }),
);

export type PieChartConfig = z.infer<typeof zPieChartConfig>;
