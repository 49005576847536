'use client';

import { useEffect } from 'react';

export default function ZendeskInit({
  enable,
  zendeskKey,
}: {
  enable: boolean;
  zendeskKey: string;
}) {
  useEffect(() => {
    if (!enable) return;
    (function (w) {
      var a = document.getElementById('ze-snippet');
      if (a) a.remove();

      var s = document.createElement('script');
      s.id = `ze-snippet`;
      s.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
      s.async = true;
      var e = document.getElementsByTagName('script')[0];
      e.parentNode?.insertBefore(s, e);
    })(window);
  }, [enable, zendeskKey]);

  return null;
}
