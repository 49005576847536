import { SxProps, Theme } from '@mui/material';

export const exhaustiveTypeCheck = (arg: never) => {
  throw new Error(`Reaching an impossible type because of ${arg}`);
};

// Flattens an object and returns concatenations of all the keys of nested objects
export type FlattenObject<TObject, TPrefix extends string = ''> = {
  [TKey in keyof TObject]: TObject[TKey] extends (...args: any[]) => any
    ? `${TPrefix}${TKey & string}`
    : TObject[TKey] extends any[]
    ? `${TPrefix}${TKey & string}`
    : TObject[TKey] extends object
    ? FlattenObject<TObject[TKey], `${TPrefix}${TKey & string}.`>
    : `${TPrefix}${TKey & string}`;
}[keyof TObject];

export type SxPropsTheme = SxProps<Theme>;
