import { z } from 'zod';

import { getBindedTargetValueObject } from '@/utils/view-config';

import { makeSafeSchema } from '../../utils/data';
import {
  zAdvancedFilter,
  zAggregateItem,
  zCompareToThePastConfig,
  zDateFilterItem,
  zFilterItem,
  zPeriodicity,
} from './common';
import { zCompatibleCommonConfig } from './compatibility';

const zGoalTargetValues = z.object({
  DAILY: z.number().nullish(),
  WEEKLY: z.number().nullish(),
  MONTHLY: z.number().nullish(),
  QUARTERLY: z.number().nullish(),
  YEARLY: z.number().nullish(),
});

export type GoalTargetValues = z.infer<typeof zGoalTargetValues>;

const zGoalTarget = z.object({
  values: zGoalTargetValues.nullish(),
  is_linked: z.boolean().default(true),
});

export type GoalTarget = z.infer<typeof zGoalTarget>;

export const zGoalConfig = z.object({
  view_type: z.literal('GOAL'),
  title: z.string().nullish(),
  metric: zAggregateItem.nullish(),
  date_column_name: z.string().nullish(),
  target_value: z.number().nullish(), // DEPRECATED due to design change
  detail_target: z.record(z.string(), z.number()).nullish(),
  target_period: zPeriodicity, // DEPRECATED due to design change
  date_filter: zDateFilterItem.nullish(),
  goal_type: z.enum(['ONE_PERIOD', 'OVERVIEW']),
  visual_type: z.enum(['PROGRESS_BAR', 'LINE_CHART', 'BOARD']).nullish(),
  is_cumulative: z.boolean().nullish(),
  target: zGoalTarget.nullish(),
  goal_metric_id: z.string().nullish(),
});

export const zGoalMetric = z.object({
  id: z.string(),
  team_id: z.string(),
  data_model_id: z.string(),
  dataset_id: z.string(),
  name: z.string(),
  config: z.object({
    metric_column: zAggregateItem.nullish(),
    filters: makeSafeSchema(z.array(zFilterItem), []),
    advanced_filters: makeSafeSchema(z.array(zAdvancedFilter), []),
    target: zGoalTarget.nullish(),
    detail_target: z.record(z.string(), z.number()).nullish(),
    date_column_name: z.string().nullish(),
  }),
  creator: z
    .object({
      id: z.string(),
      username: z.string(),
      email: z.string(),
      company_name: z.string().nullish(),
      role: z.string().nullish(),
      language: z.string().nullish(),
      phone_number: z.string().nullish(),
      avatar_url: z.string().nullish(),
    })
    .nullish(),
  created_at: z.string().nullish(),
  updated_at: z.string().nullish(),
});

export type GoalMetric = z.infer<typeof zGoalMetric>;

export type CreateMetricPayload = Pick<GoalMetric, 'name' | 'config'>;

export type GoalConfig = z.infer<typeof zGoalConfig>;

export const zGoalFullConfig = zCompatibleCommonConfig.merge(zGoalConfig);

export type GoalFullConfig = z.infer<typeof zGoalFullConfig>;

export const zGoalRenderingConfig = zGoalFullConfig.refine((config) => {
  const firstGroupingColumn = config.grouping.columns[0];
  const isDatetimeGrouping =
    firstGroupingColumn && firstGroupingColumn.column_type !== 'DATETIME';
  const isTimeSeries = Boolean(config.time_series);
  return !isTimeSeries && !isDatetimeGrouping;
});
