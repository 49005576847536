import { z } from 'zod';

import { BOARD_DEFAULT_NAME } from '../../feature-report/charts/board/constant';
import { zAggregateItem, zGrouping, zGroupItem } from './common';
import { zCompatibleCommonConfig } from './compatibility';

export const zBoardConfig = z.object({
  view_type: z.literal('BOARD'),
  title: z.string(),
});

export const zBoardFullConfig = zCompatibleCommonConfig.merge(zBoardConfig);

export type BoardFullConfig = z.infer<typeof zBoardFullConfig>;

export const zBoardRenderingConfig = zBoardFullConfig.merge(
  z.object({
    grouping: zGrouping.merge(
      z.object({
        columns: z
          .array(zGroupItem)
          .length(1, {
            message: 'board-config-grouping-columns-length-must-be-1',
          })
          .refine((cols) => cols[0] && cols[0].column_type === 'DATETIME', {
            message: 'board-config-fisrt-grouping-column-must-be-datetime',
          }),
        sub_aggregates: z.array(zAggregateItem).length(1, {
          message: 'board-config-grouping-sub_aggregates-length-must-be-1',
        }),
      }),
    ),
  }),
);

export type BoardConfig = z.infer<typeof zBoardConfig>;

export const zBoardTransformedConfig = zBoardFullConfig.merge(
  z.object({
    grouping: z.object({
      columns: z
        .array(zGroupItem)
        .default([])
        .transform((val) => []),
      sub_aggregates: z
        .array(zAggregateItem)
        .default([])
        .transform((val) => val.slice(0, 1)),
    }),
    title: z.string().default(BOARD_DEFAULT_NAME),
  }),
);
