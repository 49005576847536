'use client';

import type { Session } from 'next-auth';

import React, { type PropsWithChildren, useState } from 'react';

import dynamic from 'next/dynamic';

import { SessionProvider } from 'next-auth/react';

import RefreshTokenHandler from './RefreshTokenHandler';

const JwtInMemory = dynamic(
  () => import('./JwtInMemory').then((mod) => mod.JwtInMemory),
  { ssr: false },
);

const NextAuthSessionProvider: React.FC<
  PropsWithChildren<{
    session?: Session;
  }>
> = ({ session, children }) => {
  const [refetchInterval, setRefetchInterval] = useState(0);
  return (
    <SessionProvider
      session={session}
      refetchOnWindowFocus
      refetchInterval={refetchInterval}
      //
    >
      <RefreshTokenHandler setRefetchInterval={setRefetchInterval} />
      <JwtInMemory />
      {children}
    </SessionProvider>
  );
};

export default NextAuthSessionProvider;
