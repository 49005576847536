import type { AxiosResponse } from 'axios';

import invariant from 'tiny-invariant';
import { z } from 'zod';

import { clientHttp as axiosHttpClient } from '@/adapters/api';
import {
  UserDtoSchema,
  zDashboardPermissions,
  zDashboardUserDto,
} from '@/models/user';
import { serializeArrayData, serializeData } from '@/utils/data';

import {
  DashboardConfigSchema,
  DashboardDto,
  DashboardDtoSchema,
  DashboardFilterSchema,
} from './model';
import { MutateDashboardPayload } from './use-dashboards';

export async function getDashboardDetailAPI_deprecated(
  teamId: string | null,
  dashboardId: string,
) {
  invariant(teamId, 'no team id provided');
  const { data: dashboard } = await axiosHttpClient.get<DashboardDto>(
    '/v3/teams/' + teamId + '/dashboards/' + dashboardId,
  );
  return serializeData(dashboard, DashboardDtoSchema);
}

export async function getDashboardsAPI_deprecated(teamId: string | null) {
  invariant(teamId, 'no team id provided');
  const { data: dashboards } = await axiosHttpClient.get<DashboardDto[]>(
    '/v3/teams/' + teamId + '/dashboards',
  );
  if (dashboards == null) return [];
  return serializeArrayData(dashboards, DashboardDtoSchema);
}

export async function createDashboardAPI_deprecated(
  payload: MutateDashboardPayload,
  teamId: string | null,
) {
  invariant(teamId, 'no team id provided');
  const { data: dashboard } = await axiosHttpClient.post<
    DashboardDto,
    AxiosResponse<DashboardDto>,
    MutateDashboardPayload
  >('/v3/teams/' + teamId + '/dashboards', payload);
  return serializeData(dashboard, DashboardDtoSchema);
}

export async function likeDashboardAPI_deprecated(
  teamId: string | null,
  dashboardId: string,
) {
  invariant(teamId, 'no team id provided');
  await axiosHttpClient.post<string>(
    '/v3/teams/' + teamId + '/dashboards/' + dashboardId + '/likes',
  );
  return null;
}

export async function unlikeDashboardAPI_deprecated(
  teamId: string | null,
  dashboardId: string,
) {
  invariant(teamId, 'no team id provided');
  await axiosHttpClient.delete(
    '/v3/teams/' + teamId + '/dashboards/' + dashboardId + '/likes',
  );
  return null;
}

export async function removeDashboardAPI_deprecated(
  teamId: string | null,
  dashboardId: string,
) {
  invariant(teamId, 'no team id provided');
  await axiosHttpClient.delete(
    '/v3/teams/' + teamId + '/dashboards/' + dashboardId,
  );
  return null;
}

export async function updateDashboardAPI_deprecated(
  payload: MutateDashboardPayload,
  teamId: string | null,
  dashboardId?: string,
) {
  invariant(teamId, 'no team id provided');
  invariant(dashboardId, 'no dashboard id provided');

  const { data: dashboard } = await axiosHttpClient.put<
    DashboardDto,
    AxiosResponse<DashboardDto>,
    MutateDashboardPayload
  >('/v3/teams/' + teamId + '/dashboards/' + dashboardId, payload);

  return serializeData(dashboard, DashboardDtoSchema);
}

export const ChangeDashboardOrdersPayloadSchema = z.object({
  id: z.string(),
  order: z.number().int().min(1).nullish(),
  liked_order: z.number().int().min(1).nullish(),
});

export type ChangeDashboardOrdersPayload = z.infer<
  typeof ChangeDashboardOrdersPayloadSchema
>;

export const ChangeDashboardOrdersResponseSchema = z.object({
  id: z.string(),
  order: z.number().int().nullable().optional(),
  liked_order: z.number().int().nullable().optional(),
});

export type ChangeDashboardOrdersResponse = z.infer<
  typeof ChangeDashboardOrdersResponseSchema
>;

export async function changeDashboardOrdersAPI_deprecated(
  teamId: string,
  payload: ChangeDashboardOrdersPayload[],
) {
  invariant(teamId, 'no team id provided');
  const res = await axiosHttpClient.put<
    ChangeDashboardOrdersResponse[],
    AxiosResponse<ChangeDashboardOrdersResponse[]>,
    ChangeDashboardOrdersPayload[]
  >('/v3/teams/' + teamId + '/dashboards/orders', payload);
  return serializeArrayData(res?.data, ChangeDashboardOrdersResponseSchema);
}

export const GetDashboardShareSettingsResponseSchema = z.object({
  is_public: z.boolean(),
  shared_members: z.array(zDashboardUserDto).default([]),
});

export type GetDashboardShareSettingsResponse = z.infer<
  typeof GetDashboardShareSettingsResponseSchema
>;

export async function getDashboardShareSettingsAPI_deprecated(
  teamId: string,
  dashboardId: string,
) {
  invariant(teamId, 'no team id provided');
  invariant(dashboardId, 'no dashboard id provided');

  const res = await axiosHttpClient.get<GetDashboardShareSettingsResponse>(
    '/v3/teams/' + teamId + '/dashboards/' + dashboardId + '/sharing',
  );

  return serializeData(res?.data, GetDashboardShareSettingsResponseSchema);
}

export const UpdateDashboardShareSettingPayloadSchema = z.object({
  is_public: z.boolean(),
  new_shared_ids: z.array(z.string()).default([]),
  revoked_ids: z.array(z.string()).default([]),
  member_id_to_permission: z.record(zDashboardPermissions),
});

type UpdateDashboardShareSetting = z.infer<
  typeof UpdateDashboardShareSettingPayloadSchema
>;
export type UpdateDashboardShareSettingPayload =
  Partial<UpdateDashboardShareSetting>;

export async function updateDashboardShareSettingsAPI_deprecated(
  teamId: string,
  dashboardId: string,
  payload: UpdateDashboardShareSettingPayload,
) {
  invariant(teamId, 'no team id provided');
  invariant(dashboardId, 'no dashboard id provided');

  await axiosHttpClient.put(
    '/v3/teams/' + teamId + '/dashboards/' + dashboardId + '/sharing',
    payload,
  );

  return null;
}

export async function duplicateDashboardAPI_deprecated(
  teamId: string,
  dashboardId: string,
) {
  invariant(teamId, 'no team id provided');
  invariant(dashboardId, 'no dashboard id provided');

  const res = await axiosHttpClient.post<DashboardDto>(
    '/v3/teams/' + teamId + '/dashboards/' + dashboardId + '/duplicate',
  );

  return serializeData(res?.data, DashboardDtoSchema);
}

export async function deleteDashboardCacheAPI_deprecated(dashboardId: string) {
  invariant(dashboardId, 'no dashboard id provided');
  const res = await axiosHttpClient.delete(
    '/v3/cache/dashboard/' + dashboardId,
  );
  return res.data;
}

export async function deleteWidgetReportCacheAPI_deprecated(widgetId: string) {
  invariant(widgetId, 'no widget id provided');
  const res = await axiosHttpClient.delete(
    '/v3/cache/widget-report/' + widgetId,
  );
  return res.data;
}

export const zDashboardHealthCheckInput = z.array(
  z.object({
    filter: DashboardFilterSchema,
    is_local: z.boolean(),
  }),
);

export type DashboardHealthCheckInput = z.infer<
  typeof zDashboardHealthCheckInput
>;

export const zDashboardHealthCheckResult = z.array(
  z.object({
    filter: DashboardFilterSchema,
    is_healthty: z.boolean().nullish(),
    suggested_filter: DashboardFilterSchema.nullish(),
    is_local: z.boolean(),
  }),
);

export type DashboardHealthCheckResult = z.infer<
  typeof zDashboardHealthCheckResult
>;
