import { z } from 'zod';

import { zDateSchema } from '@/models/common';
import { UserDtoSchema, zDashboardPermissions } from '@/models/user';

import { zFilterItem } from '../../models/view';

const zColumnFilters = z.object({
  data_model_id: z.string(),
  dataset_id: z.string(),
  column_filter: zFilterItem,
});

export const DashboardFilterSchema = z.object({
  name: z.string(),
  is_active: z.boolean().nullable().optional(),
  logical_operator: z.enum(['AND', 'OR']).nullable().optional(),
  column_filters: z.array(zColumnFilters),
});

export type ColumnFilters = z.infer<typeof zColumnFilters>;

export type DashboardFilterItem = z.infer<typeof DashboardFilterSchema>;

export const DashboardConfigSchema = z.object({
  filters: z.array(DashboardFilterSchema).nullable().optional(),
  is_filter_active: z.boolean().nullable().optional(),
});

export type DashboardConfig = z.infer<typeof DashboardConfigSchema>;

export const DashboardDtoSchema = z.object({
  id: z.string(),
  team_id: z.string(),
  name: z.string(),
  owner_id: z.string(),
  order: z.number().int().nullable().optional(),
  config: DashboardConfigSchema.nullable().optional(),
  liked_order: z.number().int().nullable().optional(),
  is_public: z.boolean(),
  has_liked: z.boolean().nullable().optional(),
  created_at: zDateSchema.optional(),
  updated_at: zDateSchema.optional(),
  permission: zDashboardPermissions.nullish(),
  description: z.string().nullable().optional(),
  creator: UserDtoSchema.nullish(),
  updated_by: UserDtoSchema.nullish(),
  thumbnail_url: z.string(),
});

export type DashboardDto = z.infer<typeof DashboardDtoSchema>;

export const TemplateDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  thumbnail_url: z.string().nullish(),
  created_at: zDateSchema.optional(),
  updated_at: zDateSchema.optional(),
});

export type TemplateDto = z.infer<typeof TemplateDtoSchema>;
