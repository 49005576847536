'use client';

import React, { createContext, useContext, useMemo } from 'react';

import { useParams } from 'next/navigation';
import { useRouter } from 'next/router';

import { useQueries } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import invariant from 'tiny-invariant';

import { DashboardApi } from '@/adapters/api';
import { SplashScreen } from '@/components/SplashScreen';
import { dashboardQK } from '@/query-key';
import {
  getConnectionRouteSelect,
  getDashboardRoute,
  getTeamRoute,
  getTransformationRoute,
} from '@/routes';

interface NavigationState {
  isLoading: boolean;
  teamId: string;
  dashboardId: string;
  widgetId: string;
  connectionId: string;
  collectionId: string;
  datasetId: string;
  dataModelId: string;
}

interface NavigationContext {
  navigationState: NavigationState;
}

const INITIAL_NAVIGATION_STATE: NavigationState = {
  isLoading: false,
  teamId: '',
  dashboardId: '',
  widgetId: '',
  connectionId: '',
  collectionId: '',
  datasetId: '',
  dataModelId: '',
};

const NavigationContext = createContext<NavigationContext>({
  navigationState: INITIAL_NAVIGATION_STATE,
});

export function useInitLinks() {
  const router = useRouter();

  const { status: sessionStatus } = useSession();

  const enabled = sessionStatus === 'authenticated' && router.isReady;

  const { team_id } = router.query;
  const teamId = team_id?.toString() || '';

  const results = useQueries({
    queries: [
      {
        queryKey: dashboardQK.all(teamId),
        queryFn: () => DashboardApi.onBrowser().getAll(teamId),
        staleTime: Infinity,
        enabled: Boolean(teamId && enabled),
      },
      // {
      //   queryKey: connectionQK.all(teamId),
      //   queryFn: () => ConnectionApiAdapter.getConnectionList(teamId),
      //   staleTime: Infinity,
      //   enabled: Boolean(teamId && enabled),
      // },

      // NOTE: disable this query since it will charge user whenever they refresh the page.
      // {
      //   queryKey: transformationQK.collections(teamId),
      //   queryFn: () =>
      //     DataTransformationClientApiAdapter.getCollections({
      //       teamId,
      //     }),
      //   staleTime: Infinity,
      //   enabled: Boolean(teamId && enabled),
      // },
    ],
  });

  const isLoading = results.some((result) => result.isInitialLoading);
  const isSuccess = results.every((result) => result.isSuccess);

  const [dashboardsQuery] = results;
  const dashboards = dashboardsQuery.data || [];
  // const connections = connectionsQuery.data || [];
  // const collections = collectionQuery.data || [];

  const defaultDashboardId = dashboards.find((d) => d.has_liked === true)?.id;

  // const defaultConnectionId = connections[0]?.id;
  // const defaultCollectionId = collections.find(
  //   (collection) =>
  //     collection.category === 'COLLECTION' && collection.type === 'DEFAULT',
  // )?.id;

  const defaultTeamLink = getTeamRoute(teamId);
  return {
    data: {
      defaultTeamLink,
      defaultDashboardLink: defaultDashboardId
        ? getDashboardRoute(teamId, defaultDashboardId)
        : defaultTeamLink,
      defaultConnectionLink: getConnectionRouteSelect(teamId),
      // defaultCollectionLink: defaultCollectionId
      //   ? getTransformationCollectionRoute(teamId, defaultCollectionId)
      //   : getTransformationRoute(teamId),
      defaultCollectionLink: getTransformationRoute(teamId),
    },
    isInitialLoading: isLoading,
    isSuccess,
  };
}

type Props = React.PropsWithChildren;

/**
 * Try to parse queries from routers, make sure they are valid and ready to be consumed by children
 */
export const NavigationContextProvider: React.FC<Props> = ({ children }) => {
  const router = useRouter();
  const { ready } = useTranslation('common');
  const {
    team_id = '',
    dashboard_id = '',
    widget_id = '',
    connection_id = '',
    collection_id = '',
    dataset_id = '',
    data_model_id = '',
    // ...invalidParams
  } = router.query;

  const contextValues = useMemo(
    () => ({
      navigationState: {
        isLoading: false,
        teamId: team_id as string,
        dashboardId: dashboard_id as string,
        widgetId: widget_id as string,
        connectionId: connection_id as string,
        collectionId: collection_id as string,
        datasetId: dataset_id as string,
        dataModelId: data_model_id as string,
      },
    }),
    [
      collection_id,
      connection_id,
      dashboard_id,
      data_model_id,
      dataset_id,
      team_id,
      widget_id,
    ],
  );

  return (
    <NavigationContext.Provider value={contextValues}>
      {router.isReady && ready ? children : <SplashScreen />}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);
  invariant(context, 'useNavigationContext should be used within Provider');

  return context;
};

export const NavigationContextProviderApp: React.FC<Props> = ({ children }) => {
  let team_id = '',
    dashboard_id = '',
    widget_id = '',
    connection_id = '',
    collection_id = '',
    dataset_id = '',
    data_model_id = '';

  const params = useParams();

  if (params !== null && params['ids']?.length) {
    [team_id, dashboard_id, widget_id] = params['ids'] as string[];
    // team_id = Array.isArray(params['team_id'])
    //   ? params['team_id'][params['team_id'].length - 1]
    //   : params['team_id'];
    // dashboard_id = Array.isArray(params['dashboard_id'])
    //   ? params['dashboard_id'][params['dashboard_id'].length - 1]
    //   : params['dashboard_id'];
    // widget_id = Array.isArray(params['widget_id'])
    //   ? params['widget_id'][params['widget_id'].length - 1]
    //   : params['widget_id'];
    // data_model_id = Array.isArray(params['data_model_id'])
    //   ? params['data_model_id'][params['data_model_id'].length - 1]
    //   : params['data_model_id'];
    // connection_id = Array.isArray(params['connection_id'])
    //   ? params['connection_id'][params['connection_id'].length - 1]
    //   : params['connection_id'];
    // collection_id = Array.isArray(params['collection_id'])
    //   ? params['collection_id'][params['collection_id'].length - 1]
    //   : params['collection_id'];
    // dataset_id = Array.isArray(params['dataset_id'])
    //   ? params['dataset_id'][params['dataset_id'].length - 1]
    //   : params['dataset_id'];
  }

  const contextValues = useMemo(
    () => ({
      navigationState: {
        isLoading: false,
        teamId: team_id as string,
        dashboardId: dashboard_id as string,
        widgetId: widget_id as string,
        connectionId: connection_id as string,
        collectionId: collection_id as string,
        datasetId: dataset_id as string,
        dataModelId: data_model_id as string,
      },
    }),
    [
      collection_id,
      connection_id,
      dashboard_id,
      data_model_id,
      dataset_id,
      team_id,
      widget_id,
    ],
  );

  return (
    <NavigationContext.Provider value={contextValues}>
      {children}
    </NavigationContext.Provider>
  );
};
