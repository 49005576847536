import { useEffect } from 'react';

import lodashCloneDeep from 'lodash/cloneDeep';

export default function useStructuredClonePolyfill() {
  useEffect(() => {
    if (!window.structuredClone) {
      window.structuredClone = lodashCloneDeep;
    }
  }, []);
}
