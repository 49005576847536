'use client';

import Box from '@mui/material/Box';

import animationData from '@static/assets/lottie/splash-screen.json';

import { AppLottie } from './Lottie';

type Props = {
  onComplete?: () => void;
};

export const SplashScreen = ({ onComplete }: Props) => {
  return (
    <Box
      className="cg-splash-container"
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      //
    >
      <AppLottie
        speed={1.5}
        height={360}
        eventListeners={[
          {
            eventName: 'complete',
            callback: () => {
              onComplete?.();
            },
          },
        ]}
        options={{
          animationData,
          autoplay: true,
        }}
      />
    </Box>
  );
};
