/**
 * W3School
 *
 * Reference link: https://www.w3schools.com/js/js_cookies.asp
 */

/**
 *
 * @param cname: Cookie key to read
 * @returns
 */
export function getCookie(cname: string) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
}

/**
 *
 * @param cname Cookie name
 * @param cvalue Cookie value
 * @param exdays Expiration
 * @returns
 */
export function setCookie(cname: string, cvalue: string, exdays?: number) {
  const d = new Date();
  let parsedExDays = 30;

  if (exdays) {
    parsedExDays = exdays;
    return;
  }

  d.setTime(d.getTime() + parsedExDays * 24 * 60 * 60 * 1000);

  const expires = 'expires=' + d.toUTCString();

  /**
   * NOTE: Safari won't accept if no the cookie has no expiration, no same-site attributes
   */
  document.cookie =
    cname + '=' + cvalue + ';' + expires + ';SameSite=Strict' + ';path=/';

  return;
}
