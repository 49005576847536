import type ErrorJSON from '@static/locales/en/common.json';

import { z } from 'zod';

import { APP_PALLETES_DICTIONARY } from './theme/palettes';

export type MaybeErrorLocale = keyof (typeof ErrorJSON)['error'];

export const zLocaleSchema = z.enum(['en', 'ko']).default('en');

export type AppLocales = z.infer<typeof zLocaleSchema>;

export type PalletId = keyof typeof APP_PALLETES_DICTIONARY;
