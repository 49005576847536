import type { CreateAxiosDefaults } from 'axios';

import axios from 'axios';
import JSONBigInt from 'json-bigint';
import { getSession } from 'next-auth/react';

import {
  registerApiErrorParserResponseInterceptor,
  registerRefreshTokenOn401ResponseInterceptor,
  registerTokenHeaderRequestInterceptor,
} from '@/adapters/api/axios/utils';
import { TokenStorageObject, waitForToken } from '@/utils/client-side-token';

// NOTE: set 60 secs due to BIG QUERY sometime runs longer than 30s
const TIMEOUT_MS = 1000 * 60;

function isBigNumber(input: any): boolean {
  // Convert input to a number if possible
  const number = Number(input);
  if (isNaN(number)) {
    return false;
  }
  const absNum = Math.abs(number);

  if (absNum === Infinity) return false;

  return absNum > Number.MAX_SAFE_INTEGER;
}

function recursivelyCheckIfContainsBigNumber(data: any): boolean {
  if (typeof data !== 'object' || data === null) {
    return false;
  }

  for (const key in data) {
    if (typeof data[key] === 'object') {
      if (recursivelyCheckIfContainsBigNumber(data[key])) {
        return true;
      }
    } else if (isBigNumber(data[key])) {
      return true;
    }
  }

  return false;
}

const AXIOS_CONFIG: CreateAxiosDefaults = {
  baseURL: process.env.API,
  timeout: TIMEOUT_MS,
  withCredentials: false,
  transformResponse: [
    (data) => {
      // console.time('JSON.parse(data)');
      const normalParsedData = JSON.parse(data);
      // console.timeEnd('JSON.parse(data)');

      const hasAnyBigNumberValue =
        recursivelyCheckIfContainsBigNumber(normalParsedData);

      // NOTE: using default axios res transformer
      if (!hasAnyBigNumberValue) {
        return normalParsedData;
      }

      // NOTE: parsing big number
      // console.time('JSONBigInt.parse(data)');
      const bigNumData = JSONBigInt.parse(data);
      // const bigNumData = superjson.parse(data);
      // console.timeEnd('JSONBigInt.parse(data)');

      return bigNumData;
    },
  ],
};

const axiosInstance = axios.create(AXIOS_CONFIG);

const tokenGetter = async (): Promise<TokenStorageObject> => {
  return await waitForToken();
};

const refreshAuthFn = async (): Promise<TokenStorageObject> => {
  const maybeNewSession = await getSession();
  return {
    jwt: maybeNewSession?.access_token ?? null,
    access_token_expires_at: maybeNewSession?.access_token_expires_at ?? null,
  };
};

// NOTE!: the order of interceptor IS IMPORTANT!
// 1
registerTokenHeaderRequestInterceptor(axiosInstance, tokenGetter);
// 2
registerRefreshTokenOn401ResponseInterceptor(axiosInstance, refreshAuthFn);
// 3
registerApiErrorParserResponseInterceptor(axiosInstance);

export default axiosInstance;
