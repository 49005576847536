'use client';

import { useEffect } from 'react';

import { useRouter } from 'next/router';

import appConfig from '@/constants/configs';
import { useGlobalMe } from '@/hooks/use-global-me';
import { setCookie } from '@/utils/client-cookie';

export function LocaleHandler__APP() {
  const { me } = useGlobalMe();

  // NOTE: sync locale with user preferred language
  useEffect(() => {
    const meLanguage = me?.language;

    if (meLanguage) {
      setCookie(appConfig.USER_PREFERRED_LANGUAGE_COOKIE_NAME, meLanguage);
    }
  }, [me?.language]);

  return null;
}

export default function LocaleHandler() {
  const router = useRouter();
  const { me } = useGlobalMe();

  // NOTE: sync locale with user preferred language
  useEffect(() => {
    const meLanguage = me?.language;

    if (meLanguage) {
      setCookie(appConfig.USER_PREFERRED_LANGUAGE_COOKIE_NAME, meLanguage);
    }

    if (!meLanguage || !router.isReady) return;

    if (!router.locales?.includes(meLanguage)) return;

    if (router.locale === meLanguage) return;

    router.push(router.asPath, router.asPath, {
      locale: meLanguage,
    });
  }, [router, me?.language]);

  return null;
}
