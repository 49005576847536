import type { AppButtonColors, AppButtonVariants, StyleSlots } from './types';
import type { CSSObject, SxProps, Theme } from '@mui/material/styles';

import deepmerge from 'deepmerge';

import {
  BUTTON_CLASSNAME_ROOT_ACTIVE,
  BUTTON_CLASSNAME_ROOT_DISABLED,
  BUTTON_CLASSNAME_ROOT_FOCUS,
  BUTTON_CLASSNAME_ROOT_HOVER,
} from './constants';

export function getVariantContainedStyles(
  theme: Theme,
  { root, hover, focus, active, disabled }: StyleSlots,
): CSSObject {
  return {
    color: theme.palette.neutral[7],
    ...theme.typography.h4,
    paddingBlock: theme.spacing(1.5),
    paddingInline: theme.spacing(3),
    ...root,
    // HOVER
    [BUTTON_CLASSNAME_ROOT_HOVER]: {
      ...hover,
    },
    // FOCUS
    [BUTTON_CLASSNAME_ROOT_FOCUS]: {
      outline: 'none',
      boxShadow: `0 0 0 2px ${theme.palette.primary[30]}`,
      ...focus,
    },
    // ACTIVE
    [BUTTON_CLASSNAME_ROOT_ACTIVE]: {
      outline: 'none',
      ...active,
    },
    // DISABLED
    [BUTTON_CLASSNAME_ROOT_DISABLED]: {
      outline: 'none',
      userSelect: 'none',
      cursor: 'not-allowed',
      ...disabled,
    },
  };
}

export function getContainedPrimaryStyles(theme: Theme): CSSObject {
  return getVariantContainedStyles(theme, {
    root: {
      backgroundColor: theme.palette.primary.main,
    },
    hover: {
      backgroundColor: theme.palette.primary[90],
    },
    active: {
      backgroundColor: theme.palette.primary[80],
    },
    focus: {
      backgroundColor: theme.palette.primary.main,
    },
    disabled: {
      backgroundColor: theme.palette.primary[10],
    },
  });
}

export function getContainedDangerStyles(theme: Theme): CSSObject {
  return getVariantContainedStyles(theme, {
    root: {
      backgroundColor: theme.palette.semantic.negative100,
    },
    hover: {
      backgroundColor: theme.palette.semantic.negative90,
    },
    active: {
      backgroundColor: theme.palette.semantic.negative80,
    },
    focus: {
      backgroundColor: theme.palette.semantic.negative100,
    },
    disabled: {
      backgroundColor: theme.palette.semantic.negative10,
    },
  });
}

export function getVariantTextStyles(
  { root, hover, focus, active, disabled }: StyleSlots,
  theme: Theme,
): CSSObject {
  return {
    height: 'fit-content',
    paddingBlock: '12px',
    paddingInline: '8px',
    ...root,
    // HOVER
    [BUTTON_CLASSNAME_ROOT_HOVER]: {
      ...hover,
    },
    // FOCUS
    [BUTTON_CLASSNAME_ROOT_FOCUS]: {
      outline: 'none',
      boxShadow: `0 0 0 2px ${theme.palette.primary[30]}`,
      ...focus,
    },
    // ACTIVE
    [BUTTON_CLASSNAME_ROOT_ACTIVE]: {
      outline: 'none',
      ...active,
    },
    // DISABLED
    [BUTTON_CLASSNAME_ROOT_DISABLED]: {
      outline: 'none',
      userSelect: 'none',
      cursor: 'not-allowed',
      color: theme.palette.text[2],
      ...disabled,
    },
  };
}

export function getTextRegularStyles(theme: Theme) {
  return getVariantTextStyles(
    {
      root: {
        ...theme.typography.body2,
        textAlign: 'left',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.neutral[7],
        color: theme.palette.text[0],
      },
      hover: {
        backgroundColor: theme.palette.neutral[6],
      },
      focus: {
        backgroundColor: theme.palette.neutral[6],
      },
      active: {
        backgroundColor: theme.palette.neutral[5],
      },
      disabled: {
        backgroundColor: theme.palette.neutral[7],
        color: theme.palette.text[4],
      },
    },
    theme,
  );
}

export function getTextSemiBoldStyles(theme: Theme): CSSObject {
  return getVariantTextStyles(
    {
      root: {
        ...theme.typography.button,
        textTransform: 'none',
        textAlign: 'left',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.neutral[7],
        color: theme.palette.text[1],
      },
      hover: {
        backgroundColor: theme.palette.neutral[6],
      },
      focus: {
        backgroundColor: theme.palette.neutral[7],
      },
      active: {
        backgroundColor: theme.palette.neutral[5],
      },
      disabled: {
        backgroundColor: theme.palette.neutral[7],
        color: theme.palette.neutral[4],
      },
    },
    theme,
  );
}

export function getTextOutlinedStyles(theme: Theme): CSSObject {
  return getVariantTextStyles(
    {
      root: {
        ...theme.typography.body2,
        textAlign: 'left',
        justifyContent: 'flex-start',
        paddingBlock: '7px',
        paddingInline: '8px',
        color: theme.palette.text[0],
        backgroundColor: theme.palette.neutral[7],
        border: `1px solid ${theme.palette.neutral[4]}`,
      },
      hover: {
        backgroundColor: theme.palette.neutral[6],
      },
      focus: {
        backgroundColor: theme.palette.neutral[7],
      },
      active: {
        backgroundColor: theme.palette.neutral[5],
        outline: `2px auto ${theme.palette.primary[100]}`,
      },
      disabled: {
        backgroundColor: theme.palette.neutral[7],
        color: theme.palette.neutral[4],
      },
    },
    theme,
  );
}

export function getTextOutlinedSemiBoldStyles(theme: Theme): CSSObject {
  return getVariantTextStyles(
    {
      root: {
        ...theme.typography.h4,
        paddingBlock: '12px',
        paddingInline: '16px',
        borderRadius: '8px',
        width: 'fit-content',
        color: theme.palette.text[0],
        backgroundColor: theme.palette.neutral[7],
        border: `1px solid ${theme.palette.neutral[5]}`,
      },
      hover: {
        backgroundColor: theme.palette.neutral[6],
      },
      focus: {
        backgroundColor: theme.palette.neutral[7],
      },
      active: {
        backgroundColor: theme.palette.neutral[5],
        outline: `2px auto ${theme.palette.primary[100]}`,
      },
      disabled: {
        backgroundColor: theme.palette.neutral[7],
        color: theme.palette.neutral[4],
      },
    },
    theme,
  );
}

export function getIconOnlyStyles(theme: Theme): CSSObject {
  return {
    padding: '4px',
    color: theme.palette.neutral[1],
    backgroundColor: theme.palette.neutral[7],
    // HOVER
    [BUTTON_CLASSNAME_ROOT_HOVER]: {
      backgroundColor: theme.palette.neutral[6],
    },
    // FOCUS
    [BUTTON_CLASSNAME_ROOT_FOCUS]: {
      outline: 'none',
      boxShadow: `0 0 0 2px ${theme.palette.primary[30]}`,
      backgroundColor: theme.palette.neutral[7],
    },
    // ACTIVE
    [BUTTON_CLASSNAME_ROOT_ACTIVE]: {
      outline: 'none',
      backgroundColor: theme.palette.neutral[5],
    },
    // DISABLED
    [BUTTON_CLASSNAME_ROOT_DISABLED]: {
      outline: 'none',
      userSelect: 'none',
      cursor: 'not-allowed',
      backgroundColor: 'transparent',
      color: theme.palette.neutral[4],
    },
  };
}

export function getIconOnlyFilledStyles(theme: Theme): CSSObject {
  return {
    padding: theme.spacing(0.625),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.neutral[4],
    backgroundColor: 'transparent',
    // HOVER
    [BUTTON_CLASSNAME_ROOT_HOVER]: {
      backgroundColor: theme.palette.neutral[5],
      bgcolor: 'neutral.5',
    },
    // FOCUS
    [BUTTON_CLASSNAME_ROOT_FOCUS]: {
      outline: 'none',
      boxShadow: `0 0 0 2px ${theme.palette.primary[30]}`,
      backgroundColor: theme.palette.neutral[7],
    },
    // ACTIVE
    [BUTTON_CLASSNAME_ROOT_ACTIVE]: {
      outline: 'none',
      color: theme.palette.neutral[7],
      backgroundColor: theme.palette.neutral[4],
    },
    // DISABLED
    [BUTTON_CLASSNAME_ROOT_DISABLED]: {
      outline: 'none',
      userSelect: 'none',
      cursor: 'not-allowed',
      opacity: 0.5,
      backgroundColor: 'transparent',
      color: theme.palette.neutral[5],
    },
  };
}

export function getTextIconStyles(theme: Theme): CSSObject {
  return getVariantTextStyles(
    {
      root: {
        ...theme.typography.button,
        textTransform: 'none',
        paddingBlock: '8px',
        paddingInline: '12px',
        color: theme.palette.text[3],
        backgroundColor: theme.palette.neutral[7],
      },
      hover: {
        color: theme.palette.text[3],
        backgroundColor: theme.palette.neutral[6],
      },
      focus: {
        color: theme.palette.text[3],
        backgroundColor: theme.palette.neutral[6],
      },
      active: {
        color: theme.palette.text[3],
        backgroundColor: theme.palette.neutral[5],
      },
      disabled: {
        backgroundColor: theme.palette.neutral[7],
        color: theme.palette.neutral[4],
      },
    },
    theme,
  );
}

export function getVariantSecondaryStyles(
  theme: Theme,
  { root, hover, focus, active, disabled }: StyleSlots,
): CSSObject {
  return {
    ...theme.typography.h4,
    padding: '12px 16px',
    columnGap: '8px',
    backgroundColor: theme.palette.neutral[7],
    ...root,
    // HOVER
    [BUTTON_CLASSNAME_ROOT_HOVER]: {
      ...hover,
    },
    // FOCUS
    [BUTTON_CLASSNAME_ROOT_FOCUS]: {
      outline: 'none',
      boxShadow: `0 0 0 2px ${theme.palette.primary[30]}`,
      ...focus,
    },
    // ACTIVE
    [BUTTON_CLASSNAME_ROOT_ACTIVE]: {
      outline: 'none',
      ...active,
    },
    // DISABLED
    [BUTTON_CLASSNAME_ROOT_DISABLED]: {
      outline: 'none',
      userSelect: 'none',
      cursor: 'not-allowed',
      ...disabled,
    },
  };
}

export function getSecondaryPrimaryStyles(theme: Theme): CSSObject {
  return getVariantSecondaryStyles(theme, {
    root: {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary[100]}`,
    },
    hover: {
      backgroundColor: theme.palette.primary[10],
    },
    active: {
      backgroundColor: theme.palette.primary[20],
    },
    focus: {
      backgroundColor: theme.palette.neutral[7],
    },
    disabled: {
      backgroundColor: theme.palette.neutral[7],
      borderColor: theme.palette.primary[10],
      color: theme.palette.primary[10],
    },
  });
}

export function getSecondaryGhostPrimaryStyles(theme: Theme): CSSObject {
  return getVariantSecondaryStyles(theme, {
    root: {
      ...theme.typography.button,
      padding: '8px 12px',
      textTransform: 'none',
      gap: '8px',
      color: theme.palette.primary.main,
      border: 'none',
    },
    hover: {
      backgroundColor: theme.palette.neutral[7],
      boxShadow: `0 0 0 1px ${theme.palette.primary[100]}`,
    },
    active: {
      backgroundColor: theme.palette.neutral[5],
      boxShadow: `0 0 0 1px ${theme.palette.primary[100]}`,
    },
    focus: {
      backgroundColor: theme.palette.neutral[7],
      boxShadow: `0 0 0 1px ${theme.palette.primary[30]}`,
    },
    disabled: {
      backgroundColor: theme.palette.neutral[7],
      color: theme.palette.neutral[4],
      boxShadow: 'none',
    },
  });
}

export function getCheckboxActiveStyle(theme: Theme): CSSObject {
  return getVariantTextStyles(
    {
      root: {
        ...theme.typography.body2,
        textAlign: 'left',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.neutralV2[6],
        color: theme.palette.text[0],
        width: '100%',
      },
      hover: {
        backgroundColor: theme.palette.neutralV2[5],
      },
      focus: {
        backgroundColor: theme.palette.neutralV2[6],
      },
      active: {
        backgroundColor: theme.palette.neutralV2[4],
      },
      disabled: {
        backgroundColor: theme.palette.neutralV2[6],
        color: theme.palette.text[4],
      },
    },
    theme,
  );
}

export function getCheckboxStyle(theme: Theme): CSSObject {
  return getVariantTextStyles(
    {
      root: {
        ...theme.typography.body2,
        textAlign: 'left',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.neutralV2[8],
        color: theme.palette.text[0],
        width: '100%',
      },
      hover: {
        backgroundColor: theme.palette.neutralV2[7],
      },
      focus: {
        backgroundColor: theme.palette.neutralV2[8],
        outline: 'none',
        boxShadow: `0 0 0 2px ${theme.palette.primary[30]}`,
      },
      active: {
        backgroundColor: theme.palette.neutralV2[6],
      },
      disabled: {
        backgroundColor: theme.palette.neutralV2[8],
        color: theme.palette.text[4],
      },
    },
    theme,
  );
}

export function getIconOnlyV2Style(theme: Theme): CSSObject {
  return {
    padding: '4px',
    color: theme.palette.neutral[1],
    backgroundColor: theme.palette.neutralV2[7],
    [BUTTON_CLASSNAME_ROOT_HOVER]: {
      backgroundColor: theme.palette.neutralV2[6],
    },
    [BUTTON_CLASSNAME_ROOT_FOCUS]: {
      outline: 'none',
      boxShadow: `0 0 0 2px ${theme.palette.primary[30]}`,
      backgroundColor: theme.palette.neutralV2[7],
    },
    [BUTTON_CLASSNAME_ROOT_ACTIVE]: {
      outline: 'none',
      backgroundColor: theme.palette.neutralV2[5],
    },
    [BUTTON_CLASSNAME_ROOT_DISABLED]: {
      outline: 'none',
      userSelect: 'none',
      cursor: 'not-allowed',
      backgroundColor: 'transparent',
      color: theme.palette.neutralV2[4],
    },
  };
}

export function getLinkStyles(theme: Theme): CSSObject {
  return {
    paddingBlock: '4px',
    paddingInline: '8px',
    ...theme.typography.caption,
    textDecoration: 'none',
    color: theme.palette.text[0],
    backgroundColor: theme.palette.neutralV2[8],
    border: '1px solid ' + theme.palette.neutralV2[5],
    borderRadius: '4px',
    // HOVER
    [BUTTON_CLASSNAME_ROOT_HOVER]: {
      backgroundColor: theme.palette.neutralV2[6],
    },
    // FOCUS
    [BUTTON_CLASSNAME_ROOT_FOCUS]: {
      outline: 'none',
      boxShadow: `0 0 0 2px ${theme.palette.primary[30]}`,
    },
    // ACTIVE
    [BUTTON_CLASSNAME_ROOT_ACTIVE]: {
      outline: 'none',
      boxShadow: 'none',
      backgroundColor: theme.palette.neutralV2[5],
    },
    // DISABLED
    [BUTTON_CLASSNAME_ROOT_DISABLED]: {
      outline: 'none',
      userSelect: 'none',
      cursor: 'not-allowed',
      color: theme.palette.text[4],
      opacity: 0.5,
    },
  };
}

export const getButtonStyles = ({
  theme,
  sx,
  variant,
  color,
  fullWidth,
  semiBold,
}: {
  sx?: SxProps<Theme>;
  theme: Theme;
  variant?: AppButtonVariants;
  color?: AppButtonColors;
  fullWidth?: boolean;
  semiBold?: boolean;
}) => {
  const baseButtonStyles: CSSObject = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    borderRadius: '8px',
    width: 'fit-content',
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '300ms',
  };

  const cssObjects: CSSObject[] = [baseButtonStyles];

  let variantStyles: CSSObject = {};

  // NOTE: Variants
  if (variant === 'contained' && color === 'primary') {
    variantStyles = getContainedPrimaryStyles(theme);
  } else if (variant === 'contained' && color === 'danger') {
    variantStyles = getContainedDangerStyles(theme);
  } else if (variant === 'text-outlined' && semiBold) {
    variantStyles = getTextOutlinedSemiBoldStyles(theme);
  } else if (variant === 'text-outlined') {
    variantStyles = getTextOutlinedStyles(theme);
  } else if (variant === 'text-icon') {
    variantStyles = getTextIconStyles(theme);
  } else if (variant === 'text' && semiBold) {
    variantStyles = getTextSemiBoldStyles(theme);
  } else if (variant === 'text') {
    variantStyles = getTextRegularStyles(theme);
  } else if (variant === 'icon-only-filled') {
    variantStyles = getIconOnlyFilledStyles(theme);
  } else if (variant === 'icon-only') {
    variantStyles = getIconOnlyStyles(theme);
  } else if (variant === 'secondary-ghost' && color === 'primary') {
    variantStyles = getSecondaryGhostPrimaryStyles(theme);
  } else if (variant === 'secondary' && color === 'primary') {
    variantStyles = getSecondaryPrimaryStyles(theme);
  } else if (variant === 'checkbox' && semiBold) {
    variantStyles = getCheckboxActiveStyle(theme);
  } else if (variant === 'checkbox') {
    variantStyles = getCheckboxStyle(theme);
  } else if (variant === 'icon-only-v2') {
    variantStyles = getIconOnlyV2Style(theme);
  } else if (variant === 'link') {
    variantStyles = getLinkStyles(theme);
  }
  cssObjects.push(variantStyles);

  // NOTE: Misc
  if (fullWidth) {
    cssObjects.push({ width: '100%' });
  }

  // if (sx) {
  //   cssObjects.push(theme.unstable_sx(sx));
  // }

  return deepmerge.all<CSSObject>(cssObjects);
};
