import {
  ConnectionColumnDto,
  ConnectionDto,
  ConnectionDtoSchema,
  ConnectionScheduleDto,
  ConnectionSchemaDto,
  CreateConnectionPayload,
  CreateSchedulePayload,
  UploadedFileDto,
  UploadedFileDtoSchema,
} from '@cigro/data-connection/model';
import {
  ConnectionDS,
  ConnectionMutationResult,
  ConnectionPayload,
  ConnectionsPosition,
  ConnectionStatistics,
  ConnectionStatusPayload,
  ConnectionSyncLog,
  CsvColumn,
  ListConnection,
  QueryParams,
} from '@cigro/data-connection/types';
import invariant from 'tiny-invariant';

import {
  BaseApiAdapterClass,
  type RequestManager,
} from '@/adapters/api/__base';
import axiosHttpClient from '@/adapters/api/axios/axios-http-client';
import { NO_PERMISSION_ACCESS_TEAM_API_ERROR } from '@/initializers/AppErrorBoundary';
import { serializeArrayData } from '@/utils/data';
import { isUuid } from '@/utils/string';

import { clientHttp, serverHttp } from './axios';
import { connectionURL, dataSourcesURL } from './url-string';

class ConnectionApiAdapterClass extends BaseApiAdapterClass {
  constructor(rm: RequestManager) {
    super(rm);
  }

  private invariantUUID(teamId: string) {
    invariant(teamId, 'no team id provided');

    if (!isUuid(teamId)) {
      throw NO_PERMISSION_ACCESS_TEAM_API_ERROR;
    }
  }

  async getAll(teamId: string): Promise<ConnectionDto[]> {
    invariant(teamId, 'no team id provided');

    // NOTE: check valid teamId
    if (!isUuid(teamId)) {
      throw NO_PERMISSION_ACCESS_TEAM_API_ERROR;
    }

    const connections = await this.get<ConnectionDto[]>('/v3/connections', {
      type: 'csv',
      team_id: teamId,
    });

    if (connections == null) return [];

    return serializeArrayData(connections, ConnectionDtoSchema);
  }

  async getSchemas(
    teamId: string,
    connectionId: string,
  ): Promise<ConnectionSchemaDto[]> {
    invariant(teamId, 'no team id provided');

    // NOTE: check valid teamId
    if (!isUuid(teamId)) {
      throw NO_PERMISSION_ACCESS_TEAM_API_ERROR;
    }

    invariant(connectionId, 'no connection id provided');

    const schemas = await this.get<ConnectionSchemaDto[]>(
      connectionURL.schemas(teamId, connectionId),
    );

    return schemas ?? [];
  }

  async getColumns(
    teamId: string,
    connectionId: string,
    schemaName: string,
    tableName: string,
  ): Promise<ConnectionColumnDto[]> {
    invariant(teamId, 'no team id provided');

    // NOTE: check valid teamId
    if (!isUuid(teamId)) {
      throw NO_PERMISSION_ACCESS_TEAM_API_ERROR;
    }

    invariant(connectionId, 'no connection id provided');

    const columns = await this.get<ConnectionColumnDto[]>(
      connectionURL.columns(teamId, connectionId),
      {
        schema_name: schemaName,
        table_name: tableName,
      },
    );

    return columns ?? [];
  }

  async saveSchemas(
    teamId: string,
    connectionId: string,
    payload: ConnectionSchemaDto[],
  ): Promise<ConnectionSchemaDto[]> {
    invariant(teamId, 'no team id provided');
    invariant(connectionId, 'no team id provided');

    const res = await this.request<
      ConnectionSchemaDto[],
      ConnectionSchemaDto[]
    >(connectionURL.schemas(teamId, connectionId), 'put', payload);
    return res ?? [];
  }

  async updateCsvSchema(
    teamId: string,
    connectionId: string,
    payload: CsvColumn[],
  ): Promise<ConnectionDto | null> {
    invariant(teamId, 'no team id provided');
    invariant(connectionId, 'no team id provided');

    const res = await this.request<ConnectionDto, { schemas: CsvColumn[] }>(
      '/v3/connections',
      'put',
      {
        schemas: payload,
      },
      {
        connection_id: connectionId,
        team_id: teamId,
      },
    );
    return res;
  }

  async getSchedules(
    teamId: string,
    connectionId: string,
  ): Promise<ConnectionScheduleDto[]> {
    invariant(teamId, 'no team id provided');

    // NOTE: check valid teamId
    if (!isUuid(teamId)) {
      throw NO_PERMISSION_ACCESS_TEAM_API_ERROR;
    }

    invariant(connectionId, 'no connection id provided');

    const schedules = await this.get<ConnectionScheduleDto[]>(
      connectionURL.schedules(teamId, connectionId),
    );

    return schedules ?? [];
  }

  async createSchedule(
    teamId: string,
    connectionId: string,
    payload: CreateSchedulePayload,
  ): Promise<ConnectionScheduleDto | null> {
    invariant(teamId, 'no team id provided');

    const res = await this.request<
      ConnectionScheduleDto,
      CreateSchedulePayload
    >(connectionURL.schedules(teamId, connectionId), 'post', payload);
    return res;
  }

  async updateSchedule(
    teamId: string,
    connectionId: string,
    scheduleId: string,
    payload: CreateSchedulePayload,
  ): Promise<ConnectionScheduleDto | null> {
    invariant(teamId, 'no team id provided');

    const res = await this.request<
      ConnectionScheduleDto,
      CreateSchedulePayload
    >(
      connectionURL.schedulesId(teamId, connectionId, scheduleId),
      'put',
      payload,
    );
    return res;
  }

  async deleteSchedule(
    teamId: string,
    connectionId: string,
    scheduleId: string,
  ): Promise<unknown> {
    invariant(teamId, 'no team id provided');
    invariant(connectionId, 'no connection id provided');
    invariant(scheduleId, 'no schedule id provided');

    const res = await this.request(
      connectionURL.schedulesId(teamId, connectionId, scheduleId),
      'delete',
    );
    return res;
  }

  async getFileList(
    teamId: string,
    connectionId: number,
  ): Promise<UploadedFileDto[] | null> {
    invariant(teamId, 'no team id provided');

    const files = await this.get<UploadedFileDto[]>(
      '/v3/connections/csvbox/uploads',
      { team_id: teamId, connection_id: connectionId },
    );
    return serializeArrayData(files ?? [], UploadedFileDtoSchema);
  }

  async uploadFile(
    teamId: string,
    connectionId: number,
    data: any,
  ): Promise<unknown> {
    invariant(teamId, 'no team id provided');

    const res = await this.request(
      '/v3/connections/csvbox/upload',
      'post',
      { connection_id: connectionId, data: data },
      { team_id: teamId },
    );
    return res;
  }

  async createConnection(
    teamId: string,
    payload: CreateConnectionPayload,
  ): Promise<ConnectionDto | null> {
    invariant(teamId, 'no team id provided');

    const res = await this.request<ConnectionDto, CreateConnectionPayload>(
      '/v3/connections',
      'post',
      payload,
      {
        team_id: teamId,
        connection_type: 'csv',
      },
    );
    return res;
  }

  async updateConnection(
    teamId: string,
    payload: Partial<Omit<ConnectionDto, 'id'>>,
  ): Promise<ConnectionDto | null> {
    invariant(teamId, 'no team id provided');

    const res = await this.request<
      ConnectionDto,
      Partial<Omit<ConnectionDto, 'id'>>
    >('/v3/connections', 'post', payload, {
      team_id: teamId,
      connection_type: 'csv',
    });
    return res;
  }

  async getConnectionList(teamId: string) {
    this.invariantUUID(teamId);

    const connections = await this.get<ListConnection>(
      connectionURL.getAll(teamId),
    );

    if (connections == null) return [];

    return connections;
  }

  async getConnectionPositionList(teamId: string) {
    this.invariantUUID(teamId);

    const connections = await this.get<ConnectionsPosition[]>(
      connectionURL.position(teamId),
    );

    if (connections == null) return [];

    return connections;
  }

  async updateConnectionPositionList(
    teamId: string,
    payload: ConnectionsPosition[],
  ) {
    this.invariantUUID(teamId);

    const res = await this.request<
      {
        detail: string;
      },
      ConnectionsPosition[]
    >(connectionURL.position(teamId), 'put', payload);

    return res;
  }

  async createDataSourceConnection(teamId: string, payload: ConnectionPayload) {
    this.invariantUUID(teamId);

    return await this.request<ConnectionMutationResult, ConnectionPayload>(
      connectionURL.getAll(teamId),
      'post',
      {
        ...payload,
        base_callback_url: window.origin,
      },
    );
  }

  async getConnectionById(teamId: string, connId: string) {
    this.invariantUUID(teamId);
    this.invariantUUID(connId);

    return await this.get<ConnectionMutationResult>(
      connectionURL.getById(teamId, connId),
    );
  }

  async getDataSourcesById(sourceId: string) {
    this.invariantUUID(sourceId);

    const dataSource = await this.get<ConnectionDS>(
      dataSourcesURL.getById(sourceId),
    );

    if (!dataSource) return {};

    return dataSource;
  }

  async getDataSources(queryParams: QueryParams) {
    const queryUrl = new URLSearchParams(
      queryParams as Record<string, string>,
    ).toString();

    return await this.get<{
      total_rows?: number;
      results?: Array<ConnectionDS>;
    }>(dataSourcesURL.getAll() + '?' + queryUrl);
  }

  async putConnectionStatus(
    teamId: string,
    connectionId: string,
    payload: Partial<ConnectionStatusPayload>,
  ) {
    this.invariantUUID(teamId);
    this.invariantUUID(connectionId);

    return await this.request<
      ConnectionMutationResult,
      Partial<ConnectionStatusPayload>
    >(connectionURL.getById(teamId, connectionId), 'put', payload);
  }

  async postConnectionSync(teamId: string, connectionId: string) {
    this.invariantUUID(teamId);
    this.invariantUUID(connectionId);

    return this.request<ConnectionMutationResult, ConnectionMutationResult>(
      connectionURL.syncById(teamId, connectionId),
      'post',
    );
  }

  async getConnectionStatistics(teamId: string) {
    this.invariantUUID(teamId);

    const statistic = await this.get<Array<ConnectionStatistics>>(
      connectionURL.statistics(teamId),
    );

    if (!statistic) return [];

    return statistic;
  }

  async getSyncHistories(teamId: string, connectionId: string) {
    this.invariantUUID(teamId);
    this.invariantUUID(connectionId);

    const syncHistories = await this.get<Array<ConnectionSyncLog>>(
      connectionURL.syncHistories(teamId, connectionId),
    );

    if (!syncHistories) return [];

    return syncHistories;
  }

  deleteConnection(teamId: string, connectionId: string) {
    this.invariantUUID(teamId);
    this.invariantUUID(connectionId);

    return this.request(connectionURL.getById(teamId, connectionId), 'delete');
  }

  downloadConnectionSynHistory(
    teamId: string,
    connectionId: string,
    historyId: string,
  ) {
    this.invariantUUID(teamId);
    this.invariantUUID(connectionId);
    this.invariantUUID(historyId);

    return this.request<string>(
      connectionURL.downloadByUrl(teamId, connectionId, historyId),
      'get',
    );
  }

  deleteCSVConnection(teamId: string, connectionId: string) {
    this.invariantUUID(teamId);
    const queryParams = new URLSearchParams({
      team_id: teamId,
      connection_id: connectionId,
    });

    return this.request(`/v3/connections?${queryParams.toString()}`, 'delete');
  }
}

export const downloadConnectionSyncHistories = async (
  teamId: string,
  connectionId: string,
  historyId: string,
) => {
  return axiosHttpClient.get(
    connectionURL.downloadSyncHistories(teamId, connectionId, historyId),
    {
      responseType: 'blob',
    },
  );
};

/**
 * @deprecated
 */
export const ConnectionApiAdapter = Object.freeze(
  new ConnectionApiAdapterClass(clientHttp),
);

export const ConnectionApi = {
  onBrowser: () => new ConnectionApiAdapterClass(clientHttp),
  onServer: () => new ConnectionApiAdapterClass(serverHttp),
};
