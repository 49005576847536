import { z } from 'zod';

import { zViewConfig } from '../../models/view';

export const zViewRecommendation = z.object({
  recommendation_id: z.string(),
  user_request: z.string().nullish(),
  failed_reason: z.string().nullish(),
  status: z.enum(['IN_PROGRESS', 'COMPLETED', 'FAILED']),
  created_at: z.string().nullish(),
  suggested_view_config: zViewConfig.nullish(),
});

export type ViewRecommendation = z.infer<typeof zViewRecommendation>;

export type GlobalPrompt = {
  teamId: string;
  dashboardId: string;
  widgetId: string;
  viewId: string;
  dashboardName: string;
  viewName: string;
  prompt: ViewRecommendation;
};
