import { z } from 'zod';

import { zCommonConfig, zCompareToThePastConfig } from './common';

// Handle backward compatibility

export const zCompatibleCommonConfig = zCommonConfig.merge(
  z.object({
    // NOTE: Change compare to past config so need to conver old -> new config
    compare_with_past: zCompareToThePastConfig
      .catch((ctx) => {
        const errorConfig = ctx.input as any;
        if (!ctx.input || !ctx.input.period_time)
          return { period_time: 'RIGHT_BEFORE' };
        if (errorConfig === 'YESTERDAY')
          return { period_time: 'DAY', gap_value: 1 };
        if (errorConfig === 'LAST_WEEK')
          return { period_time: 'WEEK', gap_value: 1 };
        if (errorConfig === 'LAST_MONTH')
          return { period_time: 'MONTH', gap_value: 1 };
        return { period_time: 'RIGHT_BEFORE' };
      })
      .nullish(),
  }),
);
