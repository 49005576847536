'use client';

import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useSession } from 'next-auth/react';

import { useGlobalMe } from '@/hooks/use-global-me';
import { useTeamsQuery } from '@/layouts/navbar/use-team';

export default function SurvicateInit({
  enable,
  survicateKey,
}: {
  enable: boolean;
  survicateKey: string;
}) {
  const router = useRouter();

  const { status: sessionStatus, data: session } = useSession();
  const { isLoading: meLoading, me } = useGlobalMe();

  const isEnableApiCalls =
    session != null &&
    sessionStatus === 'authenticated' &&
    session?.error !== 'RefreshAccessTokenError';

  const { data: activeTeam } = useTeamsQuery({
    enabled: isEnableApiCalls,
    select: (teams) => teams.find((t) => t.id === router.query.team_id),
  });

  useEffect(() => {
    if (!enable) return;
    if (!me) return;
    if (!survicateKey) return;

    var a = document.getElementById('survicate');
    if (a) a.remove();

    (function (opts) {
      opts.traits = {
        email: me?.email ?? 'Unknown',
        username: me?.username ?? 'Unknown',
        team: activeTeam?.name ?? 'Unknown',
      };
      //@ts-ignore
    })((window._sva = window._sva || {}));

    (function (w) {
      var s = document.createElement('script');
      s.src = `https://survey.survicate.com/workspaces/${survicateKey}/web_surveys.js`;
      s.async = true;
      s.id = 'survicate';
      var e = document.getElementsByTagName('script')[0];
      e.parentNode?.insertBefore(s, e);
    })(window);
  }, [enable, survicateKey, me, activeTeam]);

  return null;
}
