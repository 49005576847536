import { z } from 'zod';

import {
  DagNodeItem,
  IDagConfig,
  IDataSourceNode,
} from '@/feature-graph/types';
import { zColumnType } from '@/models/view';

const zTemplateType = z.enum([
  'ONE_DATA_SOURCE_ONE_CONNECTION_PER',
  'ONE_DATA_SOURCE_MANY_CONNECTIONS_PER',
  'MANY_DATA_SOURCES_ONE_CONNECTION_PER',
  'MANY_DATA_SOURCES_MANY_CONNECTIONS_PER',
]);

export type TemplateType = z.infer<typeof zTemplateType>;

const zTemplateDataSource = z.object({
  id: z.string(),
  name: z.string(),
  icon_url: z.string().nullish(),
});

export type TemplateDataSource = z.infer<typeof zTemplateDataSource>;

const zTemplateColumnType = z.enum([
  'STRING',
  'BOOLEAN',
  'DATETIME',
  'INTEGER',
  'DECIMAL',
  'DATE',
  'ARRAY',
  'TIMESTAMP',
  'INT64',
  'FLOAT64',
  'BIGNUMERIC',
  'NUMERIC',
  'BOOL',
]);

export type TemplateColumnType = z.infer<typeof zTemplateColumnType>;

const zTemplateColumn = z.object({
  name: z.string(),
  description: z.string().nullish(),
  // type: zTemplateColumnType.nullish(),
  // index: z.number().nullish(),
  type: zTemplateColumnType,
});

export type TemplateColumn = z.infer<typeof zTemplateColumn>;

const zTemplateData = z.object({
  id: z.string(),
  original_id: z.string(),
  name: z.string(),
  type: zTemplateType,
  is_active: z.boolean(),
  data_sources: z.array(zTemplateDataSource).nullish(),
  config: z
    .object({
      columns: z.array(zTemplateColumn),
    })
    .nullish(),
  description: z.string().nullish(),
  created_at: z.string().nullish(),
  updated_at: z.string().nullish(),
});

export type TemplateData = z.infer<typeof zTemplateData>;

/// MODELING

const zCollectionCategory = z.enum(['COLLECTION', 'DATA_MODEL']);

export type CollectionCategory = z.infer<typeof zCollectionCategory>;

const zCollectionMaintainer = z.object({
  id: z.string(),
  username: z.string(),
  avatar_url: z.string().nullish(),
});

export type CollectionMaintainer = z.infer<typeof zCollectionMaintainer>;

const zCollectionType = z.enum(['DEFAULT', 'CUSTOMIZED']);

export type CollectionType = z.infer<typeof zCollectionType>;

const zCollectionDataModelType = z.enum(['TABLE', 'VIEW']);

export type CollectionDataModelType = z.infer<typeof zCollectionDataModelType>;

export const zCollectionDataModelColumn = z.object({
  property: z.string(),
  description: z.string().nullish(),
  type: zColumnType,
  // original_type: zTemplateColumnType,
});

export type CollectionDataModelColumn = z.infer<
  typeof zCollectionDataModelColumn
>;

export const zTransformationDataModels = z.object({
  category: z.literal('DATA_MODEL'),
  id: z.string(),
  team_id: z.string(),
  parent_collection_id: z.string().nullish(),
  description: z.string().nullish(),
  created_at: z.string().nullish(),
  updated_at: z.string().nullish(),
  creator: zCollectionMaintainer,
  updated_by: zCollectionMaintainer.nullish(),
  name: z.string(),
  dataset_id: z.string(),
  //
  type: zCollectionDataModelType.nullish(),
  columns: z.array(zCollectionDataModelColumn).nullish(),
  is_healthy: z.boolean(),
  _is_temp: z.boolean().nullish(),
});
export type TransformationDataModels = z.infer<
  typeof zTransformationDataModels
>;

export const zTransformationCollection = z.object({
  category: z.literal('COLLECTION'),
  id: z.string(),
  team_id: z.string(),
  parent_collection_id: z.string().nullish(),
  description: z.string().nullish(),
  created_at: z.string().nullish(),
  updated_at: z.string().nullish(),
  creator: zCollectionMaintainer.nullish(),
  updated_by: zCollectionMaintainer.nullish(),
  name: z.string(),
  //
  type: zCollectionType,
});
export type TransformationCollection = z.infer<
  typeof zTransformationCollection
>;

export const zCollection = z.union([
  zTransformationCollection,
  zTransformationDataModels,
]);

export type Collection = z.infer<typeof zCollection>;

const zCreateCollectionPayload = z.object({
  id: z.string(), // NOTE: client must specify id for sake of ease optimistic operations
  name: z.string(),
  description: z.string().nullish(),
  parent_collection_id: z.string().uuid().nullish(),
});

export type CreateCollectionPayload = z.infer<typeof zCreateCollectionPayload>;

const zUpdateCollectionPayload = z.object({
  name: z.string().nullish(),
  description: z.string().nullish(),
  parent_collection_id: z.string().uuid().nullish(),
  columns: z.array(zCollectionDataModelColumn.partial()).nullish(),
});

export type UpdateCollectionPayload = z.infer<typeof zUpdateCollectionPayload>;

const zBatchDeleteCollectionsPayload = z.object({
  collection_ids: z.array(z.string()),
  data_models: z.array(
    z.object({
      id: z.string(),
      dataset_id: z.string(),
    }),
  ),
});

export type BatchDeleteCollectionsPayload = z.infer<
  typeof zBatchDeleteCollectionsPayload
>;

export type TransformationDagQuery = {
  datasets: Array<IDataSourceNode>;
  nodes: Array<DagNodeItem>;
};

interface IBaseDagPayload {
  id: string;
  dag_config: IDagConfig;
}
export type TransformationDagPayload =
  | (IBaseDagPayload & { category: 'DATASET' })
  | (IBaseDagPayload & { category: 'DATA_MODEL'; dataset_id: string });

export type DuplicateDataModelPayload = {
  id?: string;
  parent_collection_id: string;
};
