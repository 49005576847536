'use client';

import type {
  QueryKeyDataInfer,
  QueryKeyOptionsInfer,
} from '@/query-key-factory';

import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { setupUserPermissions, useAppAbility } from '@/adapters/rbac';
import { useTeamsQuery } from '@/layouts/navbar/use-team';
import { userQueryKeys } from '@/query-key-factory';

const STALE_TIME_MS = 1000 * 60 * 5; // NOTE: 5 minutes

function useMyPermissionsQuery<
  T = QueryKeyDataInfer<typeof userQueryKeys.permissions>,
>(options?: QueryKeyOptionsInfer<typeof userQueryKeys.permissions, T>) {
  const { data: session } = useSession();

  const userId = session?.user?.id ?? '';

  // NOTE: need to check whether user has team or not, if there are no teams -> user has not finish sign up process
  const { data: teams } = useTeamsQuery({
    enabled: session != null && session?.error == null,
  });

  const enabled =
    session != null &&
    session?.error == null &&
    Boolean(userId) &&
    Boolean(teams?.length);

  return useQuery({
    ...userQueryKeys.permissions(userId),
    enabled,
    staleTime: STALE_TIME_MS,
    ...options,
  });
}

export function SetupUserRbacPermissions() {
  const ability = useAppAbility();
  const { data: permissionsResult, isInitialLoading } = useMyPermissionsQuery();

  useEffect(() => {
    if (isInitialLoading || !permissionsResult) return;
    setupUserPermissions(ability, permissionsResult);
  }, [isInitialLoading, ability, permissionsResult]);

  return null;
}
