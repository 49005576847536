import { z } from 'zod';

import { zCollectionDataModelColumn } from '@/feature-transformation/model';

import { zColumnType, zViewType } from './view';

const zColumn = z.object({
  column_name: z.string(),
  column_type: zColumnType,
});
export type DataModelColumn = z.infer<typeof zColumn>;

const zDataModelType = z.enum(['TABLE', 'VIEW', 'EXTERNAL']);
export type DataModelType = z.infer<typeof zDataModelType>;
export const zDataModels = z.object({
  data_model_id: z.string(),
  name: z.string(),
  type: zDataModelType.nullish(),
  visible: z.boolean().nullish(),
  columns: z.array(zCollectionDataModelColumn).nullish(),
  created_at: z.string().nullish(),
  description: z.string().nullish(),
  is_healthy: z.boolean().nullish(),
});
export type DataModelDto = z.infer<typeof zDataModels>;

export const zDatasetType = z.enum(['COLLECTION', 'CONNECTION']);
export type DatasetType = z.infer<typeof zDatasetType>;

const zDataset = z.object({
  id: z.string(),
  name: z.string().nullable(),
  type: zDatasetType.nullish(),
  icon_url: z.string().nullable(),
  connection_name: z.string().nullish(),
  data_source_name: z.string().nullish(),
  description: z.string().nullish(),
});

export const zDataModelsSchema = z.object({
  dataset: zDataset,
  data_models: z.array(zDataModels),
});

export const zCollectionDataModelColumns = z.object({
  data_model_id: z.string(),
  name: z.string(),
  columns: z.array(zCollectionDataModelColumn),
});

export type DataModelColumns = z.infer<typeof zCollectionDataModelColumns>;

export type DataModelsDto = z.infer<typeof zDataModelsSchema>;

const zDataModelLogResult = z.object({
  id: z.string().uuid().nullish(),
  creator: z.object({
    id: z.string().uuid().nullish(),
    username: z.string().nullish(),
    avatar_url: z.string().nullish(),
  }),
  title: z.string().nullish(),
  description: z.string().nullish(),
  created_at: z.string().nullish(),
});

export const zDataModelsLogs = z.object({
  results: z.array(zDataModelLogResult),
  total_rows: z.number(),
});
export type DataModelsLogs = z.infer<typeof zDataModelsLogs>;

const zDataModelRelatedBase = z.object({
  id: z.string(),
  name: z.string().nullish(),
  description: z.string().nullish(),
  updated_at: z.string().nullish(),
  is_downstream: z.boolean().nullish(),
  accessible: z.boolean().nullish(),
});

const zDataModelRelatedView = zDataModelRelatedBase.extend({
  type: z.literal('VIEW'),
  view_type: zViewType,
  widget_id: z.string().nullish(),
  dashboard_id: z.string().nullish(),
});

const zDataModelRelatedDashboard = zDataModelRelatedBase.extend({
  type: z.literal('DASHBOARD'),
});

const zDataModelRelatedDataModel = zDataModelRelatedBase.extend({
  type: z.literal('DATA_MODEL'),
  dataset_id: z.string().nullish(),
  team_id: z.string().nullish(),
  category: zDatasetType.nullish(),
});

const zDataModelRelatedConnection = zDataModelRelatedBase.extend({
  type: z.literal('CONNECTION'),
});

export const zDataModelsRelatedItem = z.union([
  zDataModelRelatedView,
  zDataModelRelatedDashboard,
  zDataModelRelatedDataModel,
  zDataModelRelatedConnection,
]);
export type DataModelsRelatedItem = z.infer<typeof zDataModelsRelatedItem>;

export const zDownstreamDataModel = z.object({
  id: z.string(),
  dataset_id: z.string(),
  name: z.string(),
});

export type DownstreamDataModel = z.infer<typeof zDownstreamDataModel>;
