import type { UserDto } from '@/models/user';

import { useCallback, useEffect } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { MeApi } from '@/adapters/api';
import { userQK } from '@/query-key';
import { getFullUrlService } from '@/services/file';

const EIGHT_HOURS_STALE_TIME_IN_MS = 1000 * 60 * 8; // NOTE: 8 hours
const RETRIES = 1;

export function useGlobalMe() {
  const { status, data: session } = useSession();

  const queryClient = useQueryClient();

  const {
    data: me,
    isInitialLoading,
    error,
  } = useQuery<UserDto | null>(
    userQK.profile(session?.user?.id!!),
    () => MeApi.onBrowser().getProfile(),
    {
      staleTime: EIGHT_HOURS_STALE_TIME_IN_MS,
      // staleTime: 0,
      enabled:
        Boolean(session?.user?.id) &&
        status === 'authenticated' &&
        session?.error !== 'RefreshAccessTokenError',
      retry: RETRIES,
    },
  );

  const setNewMeQueryData = useCallback(
    (newMe: Partial<UserDto>) => {
      queryClient.setQueryData(userQK.profile(session?.user?.id!!), {
        ...me,
        ...newMe,
        // avatar_url: newAvatarUrl ? getFullUrlService(newAvatarUrl) : null,
      });
    },
    [queryClient, me, session?.user?.id],
  );

  return { me, isLoading: isInitialLoading, error, setNewMeQueryData };
}
