import {
  BaseApiAdapterClass,
  type RequestManager,
} from '@/adapters/api/__base';
import {
  DestinationVerificationPayload,
  ExportFilterParams,
  IExportCreatePayload,
  IExportCreateResponse,
  IExportEditPayload,
  IGoogleSheetVerificationResult,
} from '@/models/export';

import { clientHttp, serverHttp } from './axios';

class ExportsApiAdapterClass extends BaseApiAdapterClass {
  constructor(rm: RequestManager) {
    super(rm);
  }

  private getBaseUrl(teamId: string) {
    return `/v3/teams/${teamId}/exports`;
  }

  private getDetailUrl(teamId: string, exportId: string) {
    return `/v3/teams/${teamId}/exports/${exportId}`;
  }

  verify(teamId: string, payload: DestinationVerificationPayload) {
    return this.request<
      IGoogleSheetVerificationResult,
      DestinationVerificationPayload
    >(`${this.getBaseUrl(teamId)}/verify-destination-auth`, 'post', payload);
  }

  create(teamId: string, payload: IExportCreatePayload) {
    return this.request<IExportCreateResponse, IExportCreatePayload>(
      this.getBaseUrl(teamId),
      'post',
      payload,
    );
  }

  filter(teamId: string, payload: ExportFilterParams) {
    return this.request<
      IExportCreateResponse[],
      { source: ExportFilterParams }
    >(`${this.getBaseUrl(teamId)}/filter`, 'post', {
      source: payload,
    });
  }

  edit(teamId: string, exportId: string, payload: IExportEditPayload) {
    return this.request<IExportCreateResponse, IExportEditPayload>(
      this.getDetailUrl(teamId, exportId),
      'put',
      payload,
    );
  }

  export(teamId: string, exportId: string) {
    return this.request<void>(
      `${this.getDetailUrl(teamId, exportId)}/export`,
      'post',
    );
  }

  delete(teamId: string, exportId: string) {
    return this.request<void>(this.getDetailUrl(teamId, exportId), 'delete');
  }

  getById(teamId: string, exportId: string) {
    return this.get<IExportCreateResponse>(this.getDetailUrl(teamId, exportId));
  }

  deleteByIds(teamId: string, ids: Array<string>) {
    return this.request<string, { export_ids: Array<string> }>(
      `${this.getBaseUrl(teamId)}/bulk-delete`,
      'delete',
      {
        export_ids: ids,
      },
    );
  }
}

/**
 * @deprecated
 */
export const ExportsClientApiAdapter = Object.freeze(
  new ExportsApiAdapterClass(clientHttp),
);
export const ExportsApi = {
  onBrowser: () => new ExportsApiAdapterClass(clientHttp),
  onServer: () => new ExportsApiAdapterClass(serverHttp),
};
