const DisconnectIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1010_9924)">
        <path
          d="M3.2334 17.3333H10.5361C10.7642 21.5558 12.1101 25.6418 14.4361 29.1733C11.5226 28.7157 8.84299 27.3055 6.81609 25.1633C4.78918 23.0211 3.52929 20.2676 3.2334 17.3333ZM3.2334 14.6667C3.52929 11.7324 4.78918 8.9789 6.81609 6.83668C8.84299 4.69446 11.5226 3.28429 14.4361 2.82666C12.1101 6.35814 10.7642 10.4442 10.5361 14.6667H3.2334ZM29.7667 14.6667H22.4641C22.2359 10.4442 20.89 6.35814 18.5641 2.82666C21.4775 3.28429 24.1571 4.69446 26.184 6.83668C28.211 8.9789 29.4708 11.7324 29.7667 14.6667ZM29.7667 17.3333C29.4708 20.2676 28.211 23.0211 26.184 25.1633C24.1571 27.3055 21.4775 28.7157 18.5641 29.1733C20.89 25.6418 22.2359 21.5558 22.4641 17.3333H29.7681H29.7667ZM13.2067 17.3333H19.7934C19.5739 20.9326 18.4405 24.4166 16.5001 27.456C14.5597 24.4166 13.4262 20.9326 13.2067 17.3333ZM13.2067 14.6667C13.4262 11.0673 14.5597 7.58341 16.5001 4.54399C18.4405 7.58341 19.5739 11.0673 19.7934 14.6667H13.2067Z"
          fill="#D9E0E8"
        />
      </g>
      <g clip-path="url(#clip1_1010_9924)">
        <path
          d="M23.5001 7.93955L26.6823 4.7573C26.9752 4.46445 27.45 4.46445 27.7428 4.7573C28.0357 5.05015 28.0357 5.52495 27.7428 5.8178L24.5606 9.00005L27.7428 12.1823C28.0357 12.4752 28.0357 12.95 27.7428 13.2428C27.45 13.5357 26.9752 13.5357 26.6823 13.2428L23.5001 10.0606L20.3178 13.2428C20.025 13.5357 19.5501 13.5357 19.2573 13.2428C18.9645 12.95 18.9645 12.4752 19.2573 12.1823L22.4396 9.00005L19.2573 5.8178C18.9645 5.52495 18.9645 5.05015 19.2573 4.7573C19.5501 4.46445 20.025 4.46445 20.3178 4.7573L23.5001 7.93955Z"
          fill="#EB5757"
        />
      </g>
      <defs>
        <clipPath id="clip0_1010_9924">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
        <clipPath id="clip1_1010_9924">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(14.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DisconnectIcon;
