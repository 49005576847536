import { useEffect } from 'react';

import Image from 'next/image';

import { Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { FallbackProps } from 'react-error-boundary';

import DisconnectIcon from '@/assets/icons/DisconnectIcon';
import LogoCigro2 from '@/assets/icons/LogoCigro2';
import AppButton from '@/components/Button/Button.component';
import useNetworkStatus from '@/hooks/use-network-status';

export const NetworkErrorPage: React.FC<Omit<FallbackProps, 'error'>> = ({
  resetErrorBoundary,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'error-page' });

  const isOnline = useNetworkStatus();

  useEffect(() => {
    if (isOnline) {
      resetErrorBoundary();
    }
  }, [isOnline, resetErrorBoundary]);

  return (
    <Stack height="100vh">
      <Container maxWidth="md" sx={{ flexGrow: 1 }}>
        <Stack
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          gap="16px">
          <Stack
            direction="row"
            alignItems="center"
            bgcolor="neutralV2.6"
            borderRadius="99px"
            gap="24px"
            boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)">
            <LogoCigro2 />
            <Stack p="6px" borderRadius="99px" bgcolor="white" />
            <Stack p="6px" borderRadius="99px" bgcolor="neutralV2.5" />
            <Stack p="6px" borderRadius="99px" bgcolor="neutralV2.4" />
            <DisconnectIcon />
          </Stack>

          <Stack mt="32px">
            <Typography variant="body2" textAlign="center" color="neutralV2.1">
              {t('there-is-a-problem-connecting-with-the-internet')}
            </Typography>
            <Typography
              variant="subhead5"
              fontWeight="500"
              textAlign="center"
              color="neutralV2.0">
              {t('cigro-will-attempt-automatically-to-reconnect')}
            </Typography>
          </Stack>

          <Stack
            width="100%"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            gap="8px">
            <AppButton
              color="primary"
              onClick={resetErrorBoundary}
              sx={{ padding: '7px 12px' }}>
              <Typography variant="small1" color="neutralV2.8">
                {t('reconnect')}
              </Typography>
            </AppButton>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
