'use client';
import React from 'react';

import { Global } from '@emotion/react';

import { CSS_VAR_SIDE_BAR_WIDTH } from '@/constants/configs';
import { CIGRO_SCROLL_BAR_MIXINS } from '@/constants/mixins';
import { useEnvironmentContext } from '@/contexts/EnvironmentContext';
import {
  CSS_VAR_IBM_PLEX_MONO_FONT_FAMILY,
  CSS_VAR_INTER_FONT_FAMILY,
} from '@/theme';
import { ibmPlexMono, inter } from '@/theme/font-family';

import { CSS_VAR_SIDE_NAVBAR_WIDTH } from '../../pages/_app';
import {
  useSidebarWidth,
  useSidebarWidthApp,
} from '../layouts/sidebar/use-sidebar-width';

export const AppGlobalStyles = () => {
  const { isOpenSidebar } = useEnvironmentContext();
  const { width } = useSidebarWidth();

  return (
    <Global
      styles={{
        'html, body, #__next': {
          ['::-webkit-scrollbar']: {
            display: 'none',
          },
          '.chart-tooltip': CIGRO_SCROLL_BAR_MIXINS.style,
          [CSS_VAR_SIDE_BAR_WIDTH]: isOpenSidebar ? `${width}px` : '0px',
          [CSS_VAR_INTER_FONT_FAMILY]: inter.style.fontFamily,
          [CSS_VAR_IBM_PLEX_MONO_FONT_FAMILY]: ibmPlexMono.style.fontFamily,
          [CSS_VAR_SIDE_NAVBAR_WIDTH]: '48px',
        },
      }}
    />
  );
};

export const AppGlobalStylesApp = () => {
  const { isOpenSidebar } = useEnvironmentContext();
  const { width } = useSidebarWidthApp();

  return (
    <Global
      styles={{
        'html, body, #__next': {
          ['::-webkit-scrollbar']: {
            display: 'none',
          },
          '.chart-tooltip': CIGRO_SCROLL_BAR_MIXINS.style,
          [CSS_VAR_SIDE_BAR_WIDTH]: isOpenSidebar ? `${width}px` : '0px',
          [CSS_VAR_INTER_FONT_FAMILY]: inter.style.fontFamily,
          [CSS_VAR_IBM_PLEX_MONO_FONT_FAMILY]: ibmPlexMono.style.fontFamily,
          [CSS_VAR_SIDE_NAVBAR_WIDTH]: '48px',
        },
      }}
    />
  );
};
