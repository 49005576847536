import { z } from 'zod';

import { zAggregateItem, zGrouping, zGroupItem, zSortItem } from './common';
import { zCompatibleCommonConfig } from './compatibility';

export const zGalleryConfig = z.object({
  view_type: z.literal('GALLERY'),
  is_grouping_mode: z.boolean().optional().default(false),
  image: zGroupItem.nullish(),
  title: zGroupItem.nullish(),
  description: zGroupItem.nullish(),
  link: zGroupItem.nullish(),
  link_text: z.string().nullish(),
  other_columns: z.array(zAggregateItem).nullish().default([]),
  sort: z.array(zSortItem).nullish().default([]),
  image_fit: z.enum(['FILL', 'FIT']).nullish().default('FIT'),
});

export const zGalleryFullConfig = zCompatibleCommonConfig.merge(zGalleryConfig);

export const zGalleryRenderingConfig = zGalleryFullConfig
  .merge(
    z.object({
      grouping: zGrouping.merge(
        z.object({
          columns: z.array(zGroupItem).max(2, {
            message: 'gallery-config-grouping-columns-length-max-2',
          }),
        }),
      ),
    }),
  )
  .refine(
    (config) => {
      // NOTE: Grouped columns can only be title config or image config, or both
      const invalidGroupColumns = config.grouping.columns.filter(
        (col) =>
          col.column_name !== config.title?.column_name &&
          col.column_name !== config.image?.column_name,
      );
      if (invalidGroupColumns.length > 0) return false;
      return true;
    },
    {
      message: 'gallery-config-grouping-column-can-only-contain-image-or-title',
    },
  )
  .refine(
    (config) => {
      if (config.grouping.columns.length === 0 || !config.sort) return true;
      // NOTE: Sorted can contain only grouped columns and sub aggregated columns
      const invalidSort = config?.sort.filter(
        (col) =>
          !Boolean(
            config.grouping.columns.find(
              (item) => item.column_name === col.column_name,
            ),
          ) &&
          !Boolean(
            config.grouping.sub_aggregates.find(
              (item) => item.column_name === col.column_name,
            ),
          ),
      );
      if (invalidSort && invalidSort.length > 0) return false;
      return true;
    },
    {
      message:
        'gallery-config-grouping-sort-can-only-contain-grouped-or-aggregated-columns',
    },
  );

export type GalleryImageFitlConfig = z.infer<
  typeof zGalleryFullConfig
>['image_fit'];
export type GalleryFullConfig = z.infer<typeof zGalleryFullConfig>;

export type GalleryConfig = z.infer<typeof zGalleryConfig>;
