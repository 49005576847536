import { useEffect } from 'react';

export default function useViewportWidthPolyfill() {
  useEffect(() => {
    const setVw = () => {
      const vw = window.innerWidth * 0.01;
      document.documentElement.style.setProperty('--vw', `${vw}px`);
    };
    setVw();
  }, []);
}
