import { z } from 'zod';

export const zDateSchema = z.coerce.date();

export type DateSchema = z.infer<typeof zDateSchema>;

export const zApiServerErrorSchema = z.object({
  detail: z.union([
    z.array(
      z.object({
        loc: z.array(z.union([z.string(), z.number()])),
        msg: z.string(),
        type: z.string(),
      }),
    ),
    z.string(),
  ]),
  error_code: z.number().int().nullable().optional(),
  error_message: z.string().nullish(),
});
export type ApiServerError = z.infer<typeof zApiServerErrorSchema>;

const zThousandDecimalSeparatorType = z.enum([
  'PERIOD_AND_COMMA',
  'COMMA_AND_PERIOD',
]);

export type ThousandDecimalSeparatorType = z.infer<
  typeof zThousandDecimalSeparatorType
>;

const zColumnConfigFirstPreset = z.object({
  preset_name: z.literal('FIRST'),
  decimal_places: z.number().int().min(0).max(8),
  thousand_decimal_separator_type: zThousandDecimalSeparatorType,
  show_thousand_separator: z.boolean().nullish(),
});

export type ColumnConfigFirstPreset = z.infer<typeof zColumnConfigFirstPreset>;

const zDecimalSeparatorType = z.enum(['PERIOD', 'COMMA']);

export type DecimalSeparatorType = z.infer<typeof zDecimalSeparatorType>;

const zColumnConfigSecondPreset = z.object({
  preset_name: z.literal('SECOND'),
  decimal_places: z.number().int().min(0).max(8),
  decimal_separator_type: zDecimalSeparatorType,
});

export type ColumnConfigSecondPreset = z.infer<
  typeof zColumnConfigSecondPreset
>;

const zLargeNumberAbbreviation = z.enum([
  'THOUSAND',
  'MILLION',
  'BILLION',
  'NONE',
]);

export type LargeNumberAbbreviationType = z.infer<
  typeof zLargeNumberAbbreviation
>;

const zColumnConfigThirdPreset = z.object({
  preset_name: z.literal('THIRD'),
  decimal_places: z.number().int().min(0).max(8),
  large_number_abbreviation: zLargeNumberAbbreviation.nullable(),
});

export type ColumnConfigThirdPreset = z.infer<typeof zColumnConfigThirdPreset>;

const zColumnConfigFormat = z.union([
  zColumnConfigFirstPreset,
  zColumnConfigSecondPreset,
  zColumnConfigThirdPreset,
  z.null(),
]);

export type ColumnConfigFormat = z.infer<typeof zColumnConfigFormat>;

const zMax10CharStr = z.string().max(10, 'maximum-10-characters');

export const zNumberColumnConfig = z.object({
  column_type: z.literal('NUMBER'),
  alias: z.string().nullish(),
  prefix: zMax10CharStr.nullish(),
  suffix: zMax10CharStr.nullish(),
  format: zColumnConfigFormat.nullish(),
  formula: z.string().nullish(),
  is_custom_formula_column: z.boolean().nullish(),
});

export type NumberColumnConfig = z.infer<typeof zNumberColumnConfig>;

export const zTextColumnConfig = z.object({
  column_type: z.literal('TEXT'),
  alias: z.string().nullish(),
  prefix: zMax10CharStr.nullish(),
  suffix: zMax10CharStr.nullish(),
  formula: z.string().nullish(),
  is_custom_formula_column: z.boolean().nullish(),
});

export type TextColumnConfig = z.infer<typeof zTextColumnConfig>;

export const zDateColumnConfig = z.object({
  column_type: z.literal('DATETIME'),
  alias: z.string().nullish(),
  formula: z.string().nullish(),
  is_custom_formula_column: z.boolean().nullish(),
});

export type DateColumnConfig = z.infer<typeof zDateColumnConfig>;

export const zBooleanColumnConfig = z.object({
  column_type: z.literal('BOOLEAN'),
  alias: z.string().nullish(),
  formula: z.string().nullish(),
  is_custom_formula_column: z.boolean().nullish(),
});

export type BooleanColumnConfig = z.infer<typeof zBooleanColumnConfig>;

export const zCustomColumnConfig = z.union([
  zNumberColumnConfig,
  zTextColumnConfig,
  zBooleanColumnConfig,
  zDateColumnConfig,
]);

export type CustomColumnConfig = z.infer<typeof zCustomColumnConfig>;

export const zCustomColumnsConfigMap = z.record(
  z.string(),
  zCustomColumnConfig,
);

export type CustomColumnsConfigMap = z.infer<typeof zCustomColumnsConfigMap>;
export const zDayOfWeek = z.enum([
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
]);

export type DayOfWeek = z.infer<typeof zDayOfWeek>;
