'use client';

import { createContext, PropsWithChildren, useMemo } from 'react';

import { useRouter } from 'next/router';

import { useQueries, UseQueryOptions } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';
import { create } from 'zustand';
import { devtools, persist, redux } from 'zustand/middleware';

import { WidgetApi } from '@/adapters/api';

import { reportQK } from '../../query-key';
import { getWidgetRoute } from '../../routes';
import { toastPrompt } from '../../utils/notification';
import { PROMPT_POLLING_TIME_OUT } from './constants';
import { GlobalPrompt, ViewRecommendation } from './model';

type GlobalPromptStore = {
  prompts: GlobalPrompt[];
};

type GlobalPromptStoreAction =
  | {
      type: 'ADD_NEW_PROMPT';
      payload: GlobalPrompt | null;
    }
  | {
      type: 'DELETE_PROMPT';
      payload: string | null;
    };

function globalPromptSliceReducer(
  state: GlobalPromptStore,
  action: GlobalPromptStoreAction,
): GlobalPromptStore {
  switch (action.type) {
    case 'ADD_NEW_PROMPT': {
      const { payload } = action;

      if (payload === null) return state;

      const newPrompts = [...state.prompts, payload];

      return { ...state, prompts: newPrompts };
    }
    case 'DELETE_PROMPT': {
      const { payload } = action;

      if (payload === null) return state;

      const newPrompts = state.prompts.filter(
        (prompt) => prompt.prompt?.recommendation_id !== payload,
      );

      return { ...state, prompts: newPrompts };
    }
  }
}

const configReducer = (
  state: GlobalPromptStore,
  action: GlobalPromptStoreAction,
): GlobalPromptStore => {
  const globalPromptSlice = globalPromptSliceReducer(state, action);

  return globalPromptSlice;
};
const initialState: GlobalPromptStore = {
  prompts: [],
};

const isDev = process.env.NODE_ENV !== 'production';

const reduxLikeStore = redux(configReducer, initialState);

export const getGlobalPromptStoreName = (teamId: string) => {
  //   invariant(teamId, 'Missing teamId for config store');
  return `global-recommendation-store ${teamId}`;
};

export const createGlobalPromptStore = (teamId: string) =>
  create(
    devtools(
      persist(reduxLikeStore, {
        name: getGlobalPromptStoreName(teamId),
      }),
      {
        name: getGlobalPromptStoreName(teamId),
        enabled: isDev,
      },
    ),
  );

export const GlobalPromptStoreContext = createContext<ReturnType<
  typeof createGlobalPromptStore
> | null>(null);

export const GlobalPromptStoreConsumer: React.FC<
  PropsWithChildren & { useStore: ReturnType<typeof createGlobalPromptStore> }
> = ({ children, useStore }) => {
  // const router = useRouter();
  const router = useRouter();
  const { t } = useTranslation('common', { keyPrefix: 'page-report' });
  const prompts = useStore((state) => state.prompts);
  const dispatch = useStore((state) => state.dispatch);

  const result = useQueries({
    queries: prompts.map<UseQueryOptions<ViewRecommendation | null>>((p) => ({
      queryKey: [
        ...reportQK.widget(p.dashboardId, p.widgetId),
        p.viewId,
        p.prompt?.recommendation_id,
      ],
      enabled:
        Boolean(p.widgetId) &&
        Boolean(p.viewId) &&
        Boolean(p.prompt?.recommendation_id),
      queryFn: () =>
        WidgetApi.onBrowser().getViewRecommendation(
          p.teamId,
          p.dashboardId,
          p.widgetId,
          p.viewId,
          p.prompt?.recommendation_id,
        ),
      refetchInterval: 2 * 1000,
      onError: (error) => {
        const isCancelledError = error && error.hasOwnProperty('silent');
        if (isCancelledError) return;
        //show toast error

        toastPrompt({
          header: t('ai-error'),
          buttonLabel: t('view-button-label'),
          message: p.prompt?.user_request ?? t('ai-error'),
          key: p.prompt?.recommendation_id,
          isSucceed: false,
          subtext: `${p.dashboardName} / ... / ${p.viewName}`,
          onClickView: () => {
            router.push(getWidgetRoute(p.teamId, p.dashboardId, p.widgetId));
          },
        });
        dispatch({
          type: 'DELETE_PROMPT',
          payload: p.prompt.recommendation_id,
        });
      },
      onSuccess: (data) => {
        //show toast success
        if (!data) return;

        //NOTE: Polling time out

        if (data.status === 'FAILED') {
          dispatch({
            type: 'DELETE_PROMPT',
            payload: p.prompt.recommendation_id,
          });
          toastPrompt({
            header: t('ai-error'),
            buttonLabel: t('view-button-label'),
            message: data.user_request ?? '',
            key: data.recommendation_id,
            isSucceed: false,
            subtext: `${p.dashboardName} / ... / ${p.viewName}`,
            onClickView: () => {
              router.push(getWidgetRoute(p.teamId, p.dashboardId, p.widgetId));
            },
          });
          return;
        }
        if (data.status === 'COMPLETED') {
          dispatch({
            type: 'DELETE_PROMPT',
            payload: p.prompt.recommendation_id,
          });
          toastPrompt({
            header: t('ai-success'),
            buttonLabel: t('view-button-label'),
            message: data.user_request ?? '',
            key: data.recommendation_id,
            isSucceed: true,
            viewType: data.suggested_view_config?.view_type ?? 'BAR_CHART',
            subtext: `${p.dashboardName} / ... / ${p.viewName}`,
            onClickView: () => {
              router.push(getWidgetRoute(p.teamId, p.dashboardId, p.widgetId));
            },
          });
        }

        if (
          dayjs().valueOf() - dayjs(p.prompt?.created_at).valueOf() >
          PROMPT_POLLING_TIME_OUT * 1000
        ) {
          console.log('POLLING_TIME_OUT!!');
          dispatch({
            type: 'DELETE_PROMPT',
            payload: p.prompt.recommendation_id,
          });
          toastPrompt({
            header: t('ai-error'),
            buttonLabel: t('view-button-label'),
            message: p.prompt.user_request ?? '',
            key: data.recommendation_id,
            isSucceed: false,
            subtext: `${p.dashboardName} / ... / ${p.viewName}`,
            onClickView: () => {
              router.push(getWidgetRoute(p.teamId, p.dashboardId, p.widgetId));
            },
          });
          return;
        }
      },
      onSettled: () => {
        //delete from the store
        // dispatch({ type: 'DELETE_PROMPT', payload: prompt.recommendation_id });
      },
    })),
  });

  return <>{children}</>;
};

export const GlobalPromptStoreProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    query: { team_id },
  } = useRouter();

  const store = useMemo(() => {
    let teamId = team_id;
    if (!team_id) return;
    if (typeof teamId !== 'string') return;
    return createGlobalPromptStore(teamId);
  }, [team_id]);

  if (!store) return <>{children}</>;

  return (
    <GlobalPromptStoreContext.Provider value={store}>
      <GlobalPromptStoreConsumer useStore={store}>
        {children}
      </GlobalPromptStoreConsumer>
    </GlobalPromptStoreContext.Provider>
  );
};
