import React from 'react';

import Image from 'next/image';

import { Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'next-i18next';
import { type FallbackProps } from 'react-error-boundary';

import NotfoundImage from '@static/assets/empty-page/404.png';

import { AppPageNotFoundError } from '../AppErrorBoundary';
import { BackToDashboardButton } from './components/BackToDashboardButton';

export const NotFoundErrorPage: React.FC<
  Omit<FallbackProps, 'error'> & { error: AppPageNotFoundError }
> = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'error-page',
  });

  return (
    <Stack width="100%" height="100%">
      <Container maxWidth="md" sx={{ height: '100%' }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          //
        >
          <Stack alignItems="center" gap="16px">
            <Stack
              p="6px"
              borderRadius="8px"
              bgcolor="neutralV2.6"
              boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)"
              sx={{ width: 'fit-content' }}>
              <Image
                src={NotfoundImage}
                height="24"
                width="58"
                alt="notfound icon"
                priority
              />
            </Stack>
            <Stack gap="8px">
              <Typography variant="h2" color="neutralV2.0" textAlign="center">
                {t('page-not-found')}
              </Typography>
              <Typography
                variant="body2"
                color="neutralV2.2"
                textAlign="center">
                {t('this-page-url-is-not-existed-in-Cigro')}
              </Typography>
            </Stack>
            <Stack
              width="100%"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              gap="8px"
              onClick={resetErrorBoundary}>
              <BackToDashboardButton
                resetErrorBoundary={resetErrorBoundary}
                callbackUrl={error.callbackUrl}
              />
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
