'use client';

import { useEffect } from 'react';

export default function LemonSqueezyInit({ enable }: { enable: boolean }) {
  useEffect(() => {
    if (!enable) return;
    (function (w) {
      var a = document.getElementById('lemon-squeezy');
      if (a) a.remove();

      var s = document.createElement('script');
      s.id = 'lemon-squeezy';
      s.src = `https://app.lemonsqueezy.com/js/lemon.js`;
      s.defer = true;
      var e = document.getElementsByTagName('script')[0];
      e.parentNode?.insertBefore(s, e);
    })(window);
  }, [enable]);

  return null;
}
