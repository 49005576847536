import type { CreateAxiosDefaults } from 'axios';

import axios from 'axios';
import { getSession } from 'next-auth/react';

import {
  registerApiErrorParserResponseInterceptor,
  registerRefreshTokenOn401ResponseInterceptor,
  registerTokenHeaderRequestInterceptor,
} from '@/adapters/api/axios/utils';
import { TokenStorageObject, waitForToken } from '@/utils/client-side-token';

// NOTE: set 60 secs due to BIG QUERY sometime runs longer than 30s
const TIMEOUT_MS = 1000 * 70;

const AXIOS_CONFIG: CreateAxiosDefaults = {
  baseURL: process.env.API,
  timeout: TIMEOUT_MS,
  withCredentials: false,
};

const axiosInstance = axios.create(AXIOS_CONFIG);

const tokenGetter = async (): Promise<TokenStorageObject> => {
  return await waitForToken();
};

const refreshAuthFn = async (): Promise<TokenStorageObject> => {
  const maybeNewSession = await getSession();
  return {
    jwt: maybeNewSession?.access_token ?? null,
    access_token_expires_at: maybeNewSession?.access_token_expires_at ?? null,
  };
};

// NOTE!: the order of interceptor IS IMPORTANT!
// 1
registerTokenHeaderRequestInterceptor(axiosInstance, tokenGetter);
// 2
registerRefreshTokenOn401ResponseInterceptor(axiosInstance, refreshAuthFn);
// 3
registerApiErrorParserResponseInterceptor(axiosInstance);

export default axiosInstance;
