import type { AxiosResponse } from 'axios';

import invariant from 'tiny-invariant';
import z from 'zod';

import { clientHttp as axiosHttpClient } from '@/adapters/api';
import {
  ViewSchema,
  zBarChartConfig,
  zCommonConfig,
  zLineChartConfig,
  zPeriodicity,
  zPieChartConfig,
  zTableConfig,
} from '@/models/view';
import { zComboChartConfig } from '@/models/view/combo-chart';
import { type WidgetDto, WidgetDtoSchema } from '@/models/widget';
import { serializeArrayData, serializeData } from '@/utils/data';

import { zBoardConfig } from '../../models/view/board';
import { zGalleryConfig } from '../../models/view/gallery';
import { zGoalConfig, zGoalFullConfig } from '../../models/view/goal';
import { zPivotConfig } from '../../models/view/pivot';

export async function getWidgetsAPI_deprecated(
  teamId: string,
  dashboardId: string,
) {
  const res = await axiosHttpClient.get<WidgetDto[]>(
    `/v3/teams/${teamId}/dashboards/${dashboardId}/widgets`,
  );
  return serializeArrayData(res?.data ?? [], WidgetDtoSchema);
}

export async function getRawWidgetsAPI_deprecated(
  teamId: string,
  dashboardId: string,
) {
  const res = await axiosHttpClient.get<WidgetDto[]>(
    `/v3/teams/${teamId}/dashboards/${dashboardId}/widgets`,
  );
  return res?.data ?? [];
}

const UpdateWidgetSizePayloadSchema = z.array(
  z.object({
    id: z.string(),
    x_axis_start: z.number().int().min(0),
    y_axis_start: z.number().int().min(0),
    width: z.number().int().min(3),
    height: z.number().int().min(217),
  }),
);

export type UpdateWidgetSizePayload = z.infer<
  typeof UpdateWidgetSizePayloadSchema
>;

export const UpdateWidgetSizeResponseSchema = z.object({
  id: z.string(),
  x_axis_start: z.number().int().min(0),
  y_axis_start: z.number().int().min(0),
  width: z.number().int().min(3),
  height: z.number().int().min(217),
  x_axis_end: z.number().int(),
  y_axis_end: z.number().int(),
});

export type UpdateWidgetSizeResponse = z.infer<
  typeof UpdateWidgetSizeResponseSchema
>;

export async function updateWidgetSizeAPI_deprecated(
  teamId: string,
  dashboardId: string,
  payload: UpdateWidgetSizePayload,
) {
  invariant(teamId, 'no team id provided');
  invariant(dashboardId, 'no dashboard id provided');
  const res = await axiosHttpClient.put<
    UpdateWidgetSizeResponse[],
    AxiosResponse<UpdateWidgetSizeResponse[]>,
    UpdateWidgetSizePayload
  >(
    '/v3/teams/' + teamId + '/dashboards/' + dashboardId + '/widgets/sizes',
    payload,
  );
  return serializeArrayData(res?.data, UpdateWidgetSizeResponseSchema);
}

const {
  name,
  description,
  x_axis_start,
  y_axis_start,
  width,
  height,
  text,
  content_type,
} = WidgetDtoSchema.shape;

const { report_id, name: viewName, id: viewId } = ViewSchema.shape;

export const UpdateViewPayloadSchema = z.object({
  id: viewId.optional(),
  report_id: report_id.optional(),
  name: viewName.optional(),
  config: z
    .union([
      zCommonConfig.merge(zTableConfig).partial(),
      zCommonConfig.merge(zLineChartConfig).partial(),
      zCommonConfig.merge(zPieChartConfig).partial(),
      zCommonConfig.merge(zBarChartConfig).partial(),
      zCommonConfig.merge(zBoardConfig).partial(),
      zCommonConfig.merge(zPivotConfig).partial(),
      zCommonConfig.merge(zGalleryConfig).partial(),
      zCommonConfig.merge(zGoalConfig).partial(),
      zCommonConfig.merge(zCommonConfig).partial(),
    ])
    .optional(),
  default: z.boolean().optional(),
  description: z.string().nullish(),
});
export type UpdateViewPayload = z.infer<typeof UpdateViewPayloadSchema>;

export const UpdateReportSchema = z.object({
  default_view_id: z.string().optional(),
  data_model_id: z.string().nullable().optional(),
  dataset_id: z.string().nullable().optional(),
  name: z.string().optional(),
  views: z.array(UpdateViewPayloadSchema).optional(),
});

export const UpdateWidgetPayloadSchema = z.object({
  name: name.optional(),
  description: description.optional(),
  x_axis_start: x_axis_start.optional(),
  y_axis_start: y_axis_start.optional(),
  width: width.optional(),
  height: height.optional(),
  report: UpdateReportSchema.optional(),
  text: text.optional(),
  dashboard_id: z.string().nullish(),
});

export type UpdateWidgetPayload = z.infer<typeof UpdateWidgetPayloadSchema>;

export async function updateWidgetAPI_deprecated(
  teamId: string,
  dashboardId: string,
  widgetId: string,
  payload: UpdateWidgetPayload,
) {
  invariant(teamId, 'no team id provided');
  invariant(dashboardId, 'no dashboard id provided');
  invariant(widgetId, 'no widget id provided');

  const url = `/v3/teams/${teamId}/dashboards/${dashboardId}/widgets/${widgetId}`;

  const res = await axiosHttpClient.put<
    WidgetDto,
    AxiosResponse<WidgetDto>,
    UpdateWidgetPayload
  >(url, payload);

  return res?.data;
}

const zTimeSeriesPayload = {
  time_series: z.object({
    periodicity: zPeriodicity,
  }),
};

const zViewConfigPayload = z.object({
  name: z.string(),
  description: z.string().nullish(),
  config: z.union([
    // z.object({
    //   view_type: z.literal('TABLE'),
    // }),
    zTableConfig,
    zBarChartConfig,
    zPieChartConfig,
    zLineChartConfig.extend(zTimeSeriesPayload),
    zBoardConfig.extend(zTimeSeriesPayload),
    zPivotConfig,
    zGoalConfig,
    zGalleryConfig,
    zComboChartConfig,
  ]),
});

export type ViewConfigPayload = z.infer<typeof zViewConfigPayload>;

export const CreateWidgetPayloadSchema = z.object({
  name: name,
  x_axis_start: x_axis_start.optional(),
  y_axis_start: y_axis_start.optional(),
  content_type: content_type,
  width: z.number().int(),
  height: z.number().int(),
  report: z.object({ views: z.array(zViewConfigPayload) }).optional(),
  text: z.object({ content: z.string() }).optional(),
});

export type CreateWidgetPayload = z.infer<typeof CreateWidgetPayloadSchema>;

// CREATE WIDGET
export async function createWidgetAPI_deprecated(
  teamId: string,
  dashboardId: string,
  payload: CreateWidgetPayload,
) {
  const res = await axiosHttpClient.post<
    WidgetDto,
    AxiosResponse<WidgetDto>,
    CreateWidgetPayload
  >(`/v3/teams/${teamId}/dashboards/${dashboardId}/widgets`, payload);
  return serializeData(res?.data, WidgetDtoSchema);
}

export async function removeWidgetAPI_deprecated(
  teamId: string,
  dashboardId: string,
  widgetId: string,
) {
  await axiosHttpClient.delete(
    `/v3/teams/${teamId}/dashboards/${dashboardId}/widgets/${widgetId}`,
  );
  return null;
}

export async function duplicateWidgetAPI_deprecated(
  teamId: string,
  dashboardId: string,
  widgetId: string,
) {
  const res = await axiosHttpClient.post<WidgetDto, AxiosResponse<WidgetDto>>(
    `/v3/teams/${teamId}/dashboards/${dashboardId}/widgets/${widgetId}/duplicate`,
  );
  return serializeData(res?.data, WidgetDtoSchema);
}
