import type { PropsWithChildren } from 'react';

import React from 'react';

import { AppPopoverFooter, AppPopoverTitle } from './AppPopover';
import { POPOVER_PAPER_DEFAULT_SX } from './constants';
import { AppDialog } from './Dialog.component';

type Props = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  title?: React.ReactNode | undefined;
  footer?: React.ReactNode | undefined;
}>;

export const AppConfirmDialog: React.FC<Props> = ({
  open,
  onClose,
  children,
  title,
  footer,
}) => {
  return (
    <AppDialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: [
          ...(Array.isArray(POPOVER_PAPER_DEFAULT_SX)
            ? POPOVER_PAPER_DEFAULT_SX
            : [POPOVER_PAPER_DEFAULT_SX]),
        ],
      }}
      //
    >
      <AppPopoverTitle
        sx={{ p: '12px', p8b: '16px' }}
        onClose={onClose}
        //
      >
        {title}
      </AppPopoverTitle>

      {children}

      <AppPopoverFooter sx={{ borderTop: 'none', pt: '24px' }}>
        {footer}
      </AppPopoverFooter>
    </AppDialog>
  );
};
