import type { Actions, Subjects } from './types';

import { ForcedSubject, subject } from '@casl/ability';
import { useAbility } from '@casl/react';

import { RbacPermissionContext } from './Components';
import { DASHBOARD_SUBJECT, TEAM_SUBJECT } from './constants';

export const useAppAbility = () => useAbility(RbacPermissionContext);

export const useCanI = (
  action: Actions,
  subjectProp: typeof TEAM_SUBJECT | typeof DASHBOARD_SUBJECT,
  when: Record<PropertyKey, any> = {},
) => {
  const ability = useAppAbility();

  return ability.can(action, subject(subjectProp, when) as any);
};
