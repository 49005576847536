import type {
  UpdateMyProfilePayload,
  UpdateSideBarWidthPayload,
} from '@/layouts/api';

import { BaseApiAdapterClass, RequestManager } from '@/adapters/api/__base';
import { UserDto, UserDtoSchema } from '@/models/user';
import { getFullUrlService } from '@/services/file';
import { serializeData } from '@/utils/data';

import { clientHttp, serverHttp } from './axios';

class MeApiAdapterClass extends BaseApiAdapterClass {
  constructor(rm: RequestManager) {
    super(rm);
  }

  async getProfile(): Promise<UserDto | null> {
    const profile = await this.get<UserDto>('/v3/auth/users/me');
    const me = serializeData(profile, UserDtoSchema);
    if (me?.avatar_url) {
      me.avatar_url = getFullUrlService(me.avatar_url);
    }
    return me;
  }

  async updateProfile(
    payload: UpdateMyProfilePayload,
  ): Promise<UserDto | null> {
    const data = await this.request<UserDto, UpdateMyProfilePayload>(
      '/v3/auth/users/me',
      'put',
      payload,
    );
    return serializeData(data, UserDtoSchema);
  }

  // Also used for update width of side bar
  async updateUserPreference(
    payload: UserDto['preferences'],
  ): Promise<UserDto | null> {
    const data = await this.request<UserDto, UpdateSideBarWidthPayload>(
      '/v3/auth/users/me',
      'put',
      { preferences: payload },
    );
    return serializeData(data, UserDtoSchema);
  }
}

/**
 * @deprecated
 */
export const MeApiAdapter = Object.freeze(new MeApiAdapterClass(clientHttp));

export const MeApi = {
  onBrowser: () => new MeApiAdapterClass(clientHttp),
  onServer: () => new MeApiAdapterClass(serverHttp),
};
