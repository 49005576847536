import type {
  CreateTeamPayload,
  EditTeamPermissionsPayload,
  EditTeamPermissionsResponse,
  InviteMembersPayload,
  RemoveInviteMemberPayload,
  RemoveTeamMembersPayload,
} from '@/feature-team/api';
import type {
  GetTeamInvitationStatusResponse,
  GetTeamMembersPayload,
  UpdateTeamPayload,
} from '@/layouts/api';

import invariant from 'tiny-invariant';

import {
  ApiAdapterRequestHeaders,
  BaseApiAdapterClass,
  IApiAdapters,
  RequestManager,
} from '@/adapters/api/__base';
import { type TeamDto, TeamDtoSchema } from '@/feature-team/model';
import { MemberDto, type UserDto, UserDtoSchema } from '@/models/user';
import { getFullUrlService } from '@/services/file';
import { serializeArrayData, serializeData } from '@/utils/data';

import { BillingUsageResponse } from '../../layouts/navbar/use-billing-usage-query';
import { clientHttp, serverHttp } from './axios';
import { teamsURL } from './url-string';

class TeamApiAdapterClass
  extends BaseApiAdapterClass
  implements IApiAdapters<TeamApiAdapterClass>
{
  constructor(rm: RequestManager) {
    super(rm);
    // NOTE: do not need these since using Object.freeze
    this.create.bind(this);
    this.getAll.bind(this);
    this.getMembers.bind(this);
    this.updateTeam.bind(this);
    this.removeMembers.bind(this);
    this.inviteNewMembers.bind(this);
  }

  private clone(): TeamApiAdapterClass {
    return new TeamApiAdapterClass(this.requestManager);
  }

  buildWithRequestHeaders(
    headers: ApiAdapterRequestHeaders,
  ): TeamApiAdapterClass {
    const cloned = this.clone();
    cloned.setRequestHeaders(headers);
    return cloned;
  }

  async create(payload: CreateTeamPayload) {
    const data = await this.request<TeamDto, CreateTeamPayload>(
      '/v3/teams',
      'post',
      payload,
    );
    return serializeData(data, TeamDtoSchema);
  }

  async getAll(): Promise<TeamDto[]> {
    const data = await this.get<TeamDto[]>('/v3/teams');
    return (
      data?.map<TeamDto>((team) =>
        team.avatar_url
          ? {
              ...team,
              avatar_url: getFullUrlService(team.avatar_url),
            }
          : team,
      ) ?? []
    );
  }

  async getDetailById(teamId: string) {
    return this.get<TeamDto>(teamsURL.teamsId(teamId));
  }

  async getMembers(payload: GetTeamMembersPayload): Promise<MemberDto[]> {
    const params = new URLSearchParams({
      team_id: payload.team_id,
      search_keyword: payload.search_keyword || '',
      size: payload.size.toString() || '20',
      offset: payload.offset.toString() || '0',
    });

    const fullUrl = '/v3/auth/users?' + params.toString();

    const data = await this.get<MemberDto[]>(fullUrl);

    if (!data) return [];

    return data.map<MemberDto>((member) =>
      member.avatar_url
        ? {
            ...member,
            avatar_url: getFullUrlService(member.avatar_url),
          }
        : member,
    );
  }

  async updateTeam(
    teamId: string,
    payload: UpdateTeamPayload,
  ): Promise<TeamDto | null> {
    const url = '/v3/teams/' + teamId;
    const data = await this.request<TeamDto, UpdateTeamPayload>(
      url,
      'put',
      payload,
    );
    return serializeData(data, TeamDtoSchema);
  }

  async removeMembers(
    teamId: string,
    payload: RemoveTeamMembersPayload,
  ): Promise<void> {
    invariant(teamId, 'no team id provided');
    const url = '/v3/teams/' + teamId + '/members/revoke';
    await this.request<void, RemoveTeamMembersPayload>(url, 'post', payload);
  }

  async removeInviteMember(
    teamId: string,
    payload: RemoveInviteMemberPayload,
  ): Promise<void> {
    invariant(teamId, 'no team id provided');
    const url = `/v3/teams/${teamId}/invitations/revoke`;
    await this.request<void, RemoveInviteMemberPayload>(url, 'post', payload);
  }

  async inviteNewMembers(
    teamId: string,
    payload: InviteMembersPayload,
  ): Promise<void> {
    const url = '/v3/teams/' + teamId + '/invitations';
    await this.request<void, InviteMembersPayload>(url, 'post', payload);
  }

  async getTeamInvitationStatus(
    teamId: string,
  ): Promise<GetTeamInvitationStatusResponse[]> {
    const url = '/v3/teams/' + teamId + '/invitations';
    const data = await this.get<GetTeamInvitationStatusResponse[]>(url);
    return data ?? [];
  }

  async getBillingUsage(teamId: string, month: number, year: number) {
    const url = '/v3/teams/' + teamId + '/monthly-billing-usage';
    const data = await this.get<BillingUsageResponse>(url, {
      month,
      year,
    });
    return data;
  }

  async getCheckoutUrl(teamId: string) {
    const url = '/v3/teams/' + teamId + '/payments/checkout-url';
    const data = await this.get<string>(url);
    return data;
  }

  editPermissions(teamId: string, payload: EditTeamPermissionsPayload) {
    return this.request<
      EditTeamPermissionsResponse,
      EditTeamPermissionsPayload
    >(teamsURL.permissions(teamId), 'put', payload);
  }
}

/**
 * @deprecated
 */
export const TeamApiAdapter = Object.freeze(
  new TeamApiAdapterClass(clientHttp),
);

/**
 * @deprecated
 */
export const TeamServerApiAdapter = Object.freeze(
  new TeamApiAdapterClass(serverHttp),
);

export const TeamApi = {
  onBrowser: () => new TeamApiAdapterClass(clientHttp),
  onServer: () => new TeamApiAdapterClass(serverHttp),
};
