'use client';

import type { Actions, AppAbility } from '@/adapters/rbac/types';
import type { BoundCanProps } from '@casl/react';

import React, { createContext, PropsWithChildren } from 'react';

import { subject } from '@casl/ability';
import { createContextualCan } from '@casl/react';

import ability from '@/adapters/rbac/ability';
import { DASHBOARD_SUBJECT, TEAM_SUBJECT } from '@/adapters/rbac/constants';

import { useAppAbility, useCanI } from './hooks';

export const RbacPermissionContext = createContext(ability);

export const RbacPermissionProvider: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <RbacPermissionContext.Provider value={ability}>
    {children}
  </RbacPermissionContext.Provider>
);

const ContextualCan = createContextualCan(RbacPermissionContext.Consumer);

type Props = Pick<BoundCanProps<AppAbility>, 'children' | 'passThrough'> & {
  I: Actions;
  this: typeof DASHBOARD_SUBJECT | typeof TEAM_SUBJECT;
  when: Record<PropertyKey, any>;
};

export const Can: React.FC<Props> = ({
  I,
  this: thisProp,
  when = {},
  children,
  passThrough,
}) => {
  return (
    <ContextualCan
      do={I}
      on={subject(thisProp, when) as any}
      passThrough={passThrough}
      //
    >
      {children}
    </ContextualCan>
  );
};

export const CanUpdateDashboard: React.FC<
  {
    dashboardId: string;
  } & Pick<BoundCanProps<AppAbility>, 'children'>
> = ({ dashboardId, children }) => {
  const ability = useAppAbility();

  if (typeof children === 'function') {
    return (
      <Can
        I="edit"
        this="dashboard"
        when={{ id: dashboardId }}
        passThrough
        //
      >
        {(isAllowed) => children(isAllowed, ability)}
      </Can>
    );
  }

  return (
    <Can
      I="edit"
      this="dashboard"
      when={{ id: dashboardId }}
      passThrough
      //
    >
      {children}
    </Can>
  );
};
